import React, { FC, MouseEventHandler, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BuildOutlined, CopyAllOutlined, MoreVertOutlined } from '@mui/icons-material';

import { DesignSystemColor, DesignSystemSize, IconButton, Menu, MenuItem } from '@verticeone/design-system';

import { useDuplicateWorkflowVersion } from '../../../workflow/WorkflowDetailPage/components/hooks/useDuplicateWorkflowVersion';
import { useGoToWorkflowEditor } from '../../../workflow/WorkflowDetailPage/components/hooks/useGoToWorkflowEditor';
import { getIsWorkflowLocked } from './useWorkflowColumns';
import type { WorkflowVersionRow } from './types';

type MenuButtonProps = {
  workflowVersion: WorkflowVersionRow;
  color: DesignSystemColor;
  size?: DesignSystemSize;
};

export const MenuButton: FC<MenuButtonProps> = ({ workflowVersion, color, size = 'S' }) => {
  const isLocked = getIsWorkflowLocked(workflowVersion);

  const { t } = useTranslation(undefined, {
    keyPrefix: 'ENTITIES.WORKFLOW_VERSION.ACTIONS',
  });

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const { goToEditor } = useGoToWorkflowEditor(workflowVersion);
  const { duplicateWorkflow, isDuplicatingWorkflow } = useDuplicateWorkflowVersion(workflowVersion);

  const handleIconButtonClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setIsMenuOpen(true);
    event.stopPropagation();
  };

  return (
    <div ref={containerRef}>
      <IconButton onClick={handleIconButtonClick} icon={MoreVertOutlined} variant="outline" color={color} size={size} />
      <Menu anchorEl={containerRef.current} open={isMenuOpen} onClose={() => setIsMenuOpen(false)}>
        {isLocked ? (
          <MenuItem disabled={isDuplicatingWorkflow} onClick={duplicateWorkflow} startIcon={CopyAllOutlined}>
            {t('CREATE_COPY')}
          </MenuItem>
        ) : (
          <MenuItem onClick={goToEditor} disabled={isDuplicatingWorkflow} startIcon={BuildOutlined}>
            {t('EDIT')}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};
