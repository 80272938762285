import { useCallback } from 'react';
import {
  type CreateContractApiResponse,
  useCreateContractMutation,
  type ContractCreate,
  useUploadContractFileV2Mutation,
} from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { AddExistingContractFormData } from '../../types';
import { useFormContext } from 'react-hook-form';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { formDataToExistingContractCreate } from '../../convertors';
import { useDefaultCostModel } from '@vertice/core/src/modules/saas/contract/costModels/useDefaultCostModel';

export const useCreateExistingContract = (onSuccess: () => void): [() => void, boolean] => {
  const { accountId } = useAccountContext();

  const { watch, setValue } = useFormContext<AddExistingContractFormData>();
  const formValues = watch();

  const [createContract, { isLoading }] = useCreateContractMutation();
  const [uploadContractFile] = useUploadContractFileV2Mutation();
  const { getCostModelBase } = useDefaultCostModel();

  const createContractCallback = useCallback(async () => {
    const newContract: ContractCreate = formDataToExistingContractCreate(formValues, getCostModelBase);

    const contractCreateResponse = await createContract({
      accountId,
      createContractRequest: {
        contract: newContract,
      },
    });

    const successResponse = (contractCreateResponse as { data: CreateContractApiResponse }).data;
    if (successResponse) {
      const createdContractId = successResponse.viewOnContract.contract.record.contractId;
      setValue('existingContractId', createdContractId);

      // Upload contract documents
      for await (const file of formValues.files) {
        await uploadContractFile({
          accountId: accountId!,
          contractId: createdContractId,
          fileName: file.name,
          body: file,
        });
      }
      onSuccess();
    }
  }, [accountId, createContract, uploadContractFile, formValues, onSuccess, setValue, getCostModelBase]);

  return [createContractCallback, isLoading];
};
