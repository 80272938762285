import React, { useMemo } from 'react';
import { Card, CardHeaderTitle } from '@verticeone/design-system';
import { Stack } from '@mui/material';
import { Tab } from '@verticeone/design-system';
import { Tabs } from '@verticeone/design-system';
import { TabsBottomLineWrapper } from '@verticeone/design-system';
import { ContractContextProvider, useContractContext } from '@vertice/core/src/modules/saas/contract/ContractContext';
import { useTranslation } from 'react-i18next';
import { isContractExisting } from '@vertice/core/src/modules/saas/contract/computed';
import PurchaseOfferSection from './PurchaseOfferSection';
import HighlightedCardHeader from '@vertice/core/src/modules/saas/contract/components/HighlightedCardHeader';
import SavingsBreakdownTab from '@vertice/core/src/modules/saas/contract/components/SavingsBreakdownTab';
import DocumentsTab from '@vertice/core/src/modules/saas/contract/components/DocumentsTab';
import PurchaseRequestDetailsTab from './PurchaseRequestDetailsTab';
import ProductsTab from '../../tabs/ProductsTab';
import PurchaseObjectivesTab from './PurchaseObjectivesTab';
import PurchaseRenewalHeaderActions from '@vertice/dashboard/src/modules/saas/contract/components/PurchaseRenewalHeaderActions';
import WorkflowTab from '@vertice/core/src/modules/saas/contract/components/WorkflowTab';
import PurchaseClassificationSection from './PurchaseClassificationSection';
import useFormUpdateActions from '@vertice/core/src/modules/saas/contract/hooks/useFormUpdateActions';
import useContractTabNavigation, {
  TabDefinition,
} from '@vertice/core/src/modules/saas/contract/hooks/useContractTabNavigation';
import { ContractTab } from '@vertice/core/src/modules/saas/contract/ContractTabNavigationContext';
import CustomTab from '@vertice/core/src/modules/saas/contract/components/CustomTab';
import { useCanSeeCustomFields } from '@vertice/core/src/modules/saas/contract/components/CustomFields';
import { useProductTabBenchmarkingProps } from '../../tabs/useProductTabBenchmarkingProps';

const PurchaseContractCard = () => {
  const contractContextData = useContractContext('REQUIRE_FETCHED');
  const { t } = useTranslation();
  const { handleSubmit } = useFormUpdateActions(contractContextData);

  const contract = contractContextData.fetchedContract.contract;

  const showConcessionTab = useMemo(() => contract && !isContractExisting(contract), [contract]);

  const showCustomTab = useCanSeeCustomFields();

  const productTabBenchmarkingProps = useProductTabBenchmarkingProps();

  const tabs: TabDefinition[] = useMemo(
    () => [
      {
        id: ContractTab.OBJECTIVES,
        label: t('SAAS.PURCHASE_RENEWAL_CONTRACT_CARD.TABS.OBJECTIVES'),
        element: <PurchaseObjectivesTab />,
        isVisible: true,
      },
      {
        id: ContractTab.REQUEST_DETAILS,
        label: t('SAAS.PURCHASE_RENEWAL_CONTRACT_CARD.TABS.REQUEST_DETAILS'),
        element: <PurchaseRequestDetailsTab />,
        isVisible: true,
      },
      {
        id: ContractTab.SAVINGS_BREAKDOWN,
        label: t('SAAS.PURCHASE_RENEWAL_CONTRACT_CARD.TABS.SAVINGS_BREAKDOWN'),
        element: <SavingsBreakdownTab />,
        isVisible: showConcessionTab,
      },
      {
        id: ContractTab.PRODUCTS,
        label: t('SAAS.PURCHASE_RENEWAL_CONTRACT_CARD.TABS.PRODUCTS'),
        element: <ProductsTab />,
        isVisible: true,
        otherProps: productTabBenchmarkingProps,
      },
      {
        id: ContractTab.DOCUMENTS,
        label: t('SAAS.PURCHASE_RENEWAL_CONTRACT_CARD.TABS.PURCHASE_DOCUMENTS'),
        element: <DocumentsTab />,
        isVisible: true,
      },
      {
        id: ContractTab.WORKFLOW,
        label: t('SAAS.PURCHASE_RENEWAL_CONTRACT_CARD.TABS.WORKFLOW'),
        element: <WorkflowTab />,
        isVisible: true,
      },
      {
        id: ContractTab.CUSTOM,
        label: t('SAAS.PURCHASE_RENEWAL_CONTRACT_CARD.TABS.CUSTOM'),
        element: <CustomTab />,
        isVisible: showCustomTab,
      },
    ],
    [showConcessionTab, showCustomTab, productTabBenchmarkingProps, t]
  );

  const { visibleTabs, activeTab, setActiveTab, scrollTargetRef } = useContractTabNavigation({ tabs });

  return (
    <Card>
      <ContractContextProvider value={contractContextData}>
        <Stack component="form" onSubmit={handleSubmit}>
          {contract && (
            <HighlightedCardHeader size="S">
              <CardHeaderTitle text={t('SAAS.PURCHASE_RENEWAL_CONTRACT_CARD.HEADER.NEW_PURCHASE')} />
              <PurchaseRenewalHeaderActions />
            </HighlightedCardHeader>
          )}
          <Stack py={6} spacing={8}>
            <PurchaseOfferSection />
            <PurchaseClassificationSection />
          </Stack>
          <TabsBottomLineWrapper paddingX={6}>
            <Tabs
              ref={scrollTargetRef}
              variant="outlined"
              value={activeTab.id}
              onChange={(_, value) => setActiveTab(value)}
              scrollButtons="auto"
            >
              {visibleTabs.map((tab) => (
                <Tab key={tab.id} value={tab.id} label={tab.label} {...tab.otherProps} />
              ))}
            </Tabs>
          </TabsBottomLineWrapper>
          {visibleTabs.find((tab) => tab.id === activeTab.id)?.element}
        </Stack>
      </ContractContextProvider>
    </Card>
  );
};

export default PurchaseContractCard;
