import { getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';
import { ActiveRequestsData, ItemType } from './types';

export const useActiveRequestsData = () => {
  const tempData = {
    name: 'active_requests',
    stats: {
      openRequests: 4,
      spend: 1200000,
    },
    details: {
      columns: ['id', 'title', 'subtitle', 'vendor_id', 'date'],
      types: ['string', 'string', 'string', 'string', 'isotime'],
      data: [
        ['1', 'Atlassian New Purchase', 'Onboarding Checklist', '6c3b0d9f-a7c9-5970-941e-d866def1bb87', '2024-12-11'],
        ['2', 'Slack Renewal', 'New Vendor Onboarding Checklist', '0f026b4b-8875-537c-a670-2c5755ce760b', '2025-01-14'],
        ['3', 'Request Name Vendor New Purchase', 'New Vendor Onboarding Checklist', '', '2025-01-18'],
        [
          '4',
          'Google Domains New Purchase',
          'New Vendor Onboarding Checklist',
          'dbd990b7-8b50-55eb-a6ed-8eec7cc6f75b',
          '2025-01-21',
        ],
        ['5', 'Design Suite for Marketing', 'New Vendor Onboarding Checklist', '', '2025-02-02'],
      ],
    },
  };

  const data: ActiveRequestsData = {
    items: getTableData(tempData.details, {
      vendor_id: 'vendorId',
      title: 'title',
      subtitle: 'subtitle',
      date: 'date',
    }) as ItemType[],
    openRequests: tempData.stats.openRequests,
    spend: tempData.stats.spend,
  };

  return {
    data,
    isLoading: false,
  };
};
