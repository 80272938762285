import { useAccountContext } from '../../../../contexts/AccountContext';
import {
  useListWorkflowVersionsQuery,
  useGetWorkflowVersionQuery,
} from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { WorkflowVersionRow } from './types';
import groupBy from 'lodash/groupBy';
import { useNotificationWebSocketSubscription } from '../../../../contexts/NotificationWebSocketContext';
import { isCurrentWorkflowVersion } from '../../../../hooks/workflows/refUtils';

export const useWorkflowVersions = (workflowId: string) => {
  const { accountId } = useAccountContext();

  const {
    data: workflowVersionsResponse,
    isLoading: isLoadingWorkflowVersions,
    refetch,
  } = useListWorkflowVersionsQuery({
    accountId: accountId!,
    workflowId,
  });

  const workflowVersionsList = workflowVersionsResponse?.workflows || [];

  const workflowVersions = groupBy<WorkflowVersionRow>(workflowVersionsList, (workflow) => workflow.status);

  const firstActive = workflowVersions['ACTIVE']?.[0];

  const {
    data: activeVersionData,
    isLoading: isLoadingActiveVersion,
    refetch: refetchActive,
  } = useGetWorkflowVersionQuery(
    { accountId, workflowId: firstActive?.id!, workflowVersion: firstActive?.versionId! },
    { skip: !firstActive }
  );

  useNotificationWebSocketSubscription({
    filter: isCurrentWorkflowVersion(workflowId),
    callback: () => {
      void refetch();
      void refetchActive();
    },
  });

  return {
    workflowVersions,
    isLoading: isLoadingActiveVersion || isLoadingWorkflowVersions,
    activeVersion: activeVersionData,
  };
};
