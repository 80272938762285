import React, { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { BuildOutlined } from '@mui/icons-material';

import { ChipButton, Text, DataGridColDef } from '@verticeone/design-system';
import { useFormatDate, dateTimeFormatterOptions } from '@verticeone/utils/formatting';

import { ShowOnRowHover } from '../../../request/RequestsListPage/components/ShowOnRowHover';
import { useLocalizeVersionName } from '../../../workflow/WorkflowDetailPage/utils';
import { ValidationChip } from '../../../workflowSchema';
import { DuplicateButton } from '../../../workflow/WorkflowDetailPage/components/DuplicateButton';
import { EditButton } from '../../../workflow/WorkflowDetailPage/components/EditButton';
import { MenuButton } from './MenuButton';
import type { WorkflowVersionRow } from './types';

export const getIsWorkflowLive = ({ status, isWorkflowDefault }: WorkflowVersionRow) =>
  status === 'ACTIVE' && isWorkflowDefault;
export const getIsWorkflowLocked = ({ status }: WorkflowVersionRow) => status === 'ACTIVE' || status === 'ARCHIVED';

type WorkflowNameProps = {
  row: WorkflowVersionRow;
};

const WorkflowName: FC<WorkflowNameProps> = ({ row: workflowVersion }) => {
  const { workflowName, versionName } = workflowVersion;
  const isLive = getIsWorkflowLive(workflowVersion);

  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.SERVICES_LIST.SERVICE_DRAWER.WORKFLOWS_TABLE.CHIPS',
  });
  const localizeVersionName = useLocalizeVersionName();

  const finalWorkflowName = (workflowName + ' ' + localizeVersionName(versionName)).trim();

  return (
    <Stack gap={1} direction="row" alignItems="center">
      <Text size="S" variant="body-bold" color={isLive ? 'primary' : 'text1'}>
        {finalWorkflowName}
      </Text>
      {isLive && (
        <ChipButton variant="solid" color="primary" size="S">
          {t('LIVE')}
        </ChipButton>
      )}
    </Stack>
  );
};

type WorkflowInfoProps = {
  row: WorkflowVersionRow;
};

const WorkflowInfo: FC<WorkflowInfoProps> = ({ row: workflowVersion }) => {
  const { activeInstances, completedInstances, workflowDefinitionStatus, totalInstances } = workflowVersion;
  const isLive = getIsWorkflowLive(workflowVersion);
  const isLocked = getIsWorkflowLocked(workflowVersion);

  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.SERVICES_LIST.SERVICE_DRAWER.WORKFLOWS_TABLE.CONTENT',
  });

  if (isLocked || isLive) {
    return (
      <Stack gap={1}>
        <Text variant="body-regular" size="S" color={isLive ? 'primary' : 'text1'}>
          {t('ACTIVE_REQUESTS', { count: activeInstances })}
        </Text>
        <ShowOnRowHover>
          <Text variant="caption" color={isLive ? 'primary' : 'text3'} size="XS">
            {t('COMPLETED_AND_TOTAL', { completed: completedInstances, total: totalInstances })}
          </Text>
        </ShowOnRowHover>
      </Stack>
    );
  }

  if (workflowDefinitionStatus) {
    return (
      <ValidationChip
        size="S"
        minified
        isActive={false}
        isValid={!workflowDefinitionStatus.error && !workflowDefinitionStatus.warning}
        errors={workflowDefinitionStatus.error}
        warnings={workflowDefinitionStatus.warning}
        infos={workflowDefinitionStatus.info}
        isLoading={false}
      />
    );
  }

  return null;
};

type WorkflowLastEditedProps = {
  row: WorkflowVersionRow;
};

const WorkflowLastEdited: FC<WorkflowLastEditedProps> = ({ row: workflowVersion }) => {
  const { updatedAt, activatedAt } = workflowVersion;
  const isLive = getIsWorkflowLive(workflowVersion);

  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.SERVICES_LIST.SERVICE_DRAWER.WORKFLOWS_TABLE.CONTENT',
  });

  const formatDate = useFormatDate();

  const updatedAtFormatted = formatDate(updatedAt, { formatterOptions: dateTimeFormatterOptions });
  const activatedAtFormatted = formatDate(activatedAt, { formatterOptions: dateTimeFormatterOptions });

  if (!updatedAtFormatted) {
    return null;
  }

  return (
    <Stack gap={1}>
      <Text variant="body-regular" size="S" color={isLive ? 'primary' : 'text1'}>
        {updatedAtFormatted}
      </Text>
      {activatedAtFormatted && isLive ? (
        <ShowOnRowHover>
          <Text variant="caption" color={isLive ? 'primary' : 'text3'} size="XS">
            {t('LIVE_SINCE', { date: activatedAtFormatted })}
          </Text>
        </ShowOnRowHover>
      ) : null}
    </Stack>
  );
};

type WorkflowActionsProps = {
  row: WorkflowVersionRow;
};

const WorkflowActions: FC<WorkflowActionsProps> = ({ row: workflowVersion }) => {
  const isLive = getIsWorkflowLive(workflowVersion);
  const isLocked = getIsWorkflowLocked(workflowVersion);
  const buttonColor = isLive ? 'primary' : 'neutral';

  const { t } = useTranslation(undefined, {
    keyPrefix: 'ENTITIES.WORKFLOW_VERSION.ACTIONS',
  });

  return (
    <Stack direction="row" gap={1}>
      {isLocked ? (
        <DuplicateButton tooltipTitle={t('CREATE_COPY')} size="S" row={workflowVersion} color={buttonColor} />
      ) : (
        <EditButton icon={BuildOutlined} size="S" row={workflowVersion} color="neutral" />
      )}
      <MenuButton workflowVersion={workflowVersion} color={buttonColor} />
    </Stack>
  );
};

const FUTURE_YEAR = '9999';

// The workflow version considered live should be always on top
const getValueForSorting = ({ row }: { row: WorkflowVersionRow }) =>
  getIsWorkflowLive(row) ? FUTURE_YEAR : row.updatedAt || row.createdAt;

export const useWorkflowColumns = (): DataGridColDef<WorkflowVersionRow>[] => {
  const { t } = useTranslation(undefined, {
    keyPrefix: 'INTELLIGENT_WORKFLOWS.SERVICES_LIST.SERVICE_DRAWER.WORKFLOWS_TABLE.HEADER',
  });

  return useMemo(() => {
    const columns: DataGridColDef<WorkflowVersionRow>[] = [
      {
        field: 'name',
        headerName: t('NAME'),
        flex: 1,
        disableColumnMenu: true,
        sortable: false,
        renderCell: WorkflowName,
      },
      {
        field: 'info',
        headerName: t('INFO'),
        flex: 1,
        disableColumnMenu: true,
        sortable: false,
        renderCell: WorkflowInfo,
      },
      {
        field: 'lastEdited',
        headerName: t('LAST_EDITED'),
        flex: 1,
        disableColumnMenu: true,
        renderCell: WorkflowLastEdited,
        valueGetter: getValueForSorting,
      },
      {
        field: 'actions',
        headerName: '',
        disableColumnMenu: true,
        sortable: false,
        renderCell: WorkflowActions,
      },
    ];
    return columns;
  }, [t]);
};
