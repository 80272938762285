import { useCallback, useMemo } from 'react';
import { FileAccepted, FileRejected } from '@verticeone/design-system';
import { autoDownload } from '@verticeone/utils/file';
import { FileMetaData, GetPreSignedLinkApiArg, useLazyGetPreSignedLinkQuery, useListFilesQuery } from '@vertice/slices';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { isTaskDocumentFullPath } from '../utils';

export const useCustomTasksDocuments = (requestId: string) => {
  const { accountId } = useAccountContext();
  const [getPreSignedLink] = useLazyGetPreSignedLinkQuery();

  const { data: requestAssets, isFetching } = useListFilesQuery(
    { accountId, 'path+': `requests/${requestId}/files` },
    { refetchOnMountOrArgChange: true }
  );

  const files = useMemo<FileAccepted[]>(() => {
    if (!requestAssets?.files) return [];

    return requestAssets.files
      .filter(({ type }) => type === 'FILE')
      .filter(({ fullPath }) => isTaskDocumentFullPath(fullPath))
      .sort((f1, f2) => f1.fileName.localeCompare(f2.fileName))
      .map(fromMetadataToFile);
  }, [requestAssets]);

  const getFileFullPath = useCallback(
    (fileName: string) => {
      if (!requestAssets?.files) return null;

      const fileMetadata = requestAssets.files
        .filter(({ type }) => type === 'FILE')
        .find((metadata) => metadata.fileName === fileName);

      if (!fileMetadata) return null;
      return fileMetadata.fullPath.split('/').slice(2).join('/');
    },
    [requestAssets]
  );

  const downloadFile = async ({ file }: FileAccepted | FileRejected) => {
    const fullPath = getFileFullPath(file.name);

    if (!fullPath) return;

    const request: GetPreSignedLinkApiArg = { accountId, 'path+': fullPath, responseContentDisposition: 'attachment' };
    const result = await getPreSignedLink(request).unwrap();

    if (!result.preSignedLink) return;

    autoDownload(result.preSignedLink, file.name);
  };

  return { files: files, isFetching: isFetching && !files, downloadFile };
};

const fromMetadataToFile = ({ fileName, fileSize, lastModified }: FileMetaData): FileAccepted => ({
  file: {
    name: fileName,
    size: fileSize,
    lastModified: lastModified ? new Date(lastModified).getTime() : 0,
  } as unknown as File,
});
