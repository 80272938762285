import { FieldMapping, TaskDefinition } from '../../../../../definitionsTypes';
import { ServiceCatalogResource } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { isIOMappingConfiguration } from '../../../../../definitions/taskDefinition';
import { ISSUE_TYPE_ID_NAME, PROJECT_ID_NAME } from '../JiraCommon/utils';

/**
 * Get Input mapping variables  used in  Workflow to Create Jira mapping
 * Jira ids are internally prefixed, we need to strip that prefix in preparation for used in variable picker
 * @param task
 * @param taskService
 */
export const getJiraCreateInputMapping = (task: TaskDefinition, taskService: ServiceCatalogResource) => {
  // get patter BE is expecting the jira fields have - like: "^jiraFields."
  const pattern = Object.keys(taskService.definition.ServiceProvider.Interface.Input.JsonSchema.patternProperties)[0];
  // eslint-disable-next-line no-useless-escape
  const escapedPattern = pattern.replace('.', `\.`); // Escape dot we need to keep

  let projectId: string | undefined;
  let issueTypeId: string | undefined;
  let includeAttachments: boolean | undefined;
  let variables: FieldMapping[] = [];

  const ioMappingConfiguration = task.task.configurations?.find(isIOMappingConfiguration);
  const inputFields = ioMappingConfiguration?.mapping.inputFields;

  if (ioMappingConfiguration && inputFields) {
    inputFields.forEach((field) => {
      // required field we need to know
      if (field.name === PROJECT_ID_NAME) {
        projectId = field.value;
        // required field we need to know
      } else if (field.name === ISSUE_TYPE_ID_NAME) {
        issueTypeId = field.value;
      } else if (field.name === 'includeAttachments') {
        includeAttachments = field.value === 'true';
      } else {
        const regex = new RegExp(`${escapedPattern}(.*)`); // Capturing group for the rest of the string
        const match = field.name.match(regex);
        let name = '';
        // To support old format with jiraFields. prefix
        if (match) {
          name = match[1];
        } else {
          name = field.name.split('<')[0];
        }
        variables.push({ ...field, name });
      }
    });
  }
  return {
    projectId,
    issueTypeId,
    includeAttachments,
    variables,
  };
};
