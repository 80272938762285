import { Close, OpenInNewOutlined } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Button as MuiButton, Stack, styled } from '@mui/material';
import { FC, forwardRef, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { IconWrapper, ifExpression, testProps, type TestProps, Text, Tooltip } from '@verticeone/design-system';
import { StyledTooltipContent } from '../components/StyledTooltipContent';
import { highlightText } from './HighlightedText';
import { StyledTypeBadge, TypeBadge } from './TypeBadge';

const InfoIcon = forwardRef<SVGSVGElement>((props, ref) => (
  <IconWrapper color="inherit" {...props} ref={ref} icon={InfoOutlinedIcon} size="S" />
));

const EllipsisText = styled(Text)({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  lineHeight: '1rem',
});

type StyledMuiButtonProps = {
  $isInline: boolean;
  $isSelected: boolean;
  $isActive: boolean;
  $isDisabled: boolean;
  $isError: boolean;
};

const StyledMuiButton = styled(MuiButton)<StyledMuiButtonProps>(
  ({
    theme: {
      palette: { secondary, inactive, neutral, transparent, error },
    },
    $isInline = false,
    $isSelected = false,
    $isActive = true,
    $isDisabled = false,
    $isError = false,
  }) => ({
    position: 'relative',
    display: 'flex',
    justifyContent: 'start',
    color: $isActive ? secondary[$isSelected ? 'color1' : 'color4'] : neutral.color1,
    gap: 4,
    padding: $isInline ? '2px 3px' : 6,
    borderRadius: $isInline ? 4 : 8,
    minWidth: 'unset',
    maxWidth: '100%',
    '&&': {
      '&::before': {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: $isInline ? 4 : 8,
        borderWidth: $isError ? 1 : undefined,
        borderColor: $isError ? error.color2 : undefined,
        borderStyle: $isError ? 'solid' : undefined,
        backgroundColor: $isActive ? secondary[$isSelected ? 'color4' : 'color2'] : 'auto',
        ...ifExpression($isDisabled, {
          backgroundColor: $isActive ? inactive.color4 : 'auto',
        }),
        ...ifExpression(!$isDisabled, {
          opacity: $isSelected ? 0.4 : 1,
        }),
        zIndex: -1,
      },
    },
    [`& ${StyledTypeBadge}`]: {
      backgroundColor: $isActive ? ($isSelected ? secondary.color4 : transparent.color4) : neutral.color4,
      color: $isActive ? secondary[$isSelected ? 'color1' : 'color4'] : neutral.color2,
    },
    ...ifExpression($isDisabled, {
      '&:disabled': {
        color: $isActive ? inactive.color1 : inactive.color2,
      },
      [`& ${StyledTypeBadge}`]: {
        backgroundColor: $isActive ? inactive.color3 : inactive.color4,
        color: inactive.color2,
      },
    }),
    '&:hover': {
      backgroundColor: 'transparent',
      color: $isActive ? secondary.hover[$isSelected ? 'color1' : 'color4'] : secondary.color1,
      '&&': {
        '&::before': {
          backgroundColor: $isActive ? secondary.hover[$isSelected ? 'color4' : 'color2'] : secondary.color4,
        },
      },
      [`& ${StyledTypeBadge}`]: {
        backgroundColor: $isActive ? ($isSelected ? secondary.color4 : transparent.color4) : secondary.color4,
        color: $isActive ? secondary.hover[$isSelected ? 'color1' : 'color4'] : secondary.color1,
      },
    },
    '&:focus-visible, &:active': {
      color: $isActive ? secondary[$isSelected && !$isInline ? 'color1' : 'color4'] : secondary.color1,
      '&&': {
        '&::before': {
          opacity: $isSelected && !$isInline ? 0.4 : 1,
          backgroundColor: $isActive ? secondary[$isSelected && !$isInline ? 'color4' : 'color2'] : secondary.color4,
          outline: `2px solid ${secondary[$isSelected && !$isInline ? 'color3' : 'color4']}`,
        },
      },
      [`& ${StyledTypeBadge}`]: {
        backgroundColor: $isActive
          ? $isSelected && !$isInline
            ? secondary.color4
            : transparent.color4
          : secondary.color4,
        color: $isActive ? secondary[$isSelected && !$isInline ? 'color1' : 'color4'] : secondary.color1,
      },
    },
  })
);

type VariableChipProps = TestProps & {
  isInline?: boolean;
  isDisabled?: boolean;
  isActive?: boolean;
  label?: string;
  typeLabel?: string;
  onClick?: () => void;
  withIcon?: boolean;
  highlightedLabel?: string;
  hint?: string;
  isError?: boolean;
  onDeselectIconClick?: () => void;
  tooltipContent?: string;
};

const VariableChipBase = forwardRef<HTMLButtonElement, Omit<VariableChipProps, 'tooltipContent'>>(
  (
    {
      label,
      isInline = false,
      isDisabled = false,
      isActive = true,
      isError = false,
      withIcon = false,
      typeLabel,
      onClick,
      testId,
      highlightedLabel = '',
      hint,
      onDeselectIconClick,
      ...restProps
    },
    ref
  ) => {
    const { t } = useTranslation();

    const isSelected = !!label;
    const isDeselectEnabled = isSelected && onDeselectIconClick;

    const labelBase = label || t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VARIABLE_SELECTOR.ACTIONS.SELECT_VARIABLE');
    const finalLabel = highlightText(labelBase, highlightedLabel);

    const handleIconClick: MouseEventHandler<HTMLDivElement> = (event) => {
      event.stopPropagation();
      onDeselectIconClick?.();
    };

    return (
      <StyledMuiButton
        {...testProps(testId, 'variable-chip')}
        $isInline={isInline}
        $isSelected={isSelected}
        $isActive={isActive}
        $isDisabled={isDisabled}
        $isError={isError}
        disabled={isDisabled}
        onClick={onClick}
        role="button"
        ref={ref}
        {...restProps}
      >
        <EllipsisText size="S" variant={isActive ? 'button-bold' : 'button-regular'}>
          {finalLabel}
        </EllipsisText>
        {hint && (
          <Tooltip size="S" content={<StyledTooltipContent>{hint}</StyledTooltipContent>}>
            <InfoIcon />
          </Tooltip>
        )}
        {typeLabel && <TypeBadge highlightedLabel={highlightedLabel} label={typeLabel} />}
        {withIcon && (
          <Stack
            role={isDeselectEnabled && !isDisabled ? 'button' : undefined}
            onClick={isDeselectEnabled && !isDisabled ? handleIconClick : undefined}
            sx={!typeLabel ? { marginLeft: 'auto' } : undefined}
          >
            <IconWrapper
              size="XS"
              sx={{
                opacity: 0.6,
              }}
              icon={isDeselectEnabled ? Close : OpenInNewOutlined}
            />
          </Stack>
        )}
      </StyledMuiButton>
    );
  }
);

export const VariableChip: FC<VariableChipProps> = ({ tooltipContent, ...restProps }) => {
  if (!tooltipContent) {
    return <VariableChipBase {...restProps} />;
  }

  return (
    <Tooltip size="S" content={<StyledTooltipContent>{tooltipContent}</StyledTooltipContent>}>
      <VariableChipBase {...restProps} />
    </Tooltip>
  );
};
