import React, { ReactNode } from 'react';
import { useGetServiceCatalogResources } from '../../hooks/useGetServiceCatalogResources';
import { ServicePlaceholderCard } from '../components/ServiceCardsV2/ServicePlaceholderCard';
import { ServiceCard } from '../components/ServiceCardsV2/ServiceCard';
import { ServiceCatalogResource } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { Resource } from '../types';

type UseServiceCardsProps = {
  filter?: (service: ServiceCatalogResource) => boolean;
  onClick?: (serviceRef: string) => void;
  getCardActions: (service: Resource) => ReactNode;
};

export const useServiceCardsV2 = (props: UseServiceCardsProps) => {
  const { resources, isLoading } = useGetServiceCatalogResources({
    resourcesFilter: (item) =>
      item.kind === 'Vertice/ServiceCatalog/Service/ServiceDefinition' && (!props?.filter || props.filter(item)),
    skipGlobalResources: true,
  });

  return (
    <>
      {isLoading
        ? Array.from({ length: 5 }).map((_, i) => <ServicePlaceholderCard key={i} />)
        : resources
            .sort((a, b) => a.name.localeCompare(b.name))
            .filter((resource) => resource.status === 'ACTIVE')
            .map((resource) => (
              <ServiceCard
                key={resource.urn}
                service={resource}
                onClick={props?.onClick}
                actions={props.getCardActions(resource)}
              />
            ))}
    </>
  );
};
