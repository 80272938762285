import React from 'react';
import { motion } from 'framer-motion';

const AnimatedBar = ({ width, color }: { width: number; color: string }) => {
  return (
    <motion.div
      style={{
        width: width + '%',
        height: 8,
        borderRadius: 2.5,
        backgroundColor: color,
      }}
      animate={{ width: width + '%' }}
      transition={{ type: 'tween', duration: 0.25 }}
    />
  );
};

export default AnimatedBar;
