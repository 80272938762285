import React, { PropsWithChildren, ReactNode } from 'react';
import { Stack } from '@mui/material';
import { Card, IconButton, Link, Text } from '@verticeone/design-system';
import { FilterListOutlined } from '@mui/icons-material';

type ListCardProps = PropsWithChildren & {
  testId: string;
  title: string;
  description?: ReactNode;
  showAllText: string;
  onShowAllClick: () => void;
};

const ListCard = ({ testId, title, description, children, showAllText, onShowAllClick }: ListCardProps) => {
  return (
    <Card testId={testId}>
      <Stack p={7} gap={6}>
        <Stack direction="row" justifyContent="space-between" gap={2}>
          <Stack gap={1}>
            <Text variant="body-bold" size="L" color="text1">
              {title}
            </Text>
            {description && (
              <Text variant="body-regular" size="XS" color="text3">
                {description}
              </Text>
            )}
          </Stack>
          <IconButton size="L" icon={FilterListOutlined} variant="outline" />
        </Stack>
        <Stack>{children}</Stack>
        <Stack>
          <Link href="#" onClick={onShowAllClick} color="secondary" size="XS" withArrow>
            {showAllText}
          </Link>
        </Stack>
      </Stack>
    </Card>
  );
};

export default ListCard;
