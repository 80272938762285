import React, { FC, forwardRef, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardHeader,
  CardHeaderDescription,
  CardHeaderTitle,
  IconWrapper,
  Text,
} from '@verticeone/design-system';
import { Trans, useTranslation } from 'react-i18next';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { Box, CircularProgress, Link, LinkProps, Stack, styled } from '@mui/material';
import {
  GetJiraWebhookConfigApiResponse,
  useGetJiraWebhookConfigQuery,
  useRemoveJiraWebhookConfigMutation,
  useSetJiraWebhookConfigMutation,
} from '@vertice/slices';
import { CheckCircleOutlined, ErrorOutline } from '@mui/icons-material';
import { To } from 'react-router-dom';
import { JIRA_INTEGRATION_GUIDE_URL } from '../constants';

type StyledLinkProps = LinkProps & { to?: To };
export const StyledLink = styled(
  forwardRef<HTMLAnchorElement, StyledLinkProps>((props: StyledLinkProps, ref) => (
    <Link {...props} ref={props.ref ?? ref} />
  ))
)(({ theme }) => ({
  color: theme.palette.text.color2,
  textDecorationColor: theme.palette.text.color2,
  '&:hover': {
    textDecoration: 'none',
  },
}));

type ConnectionInfoProps = {
  isConnected: boolean;
};

const ConnectionInfo: FC<ConnectionInfoProps> = ({ isConnected }) => {
  const { t } = useTranslation();
  const color = isConnected ? 'success' : 'error';
  const icon = isConnected ? CheckCircleOutlined : ErrorOutline;
  const text = t(`PREFERENCES.INTEGRATIONS.JIRA.SYNC_CARD.${isConnected ? 'CONNECTED' : 'DISCONNECTED'}`);
  return (
    <Stack direction="row" gap={2}>
      <IconWrapper icon={icon} color={color} />
      <Text variant="button-bold" size="M" color={color}>
        {text}
      </Text>
    </Stack>
  );
};

export const SynchronizationCard = () => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();

  const { data, isLoading } = useGetJiraWebhookConfigQuery({ accountId });
  const [removeWebhookMutation, removeWebhookState] = useRemoveJiraWebhookConfigMutation();
  const [setupWebhhokMutation, setupWebhhokState] = useSetJiraWebhookConfigMutation();
  const [webhookData, setWebhookData] = useState<GetJiraWebhookConfigApiResponse | undefined>();

  useEffect(() => {
    setWebhookData(data);
  }, [data]);

  const handleReconnect = async () => {
    const setupResult = await setupWebhhokMutation({ accountId });
    if (setupResult && 'data' in setupResult) {
      setWebhookData(setupResult.data);
    }
  };
  const handleDisconnect = async () => {
    const removeResult = await removeWebhookMutation({ accountId });
    if (removeResult && 'data' in removeResult) {
      setWebhookData(removeResult.data);
    }
  };

  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle text={t('PREFERENCES.INTEGRATIONS.JIRA.SYNC_CARD.TITLE')} />
        <CardHeaderDescription>
          <Box
            sx={{
              width: {
                lg: '70%',
              },
            }}
          >
            <Text variant="body-regular" size="S" color="text2">
              <Trans
                i18nKey="PREFERENCES.INTEGRATIONS.JIRA.SYNC_CARD.DESCRIPTION"
                components={{
                  a: <StyledLink href={JIRA_INTEGRATION_GUIDE_URL} />,
                }}
              />
            </Text>
          </Box>
        </CardHeaderDescription>
      </CardHeader>
      <Stack gap={6} p={6} direction="row" alignItems="center">
        {isLoading ? (
          <Stack minHeight={32} justifyContent="center">
            <CircularProgress size={16} />
          </Stack>
        ) : webhookData?.isValid ? (
          <>
            <Button
              onClick={handleDisconnect}
              variant="outline"
              color="neutral"
              size="M"
              isLoading={removeWebhookState.isLoading}
            >
              {t('PREFERENCES.INTEGRATIONS.JIRA.SYNC_CARD.DISCONNECT_BUTTON')}
            </Button>
            <ConnectionInfo isConnected={true} />
          </>
        ) : (
          <Button
            onClick={handleReconnect}
            variant="outline"
            color="primary"
            size="M"
            isLoading={setupWebhhokState.isLoading}
          >
            {t('PREFERENCES.INTEGRATIONS.JIRA.SYNC_CARD.CONNECT_BUTTON')}
          </Button>
        )}
      </Stack>
    </Card>
  );
};
