import { FC } from 'react';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { match } from 'ts-pattern';
import { ContractRenewActionNonSaas } from './ContractRenewActionNonSaas';
import { ContractRenewActionSaasIW } from './ContractRenewActionSaasIW';
import { ContractRenewActionSaas } from './ContractRenewActionSaas';
import { isNonSass } from '@vertice/core/src/modules/saas/contract/computed';

type ContractNotYetRenewedContentActionsProps = {
  contract: Contract;
  onSaasIWRequest: () => void;
};

/**
 * This component determines the appropriate contract renewal actions
 * based on the contract category and enabled features.
 *
 * - If the contract category is `NonSass`, it renders `ContractRenewActionNonSass`.
 * - If the `IntelligentWorkflow` feature is enabled, it renders `ContractRenewActionSassIW`.
 * - Otherwise, it renders `ContractRenewActionSass` (will be deprecated in future).
 *
 * @param contract - The contract, where actions should be determinated.
 */
export const ContractNotYetRenewedContentActions: FC<ContractNotYetRenewedContentActionsProps> = (props) => {
  const { contract, onSaasIWRequest } = props;
  const { isFeatureEnabled } = useAccountContext();

  const isIWEnabled = isFeatureEnabled(FEATURES.INTELLIGENT_WORKFLOWS);

  return match({ isNonSass: isNonSass(contract), isIWEnabled })
    .with({ isNonSass: true }, () => <ContractRenewActionNonSaas contract={contract} />)
    .with({ isIWEnabled: false }, () => <ContractRenewActionSaas contract={contract} />)
    .with({ isIWEnabled: true }, () => (
      <ContractRenewActionSaasIW contract={contract} onSaasIWRequest={onSaasIWRequest} />
    ))
    .exhaustive();
};
