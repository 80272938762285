import { Box, Stack, useTheme } from '@mui/material';
import { FC, ReactElement, ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import { BreadcrumbItem, PageHeader } from '@verticeone/design-system';
import { useRoutes } from '@verticeone/router/src';

const MAX_CARD_WIDTH = 900;

type IntegrationSetupProps = {
  breadcrumb: {
    label: string;
    icon: ReactElement;
  };
  actionButton: ReactNode;
  stepContent: ReactNode;
};

export const IntegrationSetup: FC<IntegrationSetupProps> = ({
  breadcrumb: { label, icon },
  actionButton,
  stepContent,
}) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const routes = useRoutes();

  const items: BreadcrumbItem<typeof Link>[] = [
    {
      label: t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.BREADCRUMBS_PREFERENCES'),
      component: Link,
      to: generatePath(routes.PREFERENCES.ABSOLUTE_PATH),
    },
    {
      label: label,
      startAdornment: icon,
    },
  ];

  return (
    <Stack flex={1} bgcolor={palette.core.color1}>
      <PageHeader breadcrumb={<PageHeader.Breadcrumbs items={items} />} color="neutral" actions={actionButton} />
      <Stack direction="row" justifyContent="center" p={6} borderTop={`1px solid ${palette.core.color3}`}>
        <Box flex={1} maxWidth={MAX_CARD_WIDTH}>
          {stepContent}
        </Box>
      </Stack>
    </Stack>
  );
};
