import { ChipButton, DesignSystemColor, IconWrapper, Text, useFormatDate } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { Stack, useTheme } from '@mui/material';
import VendorLogo from '@vertice/core/src/components/VendorLogo';
import React, { FunctionComponent, SVGProps } from 'react';
import { SvgIconComponent } from '@mui/icons-material';

const getHumanReadableDifference = (daysDiff: number) => {
  const isDueSoon = daysDiff >= 0 && daysDiff <= 4;
  const absDays = Math.abs(daysDiff);

  if (isDueSoon) {
    return null;
  }

  if (absDays < 7) {
    return `${absDays}d`;
  } else if (absDays < 30) {
    const weeks = Math.floor(absDays / 7);
    return `${weeks}w`;
  } else if (absDays < 365) {
    const months = Math.floor(absDays / 30);
    return `${months}m`;
  } else {
    const years = Math.floor(absDays / 365);
    return `${years}y`;
  }
};

const getChipColor = (diff: number): DesignSystemColor => {
  if (diff <= -8) return 'error';
  if (diff <= -1) return 'warning';
  if (diff <= 4) return 'neutral';
  if (diff <= 8) return 'secondary';
  return 'primary';
};

type ListItemProps = {
  title: string;
  subtitle: string;
  vendorId?: string;
  date: string;
  icon?: SvgIconComponent | FunctionComponent<SVGProps<SVGSVGElement>>;
};

const ListItem = ({ title, subtitle, vendorId, date, icon }: ListItemProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD.TASK_STATUS' });
  const daysDifference = dayjs(date).diff(dayjs(), 'day');
  const isOverdue = daysDifference < 0;
  const isDueSoon = daysDifference >= 0 && daysDifference <= 4;
  const daysText = isDueSoon ? t('DUE_SOON') : isOverdue ? t('OVERDUE') : t('DUE_IN');
  const formatDate = useFormatDate();
  const { palette } = useTheme();

  return (
    <Stack direction="row" gap={4} py={3}>
      {icon ? (
        <Stack
          width={40}
          height={40}
          borderRadius={2}
          border={1}
          borderColor={palette.neutral.color3}
          alignItems="center"
          justifyContent="center"
        >
          <IconWrapper icon={icon} size="XL" htmlColor={palette.core.color5} />
        </Stack>
      ) : (
        <VendorLogo vendorId={vendorId ?? undefined} size={40} />
      )}
      <Stack direction="row" justifyContent="space-between" flex={1}>
        <Stack direction="column" gap={2}>
          <Text variant="body-bold" size="S">
            {title}
          </Text>
          <Text variant="body-regular" size="XS" color="text3">
            {subtitle}
          </Text>
        </Stack>
        <Stack direction="column" gap={2} alignItems="flex-end">
          <ChipButton
            color={getChipColor(daysDifference)}
            label={daysText}
            size="S"
            isActive={false}
            testId="days-difference-chip"
            variant="solid"
          >
            {getHumanReadableDifference(daysDifference)}
          </ChipButton>
          <Text variant="body-regular" color="text4" size="XS">
            {formatDate(date)}
          </Text>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default ListItem;
