import { Stack } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { Loader, Text, TextFieldCaption } from '@verticeone/design-system';
import { createTypedFormEntry } from '../../../../../../forms/fields/FormEntry';
import FormSelectField2 from '../../../../../../forms/fields/FormSelectField2';
import { EditorDrawerGroup } from '../../../EditorDrawer';
import { VariableOrigin } from '../../../types';
import { MissingSetup } from '../JiraCommon/MissingSetup';
import { JiraFieldOption } from '../JiraCommon/types';
import { VariableMapper } from '../../../VariableMapper/VariableMapper';
import { JiraSyncTaskFormData } from './formSchema';
import { JiraSyncFormProps } from './JiraSyncForm';
import { getAvailableCreateJiraTasks } from './utils';
import { NoJiraVariables } from '../JiraCommon/NoJiraVariables';
import { createJiraVariablesGroups } from '../JiraCommon/utils';
import { IntegrationWrapper } from '../JiraCommon/IntegrationWrapper';

const JiraSyncFormEntry = createTypedFormEntry<JiraSyncTaskFormData>();

type JiraSyncSetupProps = Pick<
  JiraSyncFormProps,
  | 'syncWebhookQuery'
  | 'workflowVariables'
  | 'setWorkflowVariables'
  | 'hasReferencedIssueType'
  | 'isLoadingIntegration'
  | 'variableMapperProps'
  | 'completeCriteriaProps'
  | 'task'
  | 'integrationWrapperProps'
> & {
  taskId: string;
};

export const JiraSyncSetup: FC<JiraSyncSetupProps> = ({
  syncWebhookQuery,
  workflowVariables,
  setWorkflowVariables,
  taskId,
  hasReferencedIssueType,
  variableMapperProps,
  completeCriteriaProps,
  task,
  integrationWrapperProps,
}) => {
  const { t } = useTranslation();

  const { data: syncWebhookData, isLoading: isLoadingSyncWebhook } = syncWebhookQuery;

  const allVariables = useMemo(() => [...workflowVariables.udfs, ...workflowVariables.request], [workflowVariables]);
  const { createJiraTasks } = getAvailableCreateJiraTasks(allVariables);

  if (isLoadingSyncWebhook) {
    return (
      <Stack alignItems="center" mt={8}>
        <Loader />
      </Stack>
    );
  }

  if (!syncWebhookData?.isValid) {
    return (
      <MissingSetup
        sx={{ mt: 4 }}
        title={t('INTELLIGENT_WORKFLOWS.JIRA.SYNC_NOT_SETUP.TITLE')}
        description={t('INTELLIGENT_WORKFLOWS.JIRA.SYNC_NOT_SETUP.DESCRIPTION')}
      />
    );
  }

  return (
    <IntegrationWrapper {...integrationWrapperProps}>
      <EditorDrawerGroup title={t('INTELLIGENT_WORKFLOWS.JIRA.JIRA_SYNC_SETUP')}>
        <Stack gap={2}>
          <Stack>
            <TextFieldCaption
              htmlFor="jira-create-task-select"
              label={t('INTELLIGENT_WORKFLOWS.JIRA.SELECTED_TASK.LABEL')}
              size="XS"
            />
            <Text variant="body-regular" size="S" color="text3">
              {t('INTELLIGENT_WORKFLOWS.JIRA.SELECTED_TASK.INFO')}
            </Text>
          </Stack>
          <JiraSyncFormEntry
            width={4}
            name={'taskId'}
            component={FormSelectField2}
            componentProps={{
              id: 'jira-create-task-select',
              size: 'S',
              placeholder: t('INTELLIGENT_WORKFLOWS.JIRA.SELECTED_TASK.PLACEHOLDER'),
              getOptionValue: (option: VariableOrigin) => option.id,
              getOptionLabel: (option: VariableOrigin) => option.label,
              options: createJiraTasks,
              boxValue: (value: VariableOrigin['id']) =>
                value ? createJiraTasks.find((a) => a.id === value) : undefined,
              unboxValue: (selectValue: VariableOrigin) => selectValue?.id,
            }}
          />
        </Stack>
        {!!taskId &&
          (!hasReferencedIssueType ? (
            <Text variant="body-regular" size="XS" color="inactive" sx={{ mt: 8 }}>
              {t('INTELLIGENT_WORKFLOWS.JIRA.NO_ISSUE_TYPE')}
            </Text>
          ) : (
            <>
              <VariableMapper<JiraSyncTaskFormData>
                formFieldName="variables"
                requiredFormFieldName="requiredVariables"
                otherVariablesTitle={t('INTELLIGENT_WORKFLOWS.JIRA.VARIABLE_MAPPER.COLUMN_HEADER')}
                fromWorkflow={false}
                variableMapperRowProps={{ createOtherVariableGroups: createJiraVariablesGroups, task }}
                components={{ noOtherVariables: <NoJiraVariables /> }}
                workflowVariables={workflowVariables}
                setWorkflowVariables={setWorkflowVariables}
                allowCreateVariable={true}
                {...variableMapperProps}
              />

              <Stack gap={2}>
                <Stack>
                  <TextFieldCaption
                    htmlFor="jira-create-task-select"
                    label={t('INTELLIGENT_WORKFLOWS.JIRA.COMPLETE_CRITERIA.LABEL')}
                    size="XS"
                  />
                  <Text variant="body-regular" size="S" color="text3">
                    {t('INTELLIGENT_WORKFLOWS.JIRA.COMPLETE_CRITERIA.INFO')}
                  </Text>
                </Stack>
                <JiraSyncFormEntry
                  width={4}
                  name={'filterStatus'}
                  component={FormSelectField2}
                  componentProps={{
                    id: 'jira-status-select',
                    size: 'S',
                    isMulti: true,
                    isLoading: completeCriteriaProps.isLoading,
                    placeholder: t('INTELLIGENT_WORKFLOWS.JIRA.COMPLETE_CRITERIA.PLACEHOLDER'),
                    getOptionValue: (option: JiraFieldOption) => option.id,
                    getOptionLabel: (option: JiraFieldOption) => option.label,
                    options: completeCriteriaProps.availableStatuses,
                    boxValue: (value: JiraFieldOption['id'][]) =>
                      value ? completeCriteriaProps.availableStatuses?.filter((a) => value.includes(a.id)) : undefined,
                    unboxValue: (selectValue: JiraFieldOption[]) => selectValue?.map((item) => item.id),
                  }}
                />
              </Stack>
            </>
          ))}
      </EditorDrawerGroup>
    </IntegrationWrapper>
  );
};
