import React, { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useFormState, useWatch } from 'react-hook-form';
import { useWizard, WizardStepProps } from '@vertice/core/src/components/Wizard';
import { Buttons, Content, Header, InnerScrollable } from '@vertice/core/src/components/Dialog/Components';
import { AddExistingContractFormData, AddExistingContractFormEntry } from '../../types';
import FormVendorSelectField from '@vertice/core/src/modules/vendor/formFields/FormVendorSelectField';
import { FormVendorSelectFieldComponentType } from '@vertice/core/src/modules/vendor/formFields/FormVendorSelectField/FormVendorSelectField';
import { FormDepartmentSelectField } from '@vertice/core/src/modules/departments/FormDepartmentSelectField';
import { FormDepartmentSelectFieldType } from '@vertice/core/src/modules/departments/FormDepartmentSelectField/FormDepartmentSelectField';
import FormSection from '@vertice/core/src/modules/forms/FormSection';
import { ONLY_OWNED_DEPARTMENTS } from '../../constants';
import { FormToggleButtonField } from '@vertice/core/src/modules/forms/fields/FormToggleButtonField';
import { ContractEntityCategory } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

const useSelectVendorWizard = () => {
  const { goToStep } = useWizard();
  const goToNext = useCallback(() => goToStep('contract_type'), [goToStep]);
  return { goToNext };
};

const SelectVendor: React.FC<WizardStepProps> = () => {
  const { goToNext } = useSelectVendorWizard();
  const { t } = useTranslation();

  const { setValue } = useFormContext<AddExistingContractFormData>();
  const vendor = useWatch<AddExistingContractFormData, 'vendor'>({ name: 'vendor' });
  const formState = useFormState();
  const prevVendorRef = useRef(vendor);

  useEffect(() => {
    if (vendor && vendor !== prevVendorRef.current) {
      prevVendorRef.current = vendor;
      setValue('products', []);
    }
  }, [goToNext, setValue, vendor]);

  return (
    <Content>
      <Header
        title={t('INTAKE_FORM.EXISTING_CONTRACT_SELECT_VENDOR.HEADING_TITLE')}
        subtitle={t('INTAKE_FORM.EXISTING_CONTRACT_SELECT_VENDOR.HEADING_SUBTITLE')}
      />
      <InnerScrollable>
        <FormSection>
          <AddExistingContractFormEntry<FormDepartmentSelectFieldType<AddExistingContractFormData>>
            name="departmentId"
            width={12}
            label={t('ENTITIES.CONTRACT.LABELS.DEPARTMENT')}
            component={FormDepartmentSelectField}
            componentProps={{
              isClearable: false,
              onlyOwnedDepartments: ONLY_OWNED_DEPARTMENTS,
            }}
            required
          />
          <AddExistingContractFormEntry
            name="contractCategory"
            width={12}
            label={t('ENTITIES.CONTRACT.LABELS.CONTRACT_CATEGORY')}
            component={FormToggleButtonField}
            componentProps={{
              color: 'neutral',
              options: [
                {
                  title: t('ENTITIES.CONTRACT.CONTRACT_CATEGORIES.SAAS'),
                  label: t('ENTITIES.CONTRACT.CONTRACT_CATEGORIES.EG_HUBSPOT'),
                  value: 'SAAS' satisfies ContractEntityCategory,
                },
                {
                  title: t('ENTITIES.CONTRACT.CONTRACT_CATEGORIES.EVERYTHING_ELSE'),
                  label: t('ENTITIES.CONTRACT.CONTRACT_CATEGORIES.EG_OFFICE_SUPPLIES'),
                  value: 'OTHER' satisfies ContractEntityCategory,
                },
              ],
            }}
            required
          />
          <AddExistingContractFormEntry<FormVendorSelectFieldComponentType<AddExistingContractFormData>>
            key={vendor ? vendor.id : null}
            name="vendor"
            label={t('ENTITIES.CONTRACT.LABELS.VENDOR')}
            required
            width={12}
            component={FormVendorSelectField}
            componentProps={{
              placeholder: t('ENTITIES.CONTRACT.PLACEHOLDERS.VENDOR'),
              inlineEnabled: true,
            }}
          />
        </FormSection>
      </InnerScrollable>
      <Buttons
        primary={{
          onClick: goToNext,
          title: t('DIALOG.BUTTONS.NEXT'),
          disabled: !formState.isValid,
        }}
      />
    </Content>
  );
};

export default SelectVendor;
