import React, { useMemo } from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts, { SeriesOptionsType } from 'highcharts';
import { Box, Stack, useTheme } from '@mui/material';
import {
  cssObjectToString,
  monthFormatter,
  yLabelStyles,
} from '@vertice/core/src/components/charts/highcharts-specific/utils/formatters';
import { useXAxisOffset } from '@vertice/core/src/components/charts/highcharts-specific/plugins/useXAxisOffset';
import { buildOptions, mergeOptions } from '@vertice/core/src/components/charts/highcharts-specific/utils/optionsUtils';
import useStyledHighcharts from '@vertice/core/src/components/charts/highcharts-specific/plugins/useStyledHighcharts';
import { useChartRef } from '@vertice/core/src/components/charts/highcharts-specific/utils/useChartRef';
import { SeriesOptionsWithData } from '@vertice/core/src/components/charts/highcharts-specific/types';
import { ifExpression } from '@verticeone/utils/logic';
import { useCompletedRequestsContext } from './CompleteRequestsContext';
import CompletedRequestFilter from './CompletedRequestFilter';

type CompletedRequestsGraphProps = {
  data: any;
};

const CompletedRequestsGraph = ({ data }: CompletedRequestsGraphProps) => {
  const [setChartRef] = useChartRef();
  const { palette } = useTheme();
  const applyXAxisOffset = useXAxisOffset();
  const applyStyledHighcharts = useStyledHighcharts();
  const { setTooltipData } = useCompletedRequestsContext();

  const coloredValues = data.values as unknown as SeriesOptionsWithData[];

  const series = useMemo(() => {
    return coloredValues.map((item) => {
      return {
        ...item,
        ...ifExpression(item.id === 'onTime', {
          id: 'onTime',
          name: 'Completed on-time',
          type: 'column',
          color: palette.visualization.monochromatic.success[70],
        }),
        ...ifExpression(item.id === 'late', {
          id: 'late',
          name: 'Completed late',
          type: 'column',
          color: palette.visualization.monochromatic.success[40],
        }),
      };
    }) as SeriesOptionsType[];
  }, [coloredValues, palette]);

  const options = useMemo(
    () =>
      buildOptions([
        applyXAxisOffset,
        applyStyledHighcharts,
        mergeOptions({
          chart: { type: 'column', height: 300, marginTop: 30 },
          plotOptions: {
            column: {
              maxPointWidth: 32,
              borderRadius: 8,
              point: {
                events: {
                  mouseOver() {
                    const point = this;
                    const categoryData = this.series.chart.series.reduce((acc, item) => {
                      acc[`${item.userOptions.id}`] = item.points[point.index].y ?? 0;
                      return acc;
                    }, {} as Record<string, number>);

                    setTooltipData({
                      values: {
                        ...categoryData,
                      },
                      category: point.category,
                      total: Object.values(categoryData).reduce((acc, value) => acc + value, 0),
                    });
                  },
                  mouseOut() {
                    setTooltipData(null);
                  },
                },
              },
            },
          },
          yAxis: {
            labels: {
              formatter: ({ value }: { value: number | string }) =>
                `<span style="${cssObjectToString(yLabelStyles(palette))}">${value}</span>`,
            },
          },
          xAxis: {
            tickLength: 0,
            categories: data.months,
            labels: { formatter: monthFormatter },
          },
          tooltip: { shared: true, enabled: false },
          series,
        }),
      ]),
    [applyXAxisOffset, applyStyledHighcharts, data.months, series, setTooltipData, palette]
  );

  return (
    <Box bgcolor={palette.core.color1} py={6} borderRadius={2}>
      <Stack gap={1.5}>
        <CompletedRequestFilter />
        <HighchartsReact highcharts={Highcharts} options={options} callback={setChartRef} />
      </Stack>
    </Box>
  );
};

export default CompletedRequestsGraph;
