import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, MenuItem, Stack } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import FormFilesDropArea from '@vertice/core/src/modules/forms/fields/FormFilesDropArea';
import FormNumberField from '@vertice/core/src/modules/forms/fields/FormNumberField';
import FormSection from '@vertice/core/src/modules/forms/FormSection';
import FormSelectField from '@vertice/core/src/modules/forms/fields/FormSelectField';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { TextFieldCaption } from '@verticeone/design-system';
import { useWizard, WizardStepProps } from '@vertice/core/src/components/Wizard';
import { Header, InnerScrollable, Content, Buttons } from '@vertice/core/src/components/Dialog/Components';
import { AddExistingContractFormData, AddExistingContractFormEntry } from '../../types';
import SelectedVendorPanel from '../../../SharedComponents/SelectedVendorPanel';
import FilesList from '../../../SharedComponents/FilesList';
import { useParams } from 'react-router-dom';
import { useSetFormVendor } from '../../../sharedHooks/useSetFormVendor';
import { useSetFormCurrency } from '../../../sharedHooks/useSetFormCurrency';
import { IntakeProductList } from '../../../SharedComponents/IntakeProductList';
import { getCurrencySymbol } from '@verticeone/utils/formatting';
import { currencies } from '@verticeone/utils/currency';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';

const currenciesWithEmptyValue = ['', ...currencies];

const useUploadExistingContractWizard = () => {
  const wizard = useWizard();

  return {
    goBack: wizard.goBack,
    goToNext: () => wizard.goToStep('additional_information'),
  };
};

const UploadExistingContract: React.FC<WizardStepProps> = () => {
  const { id: vendorId } = useParams();
  const { accountId } = useAccountContext();
  const { t } = useTranslation();
  const { locale } = useLocaleContext();

  const { goBack, goToNext } = useUploadExistingContractWizard();

  const { watch, formState, trigger } = useFormContext<AddExistingContractFormData>();
  const { contractCurrency, files, departmentId, vendor, totalAnnualCost } = watch();

  useSetFormVendor(vendorId);
  useSetFormCurrency(accountId);

  const nextEnabled = useMemo(() => formState.isValid, [formState]);
  useEffect(() => {
    void trigger(['files', 'departmentId']);
  }, [trigger, files, departmentId]);

  return (
    <Content>
      <Header
        title={t('INTAKE_FORM.EXISTING_CONTRACT_UPLOAD.HEADING_TITLE')}
        subtitle={t('INTAKE_FORM.EXISTING_CONTRACT_UPLOAD.HEADING_SUBTITLE')}
      />
      <SelectedVendorPanel showProducts={false} />
      <InnerScrollable>
        <FormSection>
          {vendor && vendor.type !== 'INLINE' && (
            <Grid item xs={12}>
              <IntakeProductList />
            </Grid>
          )}
          <AddExistingContractFormEntry
            name="totalAnnualCost"
            label={t('ENTITIES.CONTRACT.LABELS.TOTAL_ANNUAL_COST')}
            component={FormNumberField}
            componentProps={{
              placeholder: t('ENTITIES.CONTRACT.PLACEHOLDERS.TOTAL_ANNUAL_COST'),
              minValue: 0,
              unit: getCurrencySymbol(locale, contractCurrency || ''),
              unitPosition: 'start',
            }}
          />
          <AddExistingContractFormEntry
            name="contractCurrency"
            label={t('ENTITIES.CONTRACT.LABELS.CONTRACT_CURRENCY')}
            component={FormSelectField}
            required={totalAnnualCost !== null}
          >
            {currenciesWithEmptyValue.map((c) => (
              <MenuItem key={c} value={c}>
                {c}
              </MenuItem>
            ))}
          </AddExistingContractFormEntry>

          <Grid item xs={12}>
            <Stack direction="column" spacing={2}>
              <TextFieldCaption
                label={t('INTAKE_FORM.EXISTING_CONTRACT_UPLOAD.LABELS.EXISTING_CONTRACT_FILE')}
                size="XS"
                required
              />
              <FormFilesDropArea name="files" required />
              <FilesList />
            </Stack>
          </Grid>
        </FormSection>
      </InnerScrollable>
      <Buttons
        secondary={{
          onClick: goBack,
          title: t('DIALOG.BUTTONS.BACK'),
        }}
        primary={{
          onClick: goToNext,
          title: t('DIALOG.BUTTONS.NEXT'),
          disabled: !nextEnabled,
        }}
      />
    </Content>
  );
};

export default UploadExistingContract;
