import { zodResolver } from '@hookform/resolvers/zod';
import { FC, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { GetJiraWebhookConfigApiResponse, useGetJiraWebhookConfigQuery } from '@vertice/slices';
import { ServiceCatalogResource } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { DEFINITION_VERSION } from '../../../../../definitions/constants';
import { updateTaskIOMapping } from '../../../../../definitions/taskDefinition';
import { FieldMapping } from '../../../../../definitionsTypes';
import {
  EditorDrawerFooter,
  EditorDrawerForm,
  EditorDrawerHeader,
  EditorDrawerScrollContainer,
} from '../../../EditorDrawer';
import { TaskProperties } from '../../../forms/EditServiceTask/TaskProperties';
import { JiraSetupLink } from '../JiraCommon/JiraSetupLink';
import { JiraFieldOption } from '../JiraCommon/types';
import { enhanceInputFieldsName, TICKET_ID_NAME } from '../JiraCommon/utils';
import { DEFAULT_MAPPING_TYPE, VariableMapperProps } from '../../../VariableMapper/VariableMapper';
import { EditServiceTaskBase } from '../types';
import { ISSUE_KEY_INPUT_FIELD_VALUE, JIRA_FIELD_PREFIX_V2, SUMMARY_INPUT_FIELD_VALUE } from '../utils';
import { JiraSyncTaskFormData, jiraSyncTaskFormSchema } from './formSchema';
import { JiraSyncSetup } from './JiraSyncSetup';
import { getAvailableCreateJiraTasks, useVariablesWithDefault } from './utils';
import { WorkflowVariables } from '../../../VariableMapper/types';
import { getVariableXDataSourceParams, getVariableXType } from '../../../VariableMapper/utils';
import { IntegrationWrapperBaseProps } from '../JiraCommon/IntegrationWrapper';

export type JiraSyncFormProps = Pick<EditServiceTaskBase, 'task' | 'onSave' | 'onDirty' | 'onClose'> & {
  taskService: ServiceCatalogResource;
  workflowVariables: WorkflowVariables;
  setWorkflowVariables: (variables: WorkflowVariables) => void;
  defaultValues: JiraSyncTaskFormData;
  onTaskChange: (taskId?: string) => void;
  variableMapperProps: Pick<
    VariableMapperProps<JiraSyncTaskFormData>,
    'otherVariables' | 'isLoading' | 'mapperRowsCount'
  >;
  isLoadingIntegration: boolean;
  hasReferencedIssueType: boolean; // To show section after referenced task is selected (and through it selected issue type)
  completeCriteriaProps: {
    isLoading: boolean;
    availableStatuses: JiraFieldOption[];
  };
  noReferenceIssuePlaceholder: string;
  synchronisationWebhookData?: GetJiraWebhookConfigApiResponse;
  syncWebhookQuery: ReturnType<typeof useGetJiraWebhookConfigQuery>;
  integrationWrapperProps: IntegrationWrapperBaseProps;
};

export const JiraSyncForm: FC<JiraSyncFormProps> = ({
  task,
  defaultValues,
  onSave,
  onDirty,
  onClose,
  onTaskChange,
  variableMapperProps,
  isLoadingIntegration,
  workflowVariables,
  setWorkflowVariables,
  hasReferencedIssueType,
  completeCriteriaProps,
  syncWebhookQuery,
  integrationWrapperProps,
}) => {
  const allVariables = useMemo(() => [...workflowVariables.udfs, ...workflowVariables.request], [workflowVariables]);
  const { createJiraTicketsByTasks } = getAvailableCreateJiraTasks(allVariables);
  const { otherVariables } = variableMapperProps;

  /*** Setup form ***/
  const formHandlers = useForm<JiraSyncTaskFormData>({
    resolver: zodResolver(jiraSyncTaskFormSchema),
    defaultValues: defaultValues,
  });
  const { resetField, setValue, watch } = formHandlers;

  const formTaskName = watch('name');

  const enhancedDefaultVariables = useVariablesWithDefault({
    task,
    currentTaskName: formTaskName,
    externalVariables: otherVariables,
    workflowVariables,
    setWorkflowVariables,
  });
  useEffect(() => {
    if (enhancedDefaultVariables) {
      // resetField does not work, so setValue is used
      setValue('requiredVariables', enhancedDefaultVariables.requiredFormVariables, { shouldDirty: false });
      setValue('variables', enhancedDefaultVariables.restFormVariables, { shouldDirty: false });
    }
  }, [enhancedDefaultVariables, resetField, setValue]);

  const taskId = formHandlers.watch('taskId');
  useEffect(() => onTaskChange(taskId), [taskId, onTaskChange]);

  const onSubmit = (data: JiraSyncTaskFormData) => {
    if (!task) return;

    const allDataVariables = [...data.requiredVariables, ...data.variables];

    const inputFields = allDataVariables.map((mapperVariable) => {
      const inputField: FieldMapping = {
        name: enhanceInputFieldsName(
          workflowVariables,
          otherVariables || [],
          mapperVariable,
          false,
          JIRA_FIELD_PREFIX_V2
        ),
        value: mapperVariable.to,
        mappingType: 'Value',
      };
      return inputField;
    });

    if (data.taskId) {
      inputFields.push({
        name: TICKET_ID_NAME,
        value: createJiraTicketsByTasks[data.taskId].id,
        mappingType: DEFAULT_MAPPING_TYPE,
      });
    }

    if (data.filterStatus) {
      inputFields.push({
        name: 'filter.status',
        value: `\`${JSON.stringify(data.filterStatus)}\``,
        mappingType: DEFAULT_MAPPING_TYPE,
      });
    }

    inputFields.push({
      name: 'issue.key<urn:verticeone:vertice::services:schema/integrations/jira/string/v1,string>',
      value: ISSUE_KEY_INPUT_FIELD_VALUE,
      mappingType: 'Value',
    });

    inputFields.push({
      // Space is mandatory in order to differentiate this field from regular Summary field that can be mapped
      name: 'issue.fields.summary<urn:verticeone:vertice::services:schema/integrations/jira/string/v1, string>',
      value: SUMMARY_INPUT_FIELD_VALUE,
      mappingType: 'Value',
    });

    const outputFields = allDataVariables.map((mapperVariable) => {
      const outputField: FieldMapping = {
        name: mapperVariable.to,
        value: mapperVariable.to,
        mappingType: 'JMESPathExpression',
        schema: {
          'x-type': getVariableXType(otherVariables || [], mapperVariable['from']),
          'x-datasource-params': getVariableXDataSourceParams(otherVariables || [], mapperVariable['from']),
        },
      };
      return outputField;
    });

    const newTask = updateTaskIOMapping(task, {
      name: data.name,
      description: data.description,
      ioMappingConfigurations: [
        {
          kind: 'ProcessEngine:TaskIOMapping',
          version: DEFINITION_VERSION,
          mapping: {
            inputFields,
            outputFields,
          },
        },
      ],
    });

    onSave(newTask);
  };

  return (
    <FormProvider {...formHandlers}>
      <EditorDrawerForm onSubmit={formHandlers.handleSubmit(onSubmit)}>
        <EditorDrawerHeader title={task?.task.name ?? ''} />
        <EditorDrawerScrollContainer>
          <TaskProperties onDirty={onDirty} />
          <JiraSyncSetup
            syncWebhookQuery={syncWebhookQuery}
            workflowVariables={workflowVariables}
            setWorkflowVariables={setWorkflowVariables}
            hasReferencedIssueType={hasReferencedIssueType}
            isLoadingIntegration={isLoadingIntegration}
            integrationWrapperProps={integrationWrapperProps}
            variableMapperProps={variableMapperProps}
            completeCriteriaProps={completeCriteriaProps}
            taskId={taskId}
            task={task}
          />
          {integrationWrapperProps.isActive && <JiraSetupLink />}
        </EditorDrawerScrollContainer>
        <EditorDrawerFooter onDiscard={onClose} disabled={!formHandlers.formState.isValid} />
      </EditorDrawerForm>
    </FormProvider>
  );
};
