import React from 'react';
import { Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Card, Grid, Text, useFormatDateRange } from '@verticeone/design-system';
import LegendItem from '@vertice/core/src/components/charts/components/Legend/LegendItem';
import CompletedRequestsGraph from './CompletedRequestsGraph';
import { CompletedRequestsProvider, useCompletedRequestsContext } from './CompleteRequestsContext';
import dayjs from 'dayjs';
import AnimatedBar from '../../AnimatedBar';
import AnimatedTextNumber from '../../AnimatedTextNumber';
import { generateMonthsInRange, generateRandomData } from '../../../tempUtils';

const CompletedRequestsCard = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD.COMPLETED_REQUESTS' });
  const { range, tooltipData } = useCompletedRequestsContext();
  const { palette } = useTheme();

  // @todo replace with real data
  const months = generateMonthsInRange(range);
  const onTimeData = generateRandomData(months.length, `${range.startDate}-${range.endDate}-ontime`, 10);
  const lateData = generateRandomData(months.length, `${range.startDate}-${range.endDate}-late`, 10);

  const totalLate = lateData.reduce((acc, curr) => acc + curr, 0);
  const totalOnTime = onTimeData.reduce((acc, curr) => acc + curr, 0);

  const data = {
    total: totalLate + totalOnTime,
    totalOnTime,
    totalLate,
    chart: {
      months: generateMonthsInRange(range),
      values: [
        {
          id: 'onTime',
          data: onTimeData,
        },
        {
          id: 'late',
          data: lateData,
        },
      ],
      usedCategories: ['onTime', 'late'],
    },
  };
  const formatDateRange = useFormatDateRange();

  const total = Number(tooltipData?.total || 0) || data.total;
  const onTime = Number(tooltipData?.values.onTime || 0) || data.totalOnTime;
  const late = Number(tooltipData?.values.late || 0) || data.totalLate;
  const startOfMonth = tooltipData?.category ? dayjs(tooltipData.category).startOf('month').format('YYYY-MM-DD') : null;
  const endOfMonth = tooltipData?.category ? dayjs(tooltipData.category).endOf('month').format('YYYY-MM-DD') : null;
  const date =
    (startOfMonth && endOfMonth && formatDateRange(startOfMonth, endOfMonth)) ||
    formatDateRange(range.startDate.format('YYYY-MM-DD'), range.endDate.format('YYYY-MM-DD'));

  return (
    <Card testId="my-tasks-card">
      <Stack p={2} gap={4}>
        <Grid container rowSpacing={2} columnSpacing={8} alignItems="stretch">
          <Grid item sm={4}>
            <Stack p={5} gap={4} justifyContent="space-between" height="100%">
              <Stack gap={1}>
                <Text variant="body-bold" size="L" color="text1">
                  {t('TITLE')}
                </Text>
                <Text variant="body-regular" color="text3" size="XS">
                  {date}
                </Text>
              </Stack>
              <Stack gap={7}>
                <Stack gap={1}>
                  <Stack overflow="hidden">
                    <AnimatedTextNumber variant="heading" size="XL" color="text1">
                      {total}
                    </AnimatedTextNumber>
                  </Stack>
                  <Text variant="body-regular" size="S" color="text1">
                    {t('TOTAL_COMPLETED_REQUESTS')}
                  </Text>
                </Stack>
                <Stack direction="row" sx={{ gap: '2px' }} alignItems="center">
                  <AnimatedBar width={(onTime / total) * 100} color={palette.visualization.monochromatic.success[70]} />
                  <AnimatedBar width={(late / total) * 100} color={palette.visualization.monochromatic.success[40]} />
                </Stack>
              </Stack>
              <Stack gap={2} direction="row">
                <Stack gap={1} flex={1}>
                  <AnimatedTextNumber variant="heading" size="M" color="text1">
                    {onTime}
                  </AnimatedTextNumber>
                  <LegendItem
                    label={t('LEGEND.COMPLETED_ON_TIME')}
                    id="completed-on-time"
                    color={palette.visualization.monochromatic.success[70]}
                  />
                </Stack>
                <Stack gap={1} flex={1}>
                  <AnimatedTextNumber variant="heading" size="M" color="text1">
                    {late}
                  </AnimatedTextNumber>
                  <LegendItem
                    label={t('LEGEND.COMPLETED_LATE')}
                    id="completed-late"
                    color={palette.visualization.monochromatic.success[40]}
                  />
                </Stack>
              </Stack>
            </Stack>
          </Grid>
          <Grid item sm={8}>
            <CompletedRequestsGraph data={data.chart} />
          </Grid>
        </Grid>
      </Stack>
    </Card>
  );
};

const CompletedRequestsCardWithContext = () => {
  const initialMonths = 6;
  const endDate = dayjs().subtract(1, 'months').startOf('month');
  const startDate = endDate.subtract(initialMonths - 1, 'months').startOf('month');

  return (
    <CompletedRequestsProvider
      defaultRange={{
        startDate,
        endDate,
      }}
    >
      <CompletedRequestsCard />
    </CompletedRequestsProvider>
  );
};

export default CompletedRequestsCardWithContext;
