import { Card, CardActions, CardContent, CardHeader } from '@mui/material';
import { FC, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useGetAccountIntegrationQuery } from '@vertice/slices';
import { Button } from '@verticeone/design-system';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { IntegrationSetupDialog } from 'pages/Preferences/Integrations/components/IntegrationSetupDialog';
import styles from '../../../common.module.scss';
import { IntegrationsCardContent } from '../../../components/IntegrationsCardContent';
import { StatusBadge, StatusBadgeVariant } from '../../../components/StatusBadge';
import { providers } from '../../utils/providers';
import { useRevoke } from './hooks';

type AccountingSetupCardProps = {
  id: keyof typeof providers;
};

export const AccountingSetupCard: FC<AccountingSetupCardProps> = ({ id }) => {
  const { t } = useTranslation();
  const { navigate } = useRouteNavigate();
  const routes = useRoutes();

  const {
    providerCode,
    translationId,
    icon: { small: Icon },
  } = providers[id];
  const providerName = t(`PREFERENCES.INTEGRATIONS.ACCOUNTING.${translationId}.TITLE`);
  const providerDescription = t(`PREFERENCES.INTEGRATIONS.ACCOUNTING.${translationId}.DESCRIPTION`);
  const { accountId } = useAccountContext();
  const { data: integration } = useGetAccountIntegrationQuery({ accountId: accountId, integrationId: providerCode });
  const onRevokeCompleted = () => setOpenRevokeDialog(false);
  const { isRevoking, revoke } = useRevoke({ providerCode, onRevokeCompleted });
  const [openRevokeDialog, setOpenRevokeDialog] = useState(false);
  const integrationStatus = useMemo(
    () => ({
      isActive: integration?.status === 'ACTIVE',
      isFailed: integration?.connection?.status === 'FAILED',
    }),
    [integration]
  );

  const badgeStatus = useMemo(() => {
    if (integrationStatus.isActive) {
      return StatusBadgeVariant.ACTIVE;
    }
    if (integrationStatus.isFailed) {
      return StatusBadgeVariant.ERROR;
    }
    return StatusBadgeVariant.NEW;
  }, [integrationStatus]);

  return (
    <div className={styles.card}>
      <Card variant="outlined">
        <CardHeader avatar={<Icon />} />
        <CardContent classes={{ root: styles['card-content'] }}>
          <IntegrationsCardContent
            title={providerName}
            description={providerDescription}
            statusBadge={badgeStatus && <StatusBadge variant={badgeStatus} />}
          />
        </CardContent>
        <CardActions className={styles['card-actions']}>
          {!integrationStatus.isActive && !integrationStatus.isFailed && (
            <Button
              onClick={() => navigate(routes.PREFERENCES.INTEGRATIONS.ACCOUNTING, { providerId: id })}
              variant="outline"
              color="neutral"
              size="S"
            >
              {t('PREFERENCES.INTEGRATIONS.CONNECT')}
            </Button>
          )}
          {(integrationStatus.isActive || integrationStatus.isFailed) && (
            <Button
              isLoading={isRevoking}
              onClick={() => setOpenRevokeDialog(true)}
              variant="ghost"
              color="neutral"
              size="S"
            >
              {t('PREFERENCES.INTEGRATIONS.REVOKE_ACCESS')}
            </Button>
          )}
        </CardActions>
      </Card>
      <IntegrationSetupDialog
        header={t('PREFERENCES.INTEGRATIONS.REVOKE_DIALOG.TITLE', { providerName })}
        content={t('PREFERENCES.INTEGRATIONS.REVOKE_DIALOG.DESCRIPTION', { providerName })}
        buttonLabel={t('PREFERENCES.INTEGRATIONS.REVOKE_DIALOG.REVOKE')}
        isOpened={openRevokeDialog}
        onSubmit={() => revoke()}
        onClose={() => setOpenRevokeDialog(false)}
      />
    </div>
  );
};
