import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';

import { Card, CardHeader, CardHeaderDescription, Text } from '@verticeone/design-system';
import type { AuthType } from '../AuthType';
import GenericForm from './components/Form/Form';

export const CreateIntegration = () => {
  const { authType } = useParams<{ authType: AuthType }>();

  return (
    <Card maxWidth={1280} width="100%" marginX="auto">
      <CardHeader>
        <CardHeaderDescription>
          <Text variant="heading" size="S" color="text1">
            {'Set up Custom Outbound Integration'}
          </Text>
        </CardHeaderDescription>
      </CardHeader>
      <Stack gap={4} p={6}>
        <GenericForm authType={authType} onSubmit={() => {}} />
      </Stack>
    </Card>
  );
};
