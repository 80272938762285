import { FC, useMemo, useState } from 'react';

import {
  useGetAccountIntegrationQuery,
  useListCreateJiraIssueTypeFieldsQuery,
  useListJiraIssueTypesQuery,
} from '@vertice/slices';
import { useAccountContext } from '../../../../../../../contexts/AccountContext';
import { ProviderIds } from '../../../../../../applications/utils';
import { useServiceCatalogResources } from '../../../../WorkflowViewer/useServiceCatalogResources';
import { useVariablesAvailableInNode } from '../../../hooks/useVariablesAvailableInNode';
import { JiraIssueType, JiraProject } from '../JiraCommon/types';
import { getCorrespondingTaskService, transformJiraToWorkflowLikeVariables } from '../JiraCommon/utils';
import { EditServiceTaskBase } from '../types';
import { JiraCreateForm } from './JiraCreateForm';
import { REQUIRED_JIRA_CREATE_FIELDS } from '../utils';
import { getRowsCount } from '../../../VariableMapper/utils';
import { getJiraCreateInputMapping } from './utils';

/**
 * Get and prepare data for Jira create form like available workflow variables and Jira variables
 */
export const JiraCreate: FC<EditServiceTaskBase> = (props) => {
  const { task, processDefinition, workflowServiceRef, editorConfig, resources, ...restProps } = props;
  const { accountId } = useAccountContext();
  const taskService = getCorrespondingTaskService(task, resources);
  const serviceCatalogResources = useServiceCatalogResources();
  const { xTypeServiceCatalogResources } = serviceCatalogResources;

  const { data: integrationData, isLoading: isLoadingIntegration } = useGetAccountIntegrationQuery({
    accountId: accountId,
    integrationId: ProviderIds.JIRA,
  });
  const selectedProject = integrationData?.parameters?.projects?.[0] as JiraProject | undefined;
  const { data: issueTypesData, isLoading: isLoadingIssueTypes } = useListJiraIssueTypesQuery(
    {
      accountId,
      projectId: selectedProject?.id ?? '',
    },
    { skip: !selectedProject }
  );

  const [selectedIssueType, setSelectedIssueType] = useState<JiraIssueType | undefined>(undefined);

  const { data: issueTypeFieldsData, isFetching: isFetchingIssueTypeFields } = useListCreateJiraIssueTypeFieldsQuery(
    {
      accountId,
      projectId: selectedProject?.id ?? '',
      issueTypeId: selectedIssueType?.id ?? '',
    },
    { skip: !selectedProject?.id || !selectedIssueType?.id }
  );

  const { requestVariables, udfVariables } = useVariablesAvailableInNode({
    nodeId: task.task.id,
    processDefinition,
    workflowServiceRef,
  });

  const otherVariables = useMemo(
    () =>
      issueTypeFieldsData?.fields &&
      transformJiraToWorkflowLikeVariables(issueTypeFieldsData?.fields, xTypeServiceCatalogResources),
    [issueTypeFieldsData?.fields, xTypeServiceCatalogResources]
  );

  const mapperRowsCount = useMemo(() => {
    if (task && taskService) {
      const { variables } = getJiraCreateInputMapping(task, taskService);
      return getRowsCount(variables, REQUIRED_JIRA_CREATE_FIELDS, 'to');
    }
  }, [task, taskService]);

  if (!taskService) return null;

  return (
    <JiraCreateForm
      task={task}
      taskService={taskService}
      projectId={selectedProject?.id ?? ''}
      {...restProps}
      integrationWrapperProps={{
        isLoading: isLoadingIntegration,
        isActive: integrationData?.status === 'ACTIVE',
      }}
      issueTypePickerProps={{
        issueTypes: issueTypesData?.issueTypes ?? [],
        isLoadingIssueTypes: isLoadingIssueTypes || issueTypesData?.issueTypes === undefined,
        setSelectedIssueType: setSelectedIssueType,
      }}
      variableMapperProps={{
        workflowVariables: {
          udfs: udfVariables,
          request: requestVariables,
        },
        otherVariables,
        isLoading: isFetchingIssueTypeFields,
        mapperRowsCount,
      }}
    />
  );
};
