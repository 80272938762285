import React, { FC, useMemo, useState } from 'react';
import { useServiceWorkflows } from '../ServiceDetailPage/useServiceWorkflows';
import { Resource } from '../types';
import { Stack } from '@mui/material';
import { Button, IconButton, Tooltip } from '@verticeone/design-system';
import { BuildOutlined, VisibilityOutlined } from '@mui/icons-material';
import { WorkflowPreviewDialog } from '../../workflow/WorkflowDetailPage/components/WorkflowPreviewDialog';
import { useTranslation } from 'react-i18next';

type CardActionProps = {
  service: Resource;
  onManageClick: () => void;
};

export const CardActions: FC<CardActionProps> = ({ service, onManageClick }) => {
  const { t } = useTranslation();
  const [previewOpen, setPreviewOpen] = useState(false);
  const defaultWorkflowRef = service?.definition?.ServiceProvider?.HandlerUrn;

  const { workflows, isLoadingWorkflows } = useServiceWorkflows({ serviceRef: service?.urn, defaultWorkflowRef });

  const defaultWorkflow = useMemo(() => workflows.find((w) => w.isDefault), [workflows]);

  return (
    <Stack direction="row" gap={1}>
      <Button
        onClick={onManageClick}
        fullWidth
        variant="ghost"
        color="primary"
        isLoading={isLoadingWorkflows}
        disabled={isLoadingWorkflows}
      >
        {t('ENTITIES.SERVICE.ACTIONS.MANAGE')}
      </Button>
      <Stack direction="row" gap={1}>
        <Tooltip title={t('ENTITIES.WORKFLOW_VERSION.ACTIONS.PREVIEW')} minified size="S">
          <IconButton
            icon={VisibilityOutlined}
            variant="outline"
            disabled={isLoadingWorkflows || !defaultWorkflow}
            onClick={() => setPreviewOpen(true)}
          />
        </Tooltip>
        <Tooltip title={t('ENTITIES.SERVICE.ACTIONS.EDIT_LATEST')} minified size="S">
          <IconButton icon={BuildOutlined} variant="outline" disabled={isLoadingWorkflows} />
        </Tooltip>
      </Stack>
      {defaultWorkflow?.activeVersionId && previewOpen && (
        <WorkflowPreviewDialog
          serviceRef={service.urn}
          workflowId={defaultWorkflow.id}
          versionId={defaultWorkflow.activeVersionId}
          onClose={() => setPreviewOpen(false)}
        />
      )}
    </Stack>
  );
};
