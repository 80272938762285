import { useState } from 'react';

import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useRevokeAccountIntegrationMutation } from '@vertice/slices';
import Wizard from 'pages/Preferences/Integrations/components/Wizard';
import { SLACK_PROVIDER_ID, STEPS } from '../common';
import { ConnectionCheckStep } from './Steps/ConnectionCheckStep';
import { ConnectionErrorStep } from './Steps/ConnectionErrorStep';
import { ConnectionSuccessStep } from './Steps/ConnectionSuccessStep';
import { StartStep } from './Steps/StartStep';

export const SlackSetupWizard = () => {
  const { accountId } = useAccountContext();

  const [step, setStep] = useState(STEPS.START);
  const [connectionWindow, setConnectionWindow] = useState<Window | null>(null);

  const [revokeAccountIntegration] = useRevokeAccountIntegrationMutation();

  const handleCancellation = async () => {
    if (!accountId || step === STEPS.START) return;
    await revokeAccountIntegration({ accountId, integrationId: SLACK_PROVIDER_ID });
  };

  return (
    <Wizard activeStep={step} onCancel={handleCancellation}>
      <StartStep stepValue={STEPS.START} setStep={setStep} setConnectionWindow={setConnectionWindow} />
      <ConnectionCheckStep stepValue={STEPS.CHECK_CONNECTION} setStep={setStep} connectionWindow={connectionWindow} />
      <ConnectionErrorStep stepValue={STEPS.CONNECTION_ERROR} setStep={setStep} />
      <ConnectionSuccessStep stepValue={STEPS.CONNECTION_SUCCESS} setStep={setStep} />
    </Wizard>
  );
};
