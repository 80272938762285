import { AddExistingContractFormData } from './types';
import { CostModelBase } from '@vertice/core/src/modules/saas/contract/costModels/types';
import {
  AdvancedLinearCostModelLine,
  BillingFrequency,
  ContractClassificationCreate,
  ContractCreate,
  ContractPartContractualFinancial,
  ContractPartContractualLifecycle,
  RollFrequency,
} from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { omitEmptyValues } from '@verticeone/utils/objects';
import { keepOnlyLocalDatePart } from '@verticeone/utils/dates';
import { isNil } from 'lodash';
import { v1 as uuid } from 'uuid';
import { ANNUAL_COST_IDENTIFIER } from '@vertice/core/src/modules/saas/contract/costModels/AdvancedLinearApproximation/utils';
import { formDataProductsToCostModelProductItems } from '../utils/convertors';

const existingFormDataToCostModelConfiguration = (
  formData: AddExistingContractFormData,
  getCostModelBase: () => CostModelBase
) => {
  return {
    baseline: getCostModelBase(),
    negotiated: {
      ...getCostModelBase(),
      model: {
        items: [
          ...(!isNil(formData.totalAnnualCost)
            ? [
                {
                  id: uuid(),
                  lineItemType: 'OVERRIDE',
                  lineItemCategory: 'FORM_OVERRIDE',
                  identifier: ANNUAL_COST_IDENTIFIER,
                  totalPrice: formData.totalAnnualCost,
                } satisfies AdvancedLinearCostModelLine,
              ]
            : []),
          ...formDataProductsToCostModelProductItems(formData),
        ],
      },
    },
  };
};

const getBillingFrequency = (
  billingFrequency?: BillingFrequency | null
): ContractPartContractualFinancial['billingFrequency'] => {
  if (!billingFrequency) {
    return undefined;
  }
  return billingFrequency;
};

const getRollingFrequency = (
  rollingFrequency?: RollFrequency | null
): ContractPartContractualLifecycle['rollFrequency'] => {
  if (!rollingFrequency) {
    return undefined;
  }
  return rollingFrequency;
};

export const formDataToExistingContractCreate = (
  formData: AddExistingContractFormData,
  getCostModelBase: () => CostModelBase
): ContractCreate => {
  return {
    record: {
      contractCategory: undefined, // Deprecated field
      contractOrigin: 'EXISTING',
    },
    parts: {
      model: {
        cost: {
          configuration: existingFormDataToCostModelConfiguration(formData, getCostModelBase),
        },
      },
      contractual: omitEmptyValues({
        vendor: omitEmptyValues({
          vendorType: formData.vendor?.type ?? 'PREDEFINED',
          vendorId: formData.vendor?.id || undefined,
          vendorName: formData.vendor?.name ?? undefined,
          vendorContact: omitEmptyValues({
            name: formData.vendorContactName,
            email: formData.vendorContactEmail,
          }),
        }),
        financial: omitEmptyValues({
          baseCurrency: formData.contractCurrency,
          billingFrequency: getBillingFrequency(formData.billingFrequency),
        }),
        lifecycle: omitEmptyValues({
          startDate: keepOnlyLocalDatePart(formData.startDate) ?? undefined,
          renewalDate: keepOnlyLocalDatePart(formData.renewalDate) ?? undefined,
          autoRenewalDeadline: keepOnlyLocalDatePart(formData.autoRenewalDate) ?? undefined,
          rollFrequency: getRollingFrequency(formData.rollingFrequency),
        }),
        signatory: omitEmptyValues({
          signingDate: keepOnlyLocalDatePart(formData.signDate || formData.startDate) ?? undefined,
          signer: omitEmptyValues({
            name: formData.signatoryName,
            email: formData.signatoryEmail,
          }),
          signingEntity: omitEmptyValues({
            name: formData.signingEntity,
          }),
        }),
      }),
    },
    classification: omitEmptyValues<ContractClassificationCreate>({
      departmentId: formData?.departmentId ?? undefined,
      category: formData.contractCategory ?? 'SAAS',
    }),
  };
};
