import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useSearchParams } from 'react-router-dom';

import { useAccountContext } from '../../../../../../contexts/AccountContext';
import { useActivateWorkflowVersionMutation } from '@vertice/slices/src/openapi/codegen/workflowsV2Api';
import { useInvalidateWorkflowVersions } from './useInvalidateWorkflowVersions';
import { isDeployedVersion } from '../../utils';

type ActivateWorkflowVersionParams = {
  workflowId: string;
  workflowVersion: string;
  versionName: string;
};

export const useActivateWorkflowVersion = (params: ActivateWorkflowVersionParams) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const { enqueueSnackbar } = useSnackbar();
  const [, setSearchParams] = useSearchParams();
  const invalidateWorkflowVersions = useInvalidateWorkflowVersions();

  const [versionBeingActivated, setVersionBeingActivated] = useState<string | undefined>(undefined);

  const [activateWorkflowVersionMutation] = useActivateWorkflowVersionMutation();
  const activateWorkflowVersion = useCallback(async (): Promise<void> => {
    setVersionBeingActivated(params.workflowVersion);
    const activationResult = await activateWorkflowVersionMutation({
      accountId,
      workflowId: params.workflowId,
      workflowVersion: params.workflowVersion,
    });
    if ('error' in activationResult) {
      enqueueSnackbar(
        t(
          isDeployedVersion(params)
            ? 'INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.MESSAGES.DEPLOYED_VERSION_ACTIVATION_FAILED'
            : 'INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.MESSAGES.DRAFT_VERSION_ACTIVATION_FAILED',
          { versionName: params.versionName }
        ),
        {
          variant: 'error',
        }
      );
    } else {
      setSearchParams({ tab: 'deployed' });
      invalidateWorkflowVersions();
      enqueueSnackbar(
        t(
          isDeployedVersion(params)
            ? 'INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.MESSAGES.DEPLOYED_VERSION_ACTIVATED'
            : 'INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.MESSAGES.DRAFT_VERSION_ACTIVATED',

          { versionName: params.versionName }
        ),
        {
          variant: 'success',
        }
      );
    }

    setVersionBeingActivated(undefined);
  }, [
    accountId,
    activateWorkflowVersionMutation,
    enqueueSnackbar,
    invalidateWorkflowVersions,
    params,
    setSearchParams,
    t,
  ]);

  return {
    activateWorkflowVersion,
    versionBeingActivated,
  };
};
