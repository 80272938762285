import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import FormSection from '@vertice/core/src/modules/forms/FormSection';
import { FormToggleButtonField } from '@vertice/core/src/modules/forms/fields/FormToggleButtonField';
import { TaskTwoTone, RequestQuoteTwoTone } from '@mui/icons-material';
import { useWizard, WizardStepProps } from '@vertice/core/src/components/Wizard';
import { Header, InnerScrollable, Content } from '@vertice/core/src/components/Dialog/Components';
import SelectedVendorPanel from '../../../SharedComponents/SelectedVendorPanel';
import { AddExistingContractFormEntry } from '../../types';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useFormContext } from 'react-hook-form';
import { SharedRenewalFormData } from '../../../SharedRenewalSteps/types';
import { useCreateRenewalRequestFlow } from '@vertice/core/src/modules/intelligentWorkflows/hooks/useCreateRenewalRequestFlow';
import { CostModelProductItem } from '@vertice/core/src/modules/saas/contract/components/CostModelProductList';
import { getProductsWithoutCosts } from '../../../utils/convertors';
import { RenewalProduct } from '@vertice/core/src/modules/intelligentWorkflows/hooks/useCreateRenewalRequest';

const useChooseInitiateRenewalWizard = () => {
  const wizard = useWizard();

  return {
    goToConfirmation: () => wizard.goToStep('confirmation'),
    goToConfirmRenewalRequirements: () => wizard.goToStep('confirm_renewal_requirements'),
  };
};

const formCostModelProudctItemsToRenewalProducts = (products: CostModelProductItem[]): RenewalProduct[] => {
  return (
    products.map(
      (product) =>
        ({
          id: product.productId ?? undefined,
          name: product.name ?? null ?? undefined,
          productId: product.productId ?? undefined,
          totalCost: product.totalCost ?? undefined,
          licenseCost: product.licenseCost ?? undefined,
          numberOfLicences: product.numberOfLicences ?? undefined,
          licenseType: product.licenseType ?? undefined,
          allocationSpan: product.allocationSpan ?? undefined,
        } ?? undefined)
    ) ?? []
  );
};

const ChooseInitiateRenewal: React.FC<WizardStepProps> = () => {
  const { t } = useTranslation();
  const { goToConfirmation, goToConfirmRenewalRequirements } = useChooseInitiateRenewalWizard();
  const { watch, setValue } = useFormContext<SharedRenewalFormData>();
  const contractId = watch('existingContractId');
  const vendor = watch('vendor');
  const products = watch('products');
  const department = watch('departmentId');
  const { isFeatureEnabled } = useAccountContext();
  const { createRenewalRequestFlow, isCreatingRenewalRequest } = useCreateRenewalRequestFlow();

  const initiateRenewalRequestForContract = useCallback(
    () =>
      createRenewalRequestFlow({
        vendor: vendor!,
        contractId: contractId!,
        products: formCostModelProudctItemsToRenewalProducts(products),
        department: department ?? undefined,
      }),
    [contractId, createRenewalRequestFlow, products, vendor, department]
  );

  const onInitiateRenewalChosen = useCallback(
    async (value: string) => {
      if (value === 'no') {
        goToConfirmation();
      } else if (value === 'yes') {
        const isIWEnabled = isFeatureEnabled(FEATURES.INTELLIGENT_WORKFLOWS);
        if (isIWEnabled) {
          await initiateRenewalRequestForContract();
        } else {
          // clear costs from products as it was just for the Add Existing part, but it's not wanted in the Renewal part
          const productsWithoutCosts = getProductsWithoutCosts(products);
          setValue('products', productsWithoutCosts);
          goToConfirmRenewalRequirements();
        }
      }
    },
    [
      goToConfirmation,
      isFeatureEnabled,
      initiateRenewalRequestForContract,
      goToConfirmRenewalRequirements,
      products,
      setValue,
    ]
  );

  return (
    <>
      <Content>
        <Header
          title={t('INTAKE_FORM.EXISTING_CONTRACT_CHOOSE_INITIATE_RENEWAL.HEADING_TITLE')}
          subtitle={t('INTAKE_FORM.EXISTING_CONTRACT_CHOOSE_INITIATE_RENEWAL.HEADING_SUBTITLE')}
        />
        <SelectedVendorPanel />
        <InnerScrollable>
          <FormSection>
            <AddExistingContractFormEntry
              name="initiateRenewal"
              width={12}
              component={FormToggleButtonField}
              disabled={isCreatingRenewalRequest}
              componentProps={{
                onClick: onInitiateRenewalChosen,
                buttonHeight: 234,
                options: [
                  {
                    title: t('INTAKE_FORM.EXISTING_CONTRACT_CHOOSE_INITIATE_RENEWAL.LABELS.UPLOAD_ONLY'),
                    value: 'no',
                    icon: TaskTwoTone,
                  },
                  {
                    title: t('INTAKE_FORM.EXISTING_CONTRACT_CHOOSE_INITIATE_RENEWAL.LABELS.INITIATE_RENEWAL'),
                    value: 'yes',
                    isLoading: isCreatingRenewalRequest,
                    icon: RequestQuoteTwoTone,
                  },
                ],
              }}
            />
          </FormSection>
        </InnerScrollable>
      </Content>
    </>
  );
};

export default ChooseInitiateRenewal;
