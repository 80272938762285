import React, { ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Stack, styled, useTheme } from '@mui/material';
import { GRID_DETAIL_PANEL_TOGGLE_COL_DEF, gridClasses, GridColDef, GridSortItem } from '@mui/x-data-grid-pro';
import ChevronRightIcon from '@mui/icons-material/ArrowForwardIos';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import {
  IconWrapper,
  Button,
  Text,
  DataGrid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  LoadableComponent,
  ChipButton,
  Loader,
} from '@verticeone/design-system';
import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';
import { alphabetComparator, arrayComparator, effortComparator, numberComparator, codeComparator } from './comparators';
import useObfuscatedOptimizationData, { OptimizationRow } from '../../dataSource/useOptimizations';
import useDataVisibility from '../../dataSource/useDataVisibility';
import { useValueFormatter } from './utils';
import ObfuscatedColumn from './columns/ObfuscatedColumn';
import CategoryColumn from './columns/CategoryColumn';
import EffortColumn from './columns/EffortColumn';
import SavingColumn from './columns/SavingColumn';
import StatusColumn from './columns/StatusColumn';
import ExpandIcon from './Components/ExpandIcon';
import ResultTooltip from './Components/ResultTooltip';
import useOptimizationSearchParamsStateStore from './useOptimizationSearchParamsStateStore';
import useTestResult from '../../dataSource/useTestResult';

const SpacerCell = styled(TableCell)(({ theme: { palette } }) => ({
  width: 43,
  '&&&': {
    borderRightColor: palette.core.color2,
  },
}));

const STANDARD_COLUMN_WIDTH = 150;

const CellCaption = (props: { children: ReactNode }) => {
  const { palette } = useTheme();
  return <Text variant="caption" size="S" tag="div" color={palette.text.color3} {...props} />;
};

type TestDetailPanelProps = {
  row: OptimizationRow;
};

const TestDetailPanel = ({ row }: TestDetailPanelProps) => {
  const { generatePathForRoute } = useRouteNavigate();
  const routes = useRoutes();
  const { palette } = useTheme();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.OPTIMIZATION_TESTS' });
  const formatTestResult = useValueFormatter();
  const { data: testResult, isFetching: isTestResultFetching } = useTestResult({ code: row.tests[0].code });

  const value = useMemo(() => {
    if (testResult?.value && testResult?.type) {
      return formatTestResult(testResult.value, testResult.type);
    }
    return t('RESULTS.NA');
  }, [testResult, t, formatTestResult]);

  return (
    <TableContainer
      sx={{
        backgroundColor: palette.input.color1,
        borderTop: 'none',
        borderLeft: 'none',
        borderRight: 'none',
        borderRadius: 0,
      }}
    >
      <Table size="M" color="neutral">
        <TableBody>
          <TableRow>
            <SpacerCell />
            <TableCell sx={{ padding: '16px 12px', height: 'auto' }} colSpan={2}>
              <CellCaption>{t('COLUMNS.DESCRIPTION')}</CellCaption>
              <Text variant="body-regular" size="M" tag="div" whiteSpace="pre-line">
                {row.description}
              </Text>
            </TableCell>
            <TableCell sx={{ textAlign: 'right', width: `${STANDARD_COLUMN_WIDTH}px` }}>
              <Stack spacing={1} alignItems="end">
                <CellCaption>{t('COLUMNS.TEST_RESULT')}</CellCaption>
                <ResultTooltip tooltip={testResult?.tooltip}>
                  <ChipButton color={row.breaching ? AWS_BRAND_COLOR : 'neutral'} isActive={false} variant="ghost">
                    {isTestResultFetching && (
                      <Stack position="absolute" left="0" right="0" top="0" bottom="0">
                        <Loader color={AWS_BRAND_COLOR} thickness={7} size={14} />
                      </Stack>
                    )}
                    <Stack
                      width={isTestResultFetching ? '50px' : 'auto'}
                      visibility={isTestResultFetching ? 'hidden' : 'visible'}
                    >
                      {value}
                    </Stack>
                  </ChipButton>
                </ResultTooltip>
              </Stack>
            </TableCell>

            <TableCell
              sx={{ padding: '16px', height: 'auto', width: `${STANDARD_COLUMN_WIDTH}px`, textAlign: 'right' }}
            >
              <Button
                component={Link}
                to={generatePathForRoute(routes.CLOUD.OPTIMIZATION.RECOMMENDATION.CODE, { code: row.code })}
                variant="plain"
                color="tertiary"
                sx={{ '&,&:hover': { padding: 0 } }}
              >
                {t('COLUMNS.VIEW')}
                <IconWrapper icon={ChevronRightIcon}></IconWrapper>
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const OptimizationTestsTable = () => {
  const { data, isFetching } = useObfuscatedOptimizationData();
  const { state, toggleItem, onStateChange } = useOptimizationSearchParamsStateStore();
  const { canViewFullVersion } = useDataVisibility();
  const { t } = useTranslation(undefined, { keyPrefix: 'CLOUD.OPTIMIZATION_TESTS.COLUMNS' });

  const columns: GridColDef<OptimizationRow>[] = useMemo(
    () => [
      {
        field: 'isObfuscated',
        headerName: 'isObfuscated',
      },
      {
        ...GRID_DETAIL_PANEL_TOGGLE_COL_DEF,
        minWidth: 43,
        renderCell: ExpandIcon,
      },
      {
        field: 'code',
        headerName: t('CODE'),
        editable: false,
        renderCell: ObfuscatedColumn,
        sortComparator: codeComparator,
      },
      {
        field: 'name',
        headerName: t('NAME'),
        renderCell: ObfuscatedColumn,
        sortComparator: alphabetComparator,
        flex: 1,
        minWidth: 300,
      },
      {
        field: 'categories',
        headerName: t('CATEGORY'),
        renderCell: CategoryColumn,
        sortComparator: arrayComparator,
        flex: 1,
      },
      {
        field: 'status',
        headerName: t('STATUS'),
        renderCell: StatusColumn,
        sortComparator: alphabetComparator,
        width: 200,
      },
      {
        field: 'effort',
        headerName: t('EFFORT'),
        renderCell: EffortColumn,
        sortComparator: effortComparator,
        width: STANDARD_COLUMN_WIDTH,
      },
      {
        field: 'saving',
        headerName: t('RECOMMENDATIONS_VALUE'),
        align: 'right',
        headerAlign: 'right',
        renderCell: SavingColumn,
        sortComparator: numberComparator,
        width: STANDARD_COLUMN_WIDTH,
      },
    ],
    [t]
  );

  return (
    <LoadableComponent isLoading={isFetching} color={AWS_BRAND_COLOR}>
      <DataGrid<OptimizationRow>
        sx={{
          border: 'none',
          borderRadius: 0,
          marginRight: '-1px',
          [`.${gridClasses.row}`]: {
            '&:hover': {
              cursor: 'pointer',
            },
          },
          [`[data-field="${GRID_DETAIL_PANEL_TOGGLE_COL_DEF.field}"]`]: {
            padding: 0,
          },
        }}
        rows={data?.recs ?? []}
        columns={columns}
        getRowId={({ code }) => code}
        sortingMode="client"
        hideFooter
        noBorderRadius
        rowHeight={60}
        showCellVerticalBorder
        showColumnVerticalBorder
        autoHeight
        detailPanelExpandedRowIds={state.expanded.filter(
          (recCode) => data?.recs?.find((rec) => rec.code === recCode)?.isVisible
        )}
        onRowClick={({ row }) => {
          if (row.isVisible) {
            toggleItem(row.code);
          }
        }}
        onDetailPanelExpandedRowIdsChange={(codes) => onStateChange({ expandedItems: codes as string[] })}
        getDetailPanelContent={({ row }) => {
          if (row.isVisible) {
            return <TestDetailPanel row={row} />;
          }
        }}
        getDetailPanelHeight={() => 'auto'} // Height based on the content.
        disableRowSelectionOnClick
        initialState={{
          sorting: {
            sortModel: [
              { field: 'isObfuscated', sort: 'asc' },
              { field: 'effort', sort: 'asc' },
              { field: 'saving', sort: 'desc' },
            ].filter((model) => (canViewFullVersion ? model.field !== 'isObfuscated' : true)) as Array<GridSortItem>,
          },
          columns: {
            columnVisibilityModel: {
              isObfuscated: false, // hide column
            },
          },
        }}
        color={AWS_BRAND_COLOR}
      />
    </LoadableComponent>
  );
};

export default OptimizationTestsTable;
