import React, { FC, useCallback, useState } from 'react';
import { Box, Stack } from '@mui/material';

import { IntelligentWorkflowsLayout } from '../../components/IntelligentWorkflowsLayout';
import { ServiceDrawer } from '../components/ServiceDrawer/ServiceDrawer';
import { useServiceCardsV2 } from '../hooks/useServiceCardsV2';
import { CardActions } from './CardActions';
import type { Resource } from '../types';

export const ServicesPageV2: FC = () => {
  const [selectedService, setSelectedService] = useState<Resource>();

  const getCardActions = useCallback(
    (service: Resource) => <CardActions service={service} onManageClick={() => setSelectedService(service)} />,
    []
  );

  const serviceCards = useServiceCardsV2({
    getCardActions,
  });

  return (
    <IntelligentWorkflowsLayout withDivider>
      <Stack gap={4} paddingBlock={8}>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
            gap: 4,
          }}
        >
          {serviceCards}
        </Box>
      </Stack>
      <ServiceDrawer
        isOpen={!!selectedService}
        onClose={() => setSelectedService(undefined)}
        service={selectedService}
      />
    </IntelligentWorkflowsLayout>
  );
};
