import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Text } from '@verticeone/design-system';
import { useLocaleContext } from '@vertice/core/src/contexts/LocaleContext';
import { useActiveRequestsData } from './useActiveRequestsData';
import ListItem from '../../ListItem';
import { ItemType } from './types';
import ListCard from '../../ListCard';

const ActiveRequestsCard = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD.ACTIVE_REQUESTS' });
  const { locale } = useLocaleContext();
  const { data } = useActiveRequestsData();
  const formatNumber = (value: number) =>
    new Intl.NumberFormat(locale, {
      maximumFractionDigits: 0,
      maximumSignificantDigits: 2,
      currency: 'USD',
      style: 'currency',
      notation: 'compact',
    }).format(value);

  return (
    <ListCard
      testId="active-requests-card"
      title={t('TITLE')}
      description={
        <Trans
          i18nKey="INTELLIGENT_WORKFLOWS.DASHBOARD.ACTIVE_REQUESTS.DESCRIPTION"
          components={{
            highlight: <Text variant="body-regular" size="XS" color="error1" />,
          }}
          values={{
            spend: formatNumber(data.spend),
          }}
        />
      }
      showAllText={t('SHOW_ALL')}
      onShowAllClick={() => {}}
    >
      {data.items.map((item: ItemType) => (
        <ListItem key={item.id} {...item} />
      ))}
    </ListCard>
  );
};

export default ActiveRequestsCard;
