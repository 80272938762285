import { FC, useState } from 'react';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { Stack } from '@mui/material';
import { Divider, Tooltip } from '@verticeone/design-system';
import { Text } from '@verticeone/design-system';
import { Button } from '@verticeone/design-system';
import { useRenewOutsideVertice } from './hooks/useRenewOutsideVertice';
import { useTranslation } from 'react-i18next';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { CreateNonSaasRequestDialog } from './CreateNonSassRequestDialog/CreateNonSaasRequestDialog';

type ContractRenewActionNonSaasProps = { contract: Contract };

/**
 * Actions to renew Non Saas contract.
 *
 * Based on the provided `contract` prop this component allow user to start new generic purchase
 * workflow in dialog, or generate Draft version of the current contract. When the IW NonSass is
 * behind paywall, it directs user to get in touch with our Account Manager.
 */
export const ContractRenewActionNonSaas: FC<ContractRenewActionNonSaasProps> = (props) => {
  const { contract } = props;
  const { t } = useTranslation();

  const [isNonSassIWDialogOpened, setIsNonSassIWDialogOpened] = useState<boolean>(false);

  const { accountId, getFeature } = useAccountContext();
  const iwPaywall = getFeature(FEATURES.INTELLIGENT_WORKFLOWS_PAYWALL);
  const isNonSassIWEnabled = iwPaywall?.properties?.nonSaasRequests;

  const { handleRenewOutsideVertice, isLoading } = useRenewOutsideVertice({ contract, accountId });

  const handleOpenDialog = () => {
    setIsNonSassIWDialogOpened(true);
  };

  return (
    <>
      <Stack gap={4} width="100%">
        {isNonSassIWEnabled ? (
          <Button fullWidth variant="solid" color="primary" isCaption size="S" onClick={handleOpenDialog}>
            {t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.START_A_NEW_REQUEST')}
          </Button>
        ) : (
          <Tooltip content={t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.DISABLED_IW_NONSASS_TOOLTIP')}>
            <div>
              <Button fullWidth variant="solid" color="primary" isCaption size="S" disabled>
                {t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.START_A_NEW_REQUEST')}
              </Button>
            </div>
          </Tooltip>
        )}
        <Divider>
          <Text variant="label" color="text3" size="XXS">
            {t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.OR')}
          </Text>
        </Divider>
        <Stack direction="column" alignItems="center" gap={1}>
          <Text variant="body-regular" size="S">
            {t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.ALREADY_FULFILLED')}
          </Text>
          <Button
            fullWidth
            variant="plain"
            color="primary"
            onClick={handleRenewOutsideVertice}
            disabled={isLoading}
            isLoading={isLoading}
          >
            {t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.ADD_RENEWED_CONTRACT')}
          </Button>
        </Stack>
      </Stack>
      {isNonSassIWDialogOpened && (
        <CreateNonSaasRequestDialog open setOpen={setIsNonSassIWDialogOpened} contract={contract} />
      )}
    </>
  );
};
