import { sortBy } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { addDays, format, startOfDay, subDays } from 'date-fns';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { graphql } from '@vertice/slices/src/graphql/cloudOptimization/generated/gql';
import {
  type MonitoringValueCheckResult,
  type TableType,
} from '@vertice/slices/src/graphql/cloudOptimization/generated/cloudOptimizationGraphQL';
import { useCloudClient } from '@vertice/dashboard/src/pages/Cloud/CloudClientProvider';
import { DATE_FORMAT, getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';
import { generatePreviousDaysData } from '../../../components/utils';
import { useGraph } from '../../../components/providers/GraphProvider';

export type SummaryDataColumns = 'resource_cost_per_hour' | 'to_cover_hourly_recommendation';
export type DailyDataColumns = {
  timestamp: string;
  cost_ewma: number;
  ec2_covered_cost: number;
  ec2_on_demand_cost: number;
  lambda_sp_covered_cost: number;
  lambda_on_demand_cost: number;
  fargate_sp_covered_cost: number;
  fargate_on_demand_cost: number;
  uncoverable_cost: number;
};

const FUTURE_PERIOD = 5;
const COMPUTED_PERIOD = 45;

const ComputeSPRIODailyQuery = graphql(`
  query ComputeSPRIODaily($accountId: String!) {
    summaryQuery: monitoringLatestQuery(
      params: { accountId: $accountId, monitoringType: CHECK, code: "COMPUTE_SP_COVERAGE_LAST_DAYS" }
    ) {
      __typename
      ... on MonitoringResult {
        items {
          code
          results {
            __typename
            ... on MonitoringValueCheckResult {
              columns
              data
              dataTypes
            }
          }
        }
      }
      ... on ErroredQueryResult {
        error
      }
    }
    dailyUsageQuery: athenaViewQuery(
      params: { accountId: $accountId, name: "cco_view_sprio_compute_daily_usage_v1", parameters: ["{accountId}"] }
    ) {
      __typename
      ... on DataTableResult {
        table(
          columns: [
            "timestamp"
            "ec2_covered_cost"
            "ec2_on_demand_cost"
            "lambda_sp_covered_cost"
            "lambda_on_demand_cost"
            "fargate_sp_covered_cost"
            "fargate_on_demand_cost"
            "uncoverable_cost"
          ]
        ) {
          columns
          data
          dataTypes
        }
      }
      ... on ErroredQueryResult {
        error
      }
      ... on DeferredQueryResult {
        __typename
      }
    }
  }
`);

type Series = {
  date: string;
  costEWMA: number | null;
  costOnDemandEWMA: number | null;
  spRiPurchaseRecommendation: number | null;
  spOnDemandPurchaseRecommendation: number | null;
  fargateSpCoveredCost: number | null;
  fargateOnDemandCost: number | null;
  lambdaSpCoveredCost: number | null;
  lambdaOnDemandCost: number | null;
  ec2SpCoveredCost: number | null;
  ec2OnDemandCost: number | null;
  shoppingCartUnits: number | null;
  uncoverableCost: number | null;
};

export const useGraphData = () => {
  const { accountId } = useAccountContext();
  const { fetchCloudOptimization } = useCloudClient();
  const { shoppingCartUnits } = useGraph();

  return useQuery({
    queryKey: ['ComputeSPRIODaily'],
    queryFn: () => fetchCloudOptimization(ComputeSPRIODailyQuery, { accountId }),
    enabled: !!accountId,
    refetchInterval: ({ state: { data } }) => {
      return [data?.dailyUsageQuery?.__typename, data?.summaryQuery?.__typename].includes('DeferredQueryResult')
        ? 2000
        : false;
    },
    select: ({ summaryQuery, dailyUsageQuery }) => {
      if (summaryQuery?.__typename === 'MonitoringResult' && dailyUsageQuery?.__typename === 'DataTableResult') {
        const { resource_cost_per_hour, to_cover_hourly_recommendation } = getTableData(
          summaryQuery?.items?.[0]?.results as MonitoringValueCheckResult
        )?.[0] as Record<SummaryDataColumns, number>;
        const daily = getTableData(dailyUsageQuery.table as TableType) as Array<DailyDataColumns>;
        const resourceCostPerDay = resource_cost_per_hour * 24;
        const onDemandCostPerDay = to_cover_hourly_recommendation * 24;

        const computedDays: Series[] = daily?.map(
          ({
            timestamp,
            ec2_covered_cost,
            ec2_on_demand_cost,
            lambda_sp_covered_cost,
            lambda_on_demand_cost,
            fargate_sp_covered_cost,
            fargate_on_demand_cost,
            uncoverable_cost,
          }) => ({
            date: format(new Date(timestamp), DATE_FORMAT),
            costEWMA: resourceCostPerDay,
            uncoverableCost: uncoverable_cost || null,
            costOnDemandEWMA: onDemandCostPerDay,
            spRiPurchaseRecommendation: null,
            spOnDemandPurchaseRecommendation: null,
            fargateSpCoveredCost: fargate_sp_covered_cost,
            fargateOnDemandCost: fargate_on_demand_cost,
            lambdaSpCoveredCost: lambda_sp_covered_cost,
            lambdaOnDemandCost: lambda_on_demand_cost,
            ec2SpCoveredCost: ec2_covered_cost,
            ec2OnDemandCost: ec2_on_demand_cost,
            shoppingCartUnits: null,
          })
        );

        const startDate = subDays(startOfDay(new Date()), 2);

        const previousDays: Series[] = generatePreviousDaysData<Series>(computedDays, startDate, COMPUTED_PERIOD, {
          costEWMA: 0,
          uncoverableCost: null,
          costOnDemandEWMA: 0,
          spRiPurchaseRecommendation: null,
          spOnDemandPurchaseRecommendation: null,
          fargateSpCoveredCost: 0,
          fargateOnDemandCost: 0,
          lambdaSpCoveredCost: 0,
          lambdaOnDemandCost: 0,
          ec2SpCoveredCost: 0,
          ec2OnDemandCost: 0,
          shoppingCartUnits: null,
        }).map((day, index, array) => ({
          ...day,
          spRiPurchaseRecommendation: index === array.length - 1 ? resourceCostPerDay : null,
          spOnDemandPurchaseRecommendation: index === array.length - 1 ? onDemandCostPerDay : null,
        }));

        const futureDays: Series[] = Array.from({ length: FUTURE_PERIOD }, (_, i) => ({
          date: format(addDays(startOfDay(new Date(previousDays[previousDays.length - 1].date)), i + 1), DATE_FORMAT),
          costEWMA: null,
          uncoverableCost: null,
          costOnDemandEWMA: null,
          spRiPurchaseRecommendation: resourceCostPerDay,
          spOnDemandPurchaseRecommendation: onDemandCostPerDay,
          fargateSpCoveredCost: null,
          fargateOnDemandCost: null,
          lambdaSpCoveredCost: null,
          lambdaOnDemandCost: null,
          ec2SpCoveredCost: null,
          ec2OnDemandCost: null,
          shoppingCartUnits: shoppingCartUnits ?? null,
        }));

        return sortBy([previousDays, futureDays].flat(), ({ date }) => new Date(date));
      }

      return null;
    },
  });
};
