import React from 'react';
import { useTranslation } from 'react-i18next';
import { useActiveTasksData } from './useActiveTasksData';
import ListCard from '../../ListCard';
import ListItem from '../../ListItem';
import { FactCheckOutlined } from '@mui/icons-material';

const ActiveTasksCard = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD.ACTIVE_TASKS' });
  const { data } = useActiveTasksData();

  return (
    <ListCard testId="active-tasks-card" title={t('TITLE')} showAllText={t('SHOW_ALL')} onShowAllClick={() => {}}>
      {data.items.map(({ id, title, description, date }) => (
        <ListItem key={id} title={title} subtitle={description} date={date} icon={FactCheckOutlined} />
      ))}
    </ListCard>
  );
};

export default ActiveTasksCard;
