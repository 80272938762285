import { ResourceThumbnail } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import SaasRenewalThumbnail from '../predefinedThumbnails/saas-renewal.svg';
import SaasNewPurchaseThumbnail from '../predefinedThumbnails/saas-new-purchase.svg';
import GenericNewPurchaseThumbnail from '../predefinedThumbnails/generic-new-purchase.svg';
import NdaAgreementThumbnail from '../predefinedThumbnails/nda-agreement.svg';
import ItSecurityReviewThumbnail from '../predefinedThumbnails/it-security-review.svg';
import RfxPlaybookThumbnail from '../predefinedThumbnails/rfx-playbook.svg';
import HireExternalContractorThumbnail from '../predefinedThumbnails/hire-external-contractor.svg';
import NegotiationThumbnail from '../predefinedThumbnails/negotiation.svg';
import TriageThumbnail from '../predefinedThumbnails/triage.svg';
import { Stack, styled } from '@mui/material';

type ThumbnailDefinition = ResourceThumbnail | undefined;

const ThumbnailIdImageMap: Record<string, string> = {
  'saas-renewal': SaasRenewalThumbnail,
  'saas-purchase': SaasNewPurchaseThumbnail,
  'generic-purchase': GenericNewPurchaseThumbnail,
  'nda-agreement': NdaAgreementThumbnail,
  'it-security-review': ItSecurityReviewThumbnail,
  'rfx-playbook': RfxPlaybookThumbnail,
  'hire-external-contractor': HireExternalContractorThumbnail,
  negotiation: NegotiationThumbnail,
  triage: TriageThumbnail,
};

export const StyledThumbnail = styled(Stack)<{ $thumbnail: ThumbnailDefinition }>(({ $thumbnail }) => ({
  borderRadius: 8,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  height: '100%',
  ...($thumbnail?.Type === 'PREDEFINED'
    ? {
        backgroundImage: `url(${ThumbnailIdImageMap[$thumbnail.Id]})`,
      }
    : {
        backgroundColor: 'lightgray',
      }),
}));

export type ThumbnailProps = {
  thumbnail: ThumbnailDefinition;
  inactive?: boolean;
  chipText?: string;
};
