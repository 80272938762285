import React from 'react';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import HighChartRounded from 'highcharts-rounded-corners';
import Highcharts from 'highcharts';
import { Tabs } from '@verticeone/design-system';
import { Tab } from '@verticeone/design-system';
import { generatePath, Navigate, useNavigate, useParams } from 'react-router-dom';
import CloudInsightsSpendTab from './tabs/CloudInsightsSpendTab';
import CloudInsightsUsageTab from './tabs/CloudInsightsUsageTab';
import LoadableAWSPageWrapper from '../LoadableAWSPageWrapper';
import { toConstantCase } from '@verticeone/utils/strings';
import { CloudSubFeature } from '../../../modules/cloud/hooks/useCloudFeature';
import PageNotFound from '@vertice/core/src/modules/notFound/PageNotFound';
import { useCloudContext } from '../../../modules/cloud/CloudContext';
import { TabsBottomLineWrapper } from '@verticeone/design-system';
import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';
import { useRoutes } from '@verticeone/router/src';

// Enable rounded corners plugin for all HighCharts on the page
HighChartRounded(Highcharts);

type TabDef = {
  id: string;
  component: React.ComponentType;

  /**
   * If provided, tab is visible only if this function returns true.
   * If not provided, tab is always visible.
   * @param subFeatures Info about what sub-features are enabled/disabled. Can be useful to make the decision.
   */
  getIsTabVisible?: (subFeatures: Record<CloudSubFeature, boolean | string>) => boolean;
};

const TABS: TabDef[] = [
  {
    id: 'spend',
    component: CloudInsightsSpendTab,
  },
  {
    id: 'usage',
    component: CloudInsightsUsageTab,
    getIsTabVisible: ({ activeSpendAudit }) => !activeSpendAudit,
  },
];

const getTabLabelKey = (tabId: string) => `CLOUD.CLOUD_INSIGHTS.${toConstantCase(tabId)}_TAB`;

const Content = () => {
  const { subFeatures } = useCloudContext();
  const { t } = useTranslation();
  const { activeTab: activeTabId } = useParams();
  const navigate = useNavigate();
  const routes = useRoutes();

  const visibleTabs = TABS.filter(({ getIsTabVisible }) => (getIsTabVisible ? getIsTabVisible(subFeatures) : true));
  if (visibleTabs.length === 0) {
    return <PageNotFound />;
  }

  const activeTab = visibleTabs.find(({ id }) => id === activeTabId);
  if (!activeTab) {
    return (
      <Navigate
        replace={true}
        to={generatePath(routes.CLOUD.INSIGHTS.TAB.ABSOLUTE_PATH, { activeTab: visibleTabs[0].id })}
      />
    );
  }

  return (
    <>
      <TabsBottomLineWrapper>
        <Tabs
          variant="outlined"
          value={activeTab.id}
          onChange={(_, value) => navigate(`../${value}`, { relative: 'path' })}
          color={AWS_BRAND_COLOR}
        >
          {visibleTabs.map(({ id }) => (
            <Tab key={id} value={id} label={t(getTabLabelKey(id))} />
          ))}
        </Tabs>
      </TabsBottomLineWrapper>
      <Box position="relative">
        <activeTab.component />
      </Box>
    </>
  );
};

const CloudInsights = () => (
  <LoadableAWSPageWrapper>
    <Content />
  </LoadableAWSPageWrapper>
);

export default CloudInsights;
