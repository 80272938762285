import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Dialog } from '@verticeone/design-system';

import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { CreateNonSaasRequestProgress } from './CreateNonSaasRequestProgress';
import { match } from 'ts-pattern';
import { CreateNonSaasRequestForm, CreateGenericPurchaseForm } from './CreateNonSaasRequestForm';
import { useCreateNewGenericPurchaseRequest } from '@vertice/core/src/modules/intelligentWorkflows/hooks/useCreateNewGenericPurchaseRequest';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';

type CreateRequestDialogProps = {
  contract: Contract;
  open: boolean;
  setOpen: (open: boolean) => void;
};

type DialogState = 'PROGRESS' | 'GENERIC_PURCHASE';

/**
 * This Dialog is copy paste from original CreateReqestDialog and will be in near future
 * replaced with redesigned and refactored version of the dialog from IW. For now, here is
 * only part of the logic to create new NonSass IW.
 */
export const CreateNonSaasRequestDialog: FC<CreateRequestDialogProps> = (props) => {
  const { open, setOpen, contract } = props;
  const { navigate } = useRouteNavigate();
  const routes = useRoutes();

  const [dialogState, setDialogState] = useState<DialogState>('GENERIC_PURCHASE');
  const [purchaseName, setPurchaseName] = useState<string>('');

  const {
    createNewGenericPurchaseRequest,
    createdNewGenericPurchaseRequestId,
    createdNewGenericPurchaseRequestTaskId,
  } = useCreateNewGenericPurchaseRequest();

  const handleSubmit = (values: CreateGenericPurchaseForm) => {
    setPurchaseName(values.name);
    setDialogState('PROGRESS');
    void createNewGenericPurchaseRequest({ title: values.name });
  };

  const handleRedirectAfterCompletion = useCallback(
    (requestId: string, taskId?: string) => {
      const search = taskId ? { taskId } : undefined;
      navigate(routes.INTELLIGENT_WORKFLOWS.REQUESTS.DETAIL, { requestId }, { search });
    },
    [navigate, routes]
  );

  const defaultValues = useMemo<CreateGenericPurchaseForm>(() => {
    const vendorName = contract.parts.contractual?.vendor?.vendorName ?? '';
    return { name: `${vendorName} non saas` };
  }, [contract]);

  useEffect(() => {
    if (createdNewGenericPurchaseRequestId) {
      handleRedirectAfterCompletion(createdNewGenericPurchaseRequestId, createdNewGenericPurchaseRequestTaskId);
    }
  }, [createdNewGenericPurchaseRequestId, createdNewGenericPurchaseRequestTaskId, handleRedirectAfterCompletion]);

  const handleClose = () => {
    if (dialogState === 'PROGRESS') return;
    setOpen(false);
  };

  return (
    <Dialog open={open} setOpen={setOpen} size="M" width={780} onClose={handleClose}>
      {match(dialogState)
        .with('PROGRESS', () => <CreateNonSaasRequestProgress purchaseName={purchaseName} />)
        .with('GENERIC_PURCHASE', () => (
          <CreateNonSaasRequestForm onClose={handleClose} onSubmit={handleSubmit} defaultValues={defaultValues} />
        ))
        .exhaustive()}
    </Dialog>
  );
};
