import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useNotificationWebSocketSubscription } from '../../../../contexts/NotificationWebSocketContext';
import { isTaskRef } from '../../../../hooks/workflows/refUtils';
import { useCountTasksQuery } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';

type UseTasksCountProps = {
  allTasks?: boolean;
  status?: string;
};

export const useTasksCount = ({ allTasks, status }: UseTasksCountProps = {}) => {
  const { accountId } = useAccountContext();
  const {
    data: tasksCount,
    isFetching: isLoadingTasksData,
    refetch,
  } = useCountTasksQuery({ accountId, includeAll: allTasks, taskStatus: status });

  useNotificationWebSocketSubscription({
    filter: isTaskRef,
    callback: () => {
      void refetch();
    },
  });

  return {
    data: tasksCount?.count,
    isLoading: isLoadingTasksData,
    refetch,
  };
};
