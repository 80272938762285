import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Link } from 'react-router-dom';

import { DataGridColDef } from '@verticeone/design-system';
import { ChipButton } from '@verticeone/design-system';
import { Text } from '@verticeone/design-system';
import { Button } from '@verticeone/design-system';
import { Tooltip } from '@verticeone/design-system';
import { IconWrapper } from '@verticeone/design-system';

import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../constants';
import { WorkflowRow } from './types';
import { createServiceIdentifier } from '../../../../hooks/workflows/refUtils';
import { InfoOutlined } from '@mui/icons-material';

export const useWorkflowsTableColumns = (serviceRef: string): DataGridColDef<WorkflowRow>[] => {
  const { t } = useTranslation();
  const { generatePathForRoute } = useRouteNavigate();
  const routes = useRoutes();

  return useMemo(() => {
    const columns: DataGridColDef<WorkflowRow>[] = [
      {
        field: 'workflowName',
        headerName: t('INTELLIGENT_WORKFLOWS.WORKFLOWS_LIST.WORKFLOW_NAME_COLUMN'),
        flex: 2,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ({ row }) => (
          <Stack gap={2} direction="row" alignItems="center">
            <Text variant="body-bold">{row.workflowName}</Text>
            {row.isDefault && (
              <Tooltip content={t('ENTITIES.WORKFLOW.DEFAULT_TOOLTIP')} size="M" minified>
                <ChipButton isActive={false} color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} size="S" variant="solid">
                  {t('ENTITIES.WORKFLOW.DEFAULT')}
                  <IconWrapper icon={InfoOutlined} size="M" opacity={0.6} />
                </ChipButton>
              </Tooltip>
            )}
          </Stack>
        ),
      },
      {
        field: 'activeCompletedCounts',
        headerName: t('INTELLIGENT_WORKFLOWS.WORKFLOWS_LIST.ACTIVE_COMPLETED_COLUMN'),
        disableColumnMenu: true,
        sortable: false,
        renderCell: ({ row }) => (
          <>
            <Text variant="body-regular">{row.activeInstances}</Text>
            <Text variant="body-regular" color="text3">
              /{row.completedInstances}
            </Text>
          </>
        ),
        flex: 1,
      },
      {
        field: 'activeVersionName',
        headerName: t('INTELLIGENT_WORKFLOWS.WORKFLOWS_LIST.LIVE_VERSION_NAME_COLUMN'),
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
      },
      {
        field: 'isDefault',
      },
      {
        field: 'actions',
        headerName: t('INTELLIGENT_WORKFLOWS.WORKFLOWS_LIST.ACTIONS_COLUMN'),
        disableColumnMenu: true,
        sortable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <Button
            component={Link}
            to={generatePathForRoute(routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.SERVICE.DETAIL, {
              workflowId: row.id,
              serviceId: createServiceIdentifier(serviceRef),
            })}
            size="S"
            variant="ghost"
            color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          >
            {t('INTELLIGENT_WORKFLOWS.WORKFLOWS_LIST.VIEW_ACTION')}
          </Button>
        ),
        renderSkeletonCell: () => null,
      },
    ];
    return columns;
  }, [generatePathForRoute, routes.INTELLIGENT_WORKFLOWS.WORKFLOWS.SERVICE.DETAIL, serviceRef, t]);
};
