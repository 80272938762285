import { FileMetaData } from '@vertice/slices';
import { AdvancedLinearApproximationCostModel, ContractModel2 } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { RenewalProduct } from './hooks/useCreateRenewalRequest';
import {
  getProductsFromItems,
  isAdvancedLinearApproximationModel,
} from '../saas/contract/costModels/AdvancedLinearApproximation/AdvancedLinearApproximation';
import { useSearchParams } from 'react-router-dom';

export const getFiles = (files: Array<FileMetaData>) => files.filter(({ type }) => type === 'FILE');

/**
 * Use this function to extract product items from Advanced Linear Approximation cost model
 */
export const mapCostModelProductsToRenewalProducts = (costModel?: ContractModel2): RenewalProduct[] => {
  if (isAdvancedLinearApproximationModel(costModel)) {
    const advancedCostModel = costModel?.model as AdvancedLinearApproximationCostModel;
    return getProductsFromItems(advancedCostModel?.items).map((prod) => ({
      id: prod.id ?? undefined,
      name: prod.name ?? undefined,
      productId: prod.productId ?? undefined,
      numberOfLicences: prod.numberOfLicences ?? undefined,
      licenseType: prod.licenseType ?? undefined,
      totalCost: prod.totalCost ?? undefined,
      licenseCost: prod.licenseCost ?? undefined,
      allocationSpan: prod.allocationSpan ?? undefined,
    }));
  }

  return [];
};

/**
 * Get only last part of urn. It's without version, so it can be used even if some insignificant change appear.
 * @param urn Something like 'urn:verticeone:vertice::services:form/integrations/jira/createTicket/v1'
 * @return Something like 'form/integrations/jira/createTicket'
 */
export const getLastPartDiversioned = (urn?: string) => {
  if (!urn) return null;

  const versionedKey = urn.split(':').slice(-1)[0];
  const regex = /^(.*)\/v1$/; // catch part without version
  const match = versionedKey.match(regex);
  if (match) {
    return match[1];
  }

  return null;
};

export const useQueryParam = <T extends string>(key: string, defaultValue: T) => {
  const [queryParam, _setQueryParam] = useSearchParams();
  const value = (queryParam.get(key) || defaultValue) as T;

  const setQueryParam = (newParam: T | ((prev: T) => T)) => {
    return _setQueryParam((params) => {
      const newValue = typeof newParam === 'function' ? newParam(value) : newParam;

      if (newValue === '') {
        params.delete(key);
      } else {
        params.set(key, newValue);
      }

      return params;
    });
  };

  return [value, setQueryParam] as const;
};
