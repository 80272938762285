import { Box, styled } from '@mui/material';
import { FC, PropsWithChildren } from 'react';

type SectionWrapperProps = {
  isVisible?: boolean;
} & PropsWithChildren;

const StyledBox = styled(Box)(({ theme }) => ({
  '&:not(:last-child)': {
    paddingBottom: theme.spacing(8),
    borderBottom: `1px solid ${theme.palette.core.color3}`,
  },
}));

export const SectionWrapper: FC<SectionWrapperProps> = ({ children, isVisible = true }) => {
  if (!isVisible) {
    return null;
  }

  return <StyledBox>{children}</StyledBox>;
};
