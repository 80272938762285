import { Stack, useTheme } from '@mui/material';
import { Children, FC, isValidElement, PropsWithChildren, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { generatePath, Link } from 'react-router-dom';

import { SlackIcon } from '@vertice/assets';
import { BreadcrumbItem, IconWrapper, PageHeader } from '@verticeone/design-system';
import { useRoutes } from '@verticeone/router/src';
import { IntegrationSetupDialog } from 'pages/Preferences/Integrations/components/IntegrationSetupDialog';
import { WizardStepProps } from 'pages/Preferences/Integrations/components/Wizard';
import { Step } from '../common';
import { useDeactivateSlackIntegration } from './hooks/useDeactivateSlackIntegration';

type IntegrationSetupProps = {
  activeStep: Step;
} & PropsWithChildren;

export const IntegrationSetup: FC<IntegrationSetupProps> = ({ activeStep, children }) => {
  const { t } = useTranslation();
  const { palette } = useTheme();
  const routes = useRoutes();

  const [isDisconnectDialogOpened, setIsDisconnectDialogOpened] = useState(false);
  const handleSetIsDisconnectDialogOpened = (value: boolean) => {
    setIsDisconnectDialogOpened(value);
  };

  const { deactivateSlackIntegration, isIntegrationDeactivationInProgress, isIntegrationRevokeInProgress } =
    useDeactivateSlackIntegration(handleSetIsDisconnectDialogOpened);

  const stepContent = Children.toArray(children).find((child) =>
    isValidElement<WizardStepProps>(child) ? child.props.stepValue === activeStep : false
  );

  const items: BreadcrumbItem<typeof Link>[] = [
    {
      label: t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.BREADCRUMBS_PREFERENCES'),
      component: Link,
      to: generatePath(routes.PREFERENCES.ABSOLUTE_PATH),
    },
    {
      label: t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.BREADCRUMBS_SLACK'),
      startAdornment: <IconWrapper icon={SlackIcon} />,
    },
  ];

  return (
    <Stack flex={1} bgcolor={palette.core.color1}>
      <PageHeader
        breadcrumb={<PageHeader.Breadcrumbs items={items} />}
        color="neutral"
        actions={
          activeStep === Step.CONNECTION && (
            <>
              <PageHeader.PrimaryAction onClick={() => setIsDisconnectDialogOpened(true)}>
                {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.DISCONNECT')}
              </PageHeader.PrimaryAction>
              <IntegrationSetupDialog
                header={t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.DISCONNECT_DIALOG_HEADER')}
                content={t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.DISCONNECT_DIALOG_TEXT')}
                buttonLabel={t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.DISCONNECT_DIALOG_BUTTON')}
                isLoading={isIntegrationDeactivationInProgress || isIntegrationRevokeInProgress}
                isOpened={isDisconnectDialogOpened}
                onSubmit={deactivateSlackIntegration}
                onClose={() => setIsDisconnectDialogOpened(false)}
              />
            </>
          )
        }
      />
      <Stack p={6} alignItems="center" borderTop={`1px solid ${palette.core.color3}`}>
        {stepContent}
      </Stack>
    </Stack>
  );
};
