import { getTableData } from '@vertice/dashboard/src/modules/cloud/utils/graphDataUtils';

export type Task = {
  id: string;
  title: string;
  description: string;
  date: string;
};

type ActiveTasksData = {
  items: Task[];
};

export const useActiveTasksData = () => {
  const tempData = {
    name: 'active_tasks',
    stats: {},
    details: {
      columns: ['id', 'title', 'description', 'date'],
      types: ['string', 'string', 'string', 'isotime'],
      data: [
        ['1', 'Requirements Gathering', 'Atlassian New Purchase', '2024-12-18'],
        ['2', 'IT Security Approval', 'Design Suite for Marketing', '2024-12-31'],
        ['3', 'SaaS Baseline Offers', 'Slack Renewal', '2025-01-15'],
        ['4', 'C-Level Executive Approval', 'Slack Renewal', '2025-02-04'],
        ['5', 'Legal Approval', 'Google Domains New Purchase', '2025-02-22'],
      ],
    },
  };

  const data: ActiveTasksData = {
    items: getTableData(tempData.details, {
      title: 'title',
      description: 'description',
      date: 'date',
    }) as Task[],
  };

  return {
    data,
    isLoading: false,
  };
};
