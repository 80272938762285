import { useEffect, useState } from 'react';
import { isIOMappingConfiguration } from '../../../../../definitions/taskDefinition';
import { FieldMapping, TaskDefinition } from '../../../../../definitionsTypes';
import { getLastPartDiversioned } from '../../../../../utils';
import { Variable, VariableOrigin } from '../../../types';
import { TICKET_ID_NAME } from '../JiraCommon/utils';
import {
  ISSUE_KEY_INPUT_FIELD_VALUE,
  JIRA_FIELD_PREFIX_V2,
  REQUIRED_JIRA_SYNC_FIELDS,
  JIRA_FIELD_PREFIX_ESCAPED,
  SUMMARY_INPUT_FIELD_VALUE,
} from '../utils';
import { VariableMapperRowFormBase } from '../../../VariableMapper/formSchema';
import { useWorkflowVariablesGroups } from '../../../forms/EditGatewayForm/useWorkflowVariablesGroups';
import { RequiredField, WorkflowVariables } from '../../../VariableMapper/types';
import { enhanceWithRequired, workflowToFormVariables } from '../../../VariableMapper/utils';

type UseVariablesWithDefaultParam = {
  task: TaskDefinition;
  currentTaskName: string;
  externalVariables?: Variable[];
  workflowVariables: WorkflowVariables;
  setWorkflowVariables: (variables: WorkflowVariables) => void;
};

type EnhancedDefaultVariables = {
  requiredFormVariables: VariableMapperRowFormBase[];
  restFormVariables: VariableMapperRowFormBase[];
};

export const useVariablesWithDefault = ({
  task,
  currentTaskName,
  externalVariables,
  workflowVariables,
  setWorkflowVariables,
}: UseVariablesWithDefaultParam) => {
  const variablesGroups = useWorkflowVariablesGroups({
    udfVariables: workflowVariables.udfs,
    allRequestVariables: workflowVariables.request,
  });
  const [enhancedDefaultVariables, setEnhancedDefaultVariables] = useState<EnhancedDefaultVariables | undefined>();

  useEffect(() => {
    if (!task || externalVariables === undefined || enhancedDefaultVariables) return;

    const { variables } = getJiraSyncInputMapping(task);
    let initialVariables = workflowToFormVariables(variables, false);
    const requiredFields: RequiredField[] = REQUIRED_JIRA_SYNC_FIELDS.map((externalFieldId) => {
      return { variableId: externalFieldId, whereRequired: 'from', preCreateWfVariable: true, mappingType: 'Value' };
    });
    const { requiredFormVariables, restFormVariables, newWorkflowVariables } = enhanceWithRequired({
      formVariables: initialVariables,
      requiredFields,
      task: { id: task.task.id, name: currentTaskName },
      externalVariables,
      variablesGroups,
    });
    // Update local variables by pre-created ones.
    newWorkflowVariables.length &&
      setWorkflowVariables({
        request: [...workflowVariables.request, ...newWorkflowVariables],
        udfs: workflowVariables.udfs,
      });

    setEnhancedDefaultVariables({ requiredFormVariables, restFormVariables });
  }, [
    enhancedDefaultVariables,
    externalVariables,
    setWorkflowVariables,
    task,
    currentTaskName,
    variablesGroups,
    workflowVariables,
  ]);

  return enhancedDefaultVariables;
};

export const getJiraSyncInputMapping = (task: TaskDefinition) => {
  const ioMappingConfiguration = task.task.configurations?.find(isIOMappingConfiguration);
  const inputFields = ioMappingConfiguration?.mapping.inputFields;

  let ticketId: string | undefined;
  let filterStatus: string[] | undefined;
  let variables: FieldMapping[] = [];

  if (inputFields) {
    inputFields.forEach((field) => {
      // Necessary to skip these hard-coded values
      if (field.value === ISSUE_KEY_INPUT_FIELD_VALUE || field.value === SUMMARY_INPUT_FIELD_VALUE) return;

      switch (field.name) {
        case TICKET_ID_NAME:
          ticketId = field.value;
          break;
        case 'filter.status':
          filterStatus = JSON.parse(field.value.substring(1, field.value.length - 1));
          break;
        default:
          const regex = new RegExp(`${JIRA_FIELD_PREFIX_ESCAPED}(.*)`); // Capturing group for the rest of the string
          const match = field.value.match(regex);
          let name = '';
          if (match) {
            name = match[1];
          } else {
            name = field.name.split('<')[0].replace(JIRA_FIELD_PREFIX_V2, '');
          }
          variables.push({ ...field, name });
      }
    });
  }

  return { ticketId, filterStatus, variables };
};

const CREATE_JIRA_IDENTIFIERS = ['schema/core/jira/ticketId', 'schema/integrations/jira/ticketId'];

const isJiraRelatedVariable = (variable: Variable) => {
  // 'schema/core/jira/ticketId' is legacy identifier, will be removed as a part of https://vertice.atlassian.net/browse/GREEN-1268
  const lastPart = getLastPartDiversioned(variable.type.xType);
  return lastPart && CREATE_JIRA_IDENTIFIERS.includes(lastPart);
};

export const getAvailableCreateJiraTasks = (workflowVariables: Variable[]) => {
  // variables - tickets
  const createJiraTickets = workflowVariables.filter(isJiraRelatedVariable);

  const createJiraTasks = createJiraTickets.map((variable) => variable.origin);
  const createJiraTicketsByTasks = {} as Record<string, Variable>;
  createJiraTickets.forEach((variable) => (createJiraTicketsByTasks[variable.origin.id] = variable));
  const createJiraTasksByTickets = {} as Record<string, VariableOrigin>;
  createJiraTickets.forEach((variable) => (createJiraTasksByTickets[variable.id] = variable.origin));
  return { createJiraTicketsByTasks, createJiraTasks, createJiraTasksByTickets };
};
