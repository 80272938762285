import camelCase from 'lodash/camelCase';
import { Variable, VariableOrigin } from '../types';
import { formatVariableLabel } from '../../WorkflowViewer/utils';
import {
  fixName,
  hasValidNameCharacters,
  hasValidUppercaseWord,
  isValidNameFormat,
  normalizeWhiteSpace,
} from './variableNameUtils';
import { VariablesGroup } from './types';
import { TaskMinimum } from '../EditNodeDrawers/EditServiceTaskDrawer/JiraCommon/types';

export const createVariableId = (name: string) => camelCase(normalizeWhiteSpace(name));

export const createVariable = (addVariableDefaults: Pick<Variable, 'origin' | 'type'>, name: string): Variable => {
  const id = createVariableId(name);
  return {
    id,
    label: formatVariableLabel(id),
    isVisible: true,
    required: false,
    isSelectable: true,
    path: [formatVariableLabel(id)],
    variables: [],
    ...addVariableDefaults,
  };
};

type Validation = {
  isEmpty: boolean;
  isValidFormat: boolean;
  isNewName: boolean;
  alreadyExists: boolean;
  hasValidCase: boolean;
  hasValidCharacters: boolean;
};

export const createValidationObj = (name: string, variablesGroups: VariablesGroup[]): Validation => {
  const id = createVariableId(name);
  const cleanedName = normalizeWhiteSpace(name);
  const otherTopLevelVariableIds = variablesGroups
    .flatMap((group) => Object.values(group.originsWithVariables).flatMap((o) => o.variables))
    .map((v) => v.id);

  return {
    isEmpty: cleanedName === '',
    isValidFormat: isValidNameFormat(cleanedName),
    hasValidCharacters: hasValidNameCharacters(cleanedName),
    hasValidCase: hasValidUppercaseWord(cleanedName),
    isNewName: true,
    alreadyExists: otherTopLevelVariableIds.includes(id),
  };
};

export const validate = (validation: Validation) => {
  const { isEmpty, isValidFormat, isNewName, alreadyExists, hasValidCase, hasValidCharacters } = validation;
  return !isEmpty && isValidFormat && isNewName && !alreadyExists && hasValidCase && hasValidCharacters;
};

/**
 * Check if nam exists among workflow variables and if yes, add random digit to the end. Repeat.
 */
export const uniqueNameWithNumber = (name: string, variablesGroups: VariablesGroup[]) => {
  let validation = createValidationObj(name, variablesGroups);
  if (!validate(validation)) {
    name = fixName(name);
    validation = createValidationObj(name, variablesGroups);
  }
  while (!validate(validation)) {
    name = `${name} ${Math.round(Math.random() * 10)}`;
    name = fixName(name);
    validation = createValidationObj(name, variablesGroups);
  }

  return name;
};

export const composeVariableDefaults = (
  sourceVariable: Variable,
  task: TaskMinimum,
  originKind: VariableOrigin['kind']
) => {
  const addVariableDefaults: Pick<Variable, 'type' | 'origin'> = {
    type: {
      baseType: sourceVariable.type.baseType,
      labels: sourceVariable.type.labels,
      xType: sourceVariable.type.xType,
    },
    origin: {
      kind: originKind,
      id: task.id,
      label: task.name,
    },
  };

  return addVariableDefaults;
};
