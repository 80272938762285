import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import { TabsBottomLineWrapper } from '@verticeone/design-system';
import { Tabs } from '@verticeone/design-system';
import { Tab as BaseTab } from '@verticeone/design-system';
import { toConstantCase } from '@verticeone/utils/strings';
import { AWS_BRAND_COLOR } from '@vertice/dashboard/src/modules/cloud/constants';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import LoadableAWSPageWrapper from 'pages/Cloud/LoadableAWSPageWrapper';
import { useCloudContext } from '@vertice/dashboard/src/modules/cloud/CloudContext';

type TABS = 'optimize' | 'inventory' | 'transactions';

type CloudRIOLayoutProps = {
  activeTab: TABS;
};

type TabProps = {
  value: TABS;
  onClick: () => void;
};

const getTabLabelKey = (tabId: string) => `CLOUD.RIO.${toConstantCase(tabId)}_TAB`;

const Tab = ({ value, onClick, ...otherProps }: TabProps) => {
  const { t } = useTranslation();

  return <BaseTab {...otherProps} key={value} value={value} label={t(getTabLabelKey(value))} onClick={onClick} />;
};

const CloudRIOLayoutInner = ({ activeTab }: CloudRIOLayoutProps) => {
  const { subFeatures } = useCloudContext();
  const routes = useRoutes();
  const { navigate } = useRouteNavigate();

  useEffect(() => {
    if (!subFeatures.rio) {
      navigate(routes.PAGE_NOT_FOUND);
    }
  }, [subFeatures, routes, navigate]);

  useEffect(() => {
    if (!activeTab) {
      navigate(routes.CLOUD.RIO.OPTIMIZE, {}, { replace: true });
    }
  }, [activeTab, routes, navigate]);

  return (
    <>
      <TabsBottomLineWrapper>
        <Tabs variant="outlined" value={activeTab} color={AWS_BRAND_COLOR}>
          <Tab value="optimize" onClick={() => navigate(routes.CLOUD.RIO.OPTIMIZE)} />
          <Tab value="inventory" onClick={() => navigate(routes.CLOUD.RIO.INVENTORY)} />
          <Tab value="transactions" onClick={() => navigate(routes.CLOUD.RIO.TRANSACTIONS)} />
        </Tabs>
      </TabsBottomLineWrapper>
      <Box position="relative">
        <Outlet />
      </Box>
    </>
  );
};

const CloudRIOLayout = ({ activeTab }: CloudRIOLayoutProps) => (
  <LoadableAWSPageWrapper getCanBeViewed={({ rio }) => rio}>
    <CloudRIOLayoutInner activeTab={activeTab} />
  </LoadableAWSPageWrapper>
);

export default CloudRIOLayout;
