import { FC } from 'react';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import { useTranslation } from 'react-i18next';
import { Button } from '@verticeone/design-system';
import { VerticeVWhiteIcon } from '@verticeone/design-system';
import { Link as RouterLink } from 'react-router-dom';
import { Stack } from '@mui/material';
import { Divider } from '@verticeone/design-system';
import { Text } from '@verticeone/design-system';
import { NonSaasRenewalAlert } from '@vertice/core/src/modules/saas/contract/components/NonSaasRenewalAlert';
import { useRenewOutsideVertice } from './hooks/useRenewOutsideVertice';

type ContractRenewActionSaasProps = { contract: Contract };

/**
 * Base actions to renew contract. This will be deprecated in near future and replaced with
 * requesting new workflow.
 *
 * Based on the provided `contract` prop this component allow user to redirect to Intake
 * form, or generate Draft version of the current contract.
 */
export const ContractRenewActionSaas: FC<ContractRenewActionSaasProps> = (props) => {
  const { contract } = props;
  const { t } = useTranslation();

  const routes = useRoutes();
  const { generatePath } = useRouteNavigate();

  const { accountId } = useAccountContext();
  const { handleRenewOutsideVertice, isLoading } = useRenewOutsideVertice({ contract, accountId });

  const renewalIntakePath = generatePath(routes.CONTRACTS.DETAIL.RENEWAL_INTAKE.ABSOLUTE_PATH, {
    contractId: contract.record.contractId,
  });

  return (
    <>
      <Stack gap={4} width="100%">
        <Stack gap={1}>
          <Button
            fullWidth
            variant="solid"
            color="primary"
            isCaption
            size="S"
            component={RouterLink}
            to={renewalIntakePath}
            disabled={isLoading}
          >
            <VerticeVWhiteIcon />
            {t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.RENEW_WITH_VERTICE')}
          </Button>
          <NonSaasRenewalAlert contract={contract} />
        </Stack>
        <Divider>
          <Text variant="label" color="text3" size="XXS">
            {t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.OR')}
          </Text>
        </Divider>
        <Button
          fullWidth
          variant="plain"
          color="primary"
          size="M"
          onClick={handleRenewOutsideVertice}
          disabled={isLoading}
          isLoading={isLoading}
        >
          {t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.RENEW_OUTSIDE_VERTICE_BUTTON')}
        </Button>
      </Stack>
    </>
  );
};
