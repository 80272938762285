import { Text, TextProps } from '@verticeone/design-system';
import { animate, motion, useMotionValue, useTransform } from 'framer-motion';
import React, { forwardRef, useEffect } from 'react';

type AnimatedTextNumberProps = {
  children: number;
} & TextProps;

const AnimatedTextNumber = ({ children, ...textProps }: AnimatedTextNumberProps) => {
  const count = useMotionValue(children);
  const rounded = useTransform(() => Math.round(count.get()));

  useEffect(() => {
    const controls = animate(count, children, { duration: 0.25 });
    return () => controls.stop();
  }, [count, children]);

  const TextComponent = forwardRef<HTMLElement, TextProps>((_, ref) => {
    return (
      <Text {...textProps} ref={ref}>
        {children}
      </Text>
    );
  });

  const MotionComponent = motion(TextComponent);

  return <MotionComponent>{rounded}</MotionComponent>;
};

export default AnimatedTextNumber;
