import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CopyAllOutlined } from '@mui/icons-material';

import { DesignSystemColor, DesignSystemSize, IconButton } from '@verticeone/design-system';
import { Tooltip } from '@verticeone/design-system';

import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../constants';
import { WorkflowVersionRow } from '../types';
import { useDuplicateWorkflowVersion } from './hooks/useDuplicateWorkflowVersion';

type DuplicateButtonProps = {
  row: WorkflowVersionRow;
  color?: DesignSystemColor;
  size?: DesignSystemSize;
  tooltipTitle?: string;
};

export const DuplicateButton: FC<DuplicateButtonProps> = ({
  row,
  color = INTELLIGENT_WORKFLOWS_BRAND_COLOR,
  size,
  tooltipTitle,
}) => {
  const { t } = useTranslation();

  const { duplicateWorkflow, isDuplicatingWorkflow } = useDuplicateWorkflowVersion(row);
  const isDisabled = isDuplicatingWorkflow;

  const handleButtonClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    await duplicateWorkflow();
  };

  const Button = (
    <IconButton
      icon={CopyAllOutlined}
      variant="outline"
      color={color}
      onClick={handleButtonClick}
      isLoading={isDuplicatingWorkflow}
      disabled={isDisabled}
      size={size}
    />
  );

  // disabled button cannot have a tooltip
  return isDisabled ? (
    Button
  ) : (
    <Tooltip title={tooltipTitle || t('ENTITIES.WORKFLOW_VERSION.ACTIONS.DUPLICATE')} minified size="S">
      {Button}
    </Tooltip>
  );
};
