import React, { FC } from 'react';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../constants';
import { Button } from '@verticeone/design-system';
import { WorkflowVersionRow } from '../types';
import { useActivateWorkflowVersion } from './hooks/useActivateWorkflowVersion';
import { useTranslation } from 'react-i18next';

type ActivateButtonProps = {
  row: WorkflowVersionRow;
};

export const ActivateButton: FC<ActivateButtonProps> = ({ row }) => {
  const { t } = useTranslation();
  const { activateWorkflowVersion, versionBeingActivated } = useActivateWorkflowVersion({
    workflowId: row.id,
    workflowVersion: row.versionId,
    versionName: row.versionName!,
  });

  const isDisabled =
    row.status === 'ACTIVE' || !!row.workflowDefinitionStatus?.error || versionBeingActivated !== undefined;

  return (
    <Button
      color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
      variant="solid"
      disabled={isDisabled}
      isLoading={versionBeingActivated === row.versionId}
      onClick={() => activateWorkflowVersion()}
    >
      {t('ENTITIES.WORKFLOW_VERSION.ACTIONS.SET_AS_LIVE')}
    </Button>
  );
};
