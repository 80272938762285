import { createApi } from '@reduxjs/toolkit/query/react';
import { API_URLS } from '../constants';
import prepareBaseQuery from '../baseQuery';

export const WORKFLOWS_BFFE_TAG_PREFIX = 'WorkflowsBffe';

export const bffeWorkflowsAPI = createApi({
  reducerPath: 'bffeWorkflowsAPI',
  baseQuery: prepareBaseQuery(API_URLS.BASE_URLS.BFFE_WORKFLOWS),
  tagTypes: [WORKFLOWS_BFFE_TAG_PREFIX],
  endpoints: () => ({}),
});
