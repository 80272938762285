import React from 'react';
import { Button, Menu, MenuItem, Text } from '@verticeone/design-system';
import { ArrowDropDownOutlined, ArrowDropUpOutlined } from '@mui/icons-material';

type DropDownMenuItem = {
  id: number;
  label: string;
};

type DropDownMenuProps = {
  label: string;
  placeholder: string;
  onChange?: (id: number) => void;
  items: DropDownMenuItem[];
};

const DropDownMenu = ({ label, placeholder, onChange, items }: DropDownMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => items.length > 0 && setAnchorEl(event.currentTarget);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = async (id: number) => {
    setAnchorEl(null);
    await onChange?.(id);
  };

  return (
    <>
      <Button
        id="status-button"
        onClick={handleClick}
        variant="outline"
        disabled={items.length === 0}
        color="neutral"
        size="S"
        sx={{ background: 'white' }}
      >
        <Text variant="body-regular" color="text3" size="S">
          {label}
        </Text>
        {placeholder}
        {open ? <ArrowDropUpOutlined /> : <ArrowDropDownOutlined />}
      </Button>
      <Menu
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        autoFocus={false}
        testId={'status-menu'}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
      >
        {items.map((item) => (
          <MenuItem onClick={() => handleChange(item.id)}>
            <Text variant="body-bold" color="text2" size="S">
              {item.label}
            </Text>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default DropDownMenu;
