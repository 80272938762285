import { Box, Card, CardActions, CardContent, CardHeader, Stack } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IntegrationAWSIcon } from '@vertice/assets';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useGetAccountIntegrationQuery, useRevokeAccountIntegrationMutation } from '@vertice/slices';
import { Button, ButtonProps, Loader, Tooltip } from '@verticeone/design-system';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';
import useCloudFeature from 'modules/cloud/hooks/useCloudFeature';
import { IntegrationSetupDialog } from 'pages/Preferences/Integrations/components/IntegrationSetupDialog';
import { AWSAccountTableDialog } from '..';
import styles from '../../../common.module.scss';
import { IntegrationsCardContent } from '../../../components/IntegrationsCardContent';
import { StatusBadge, StatusBadgeVariant } from '../../../components/StatusBadge';
import { useAccountTableData } from '../../hooks/useAccountTableData';

export const AWS_INTEGRATION_ID = 'Provider:AWS:CloudOptimization';

const getBadgeStatus = (awsStatus?: string) => {
  switch (awsStatus) {
    case 'MISSING':
      return StatusBadgeVariant.NEW;
    case 'SETUP':
      return StatusBadgeVariant.PROGRESS;
    case 'ACTIVE':
      return StatusBadgeVariant.ACTIVE;
    default:
      return undefined;
  }
};

const AWSAccountTableButton = (props: ButtonProps) => {
  const { t } = useTranslation(undefined, { keyPrefix: 'PREFERENCES.INTEGRATIONS.ACCOUNT_TABLE_BUTTON' });
  const { error: accountDataError, isEmpty: accountDataEmpty, data: accountData } = useAccountTableData();
  const accountTableEnabled = !accountDataError && !accountDataEmpty && accountData;

  const button = (
    <Button variant="ghost" color="neutral" size="S" disabled={!accountTableEnabled} {...props}>
      {t('LABEL')}
    </Button>
  );

  return accountTableEnabled ? (
    button
  ) : (
    <Tooltip size="M" noArrow title={t('TOOLTIP.TITLE')} content={t('TOOLTIP.TEXT')}>
      <div>{button}</div>
    </Tooltip>
  );
};

export const AWSSetupCard = () => {
  const { t } = useTranslation();
  const { navigate } = useRouteNavigate();
  const routes = useRoutes();
  const integrationId = AWS_INTEGRATION_ID;
  const [actionInProgress, setActionInProgress] = useState(false);
  const [openRevoke, setOpenRevoke] = useState(false);
  const [openAccountTable, setOpenAccountTable] = useState(false);
  const cloudFeature = useCloudFeature();
  const { accountId } = useAccountContext();

  const [revokeAccountIntegrationMutation] = useRevokeAccountIntegrationMutation();
  const { data: awsDetails, isLoading: isAwsDetailsLoading } = useGetAccountIntegrationQuery({
    accountId: accountId,
    integrationId,
  });

  const navigateToAwsSetup = () => navigate(routes.PREFERENCES.INTEGRATIONS.AWS);
  const showRevokeDialog = () => setOpenRevoke(true);
  const hideRevokeDialog = () => setOpenRevoke(false);
  const showAccountTableDialog = () => setOpenAccountTable(true);
  const hideAccountTableDialog = () => setOpenAccountTable(false);

  const handleRevoke = async () => {
    setActionInProgress(true);
    revokeAccountIntegrationMutation({
      accountId: accountId,
      integrationId,
    }).finally(() => {
      setOpenRevoke(false);
      setActionInProgress(false);
    });
  };

  const awsStatus = awsDetails?.status;
  const isMissing = awsStatus === 'MISSING';
  const badgeStatus = getBadgeStatus(awsStatus);
  const disableButton = cloudFeature.isLoading || !cloudFeature?.data.isMainFeatureEnabled;

  return (
    <div className={styles.card}>
      <Card variant="outlined">
        {isAwsDetailsLoading || cloudFeature.isLoading ? (
          <Box sx={{ height: '244px' }}>
            <Loader />
          </Box>
        ) : (
          <>
            <CardHeader avatar={<IntegrationAWSIcon />} />
            <CardContent classes={{ root: styles['card-content'] }}>
              <IntegrationsCardContent
                title={t('PREFERENCES.INTEGRATIONS.AWS.TITLE')}
                statusBadge={badgeStatus && <StatusBadge variant={badgeStatus} />}
                description={t('PREFERENCES.INTEGRATIONS.AWS.DESCRIPTION')}
              />
            </CardContent>
            <CardActions className={styles['card-actions']}>
              {isMissing ? (
                <Button
                  disabled={disableButton}
                  onClick={navigateToAwsSetup}
                  variant="outline"
                  color="neutral"
                  size="S"
                >
                  {t('PREFERENCES.INTEGRATIONS.RUN_SETUP')}
                </Button>
              ) : (
                <Stack direction="row" gap={2}>
                  <Button
                    onClick={showRevokeDialog}
                    variant="ghost"
                    color="neutral"
                    size="S"
                    disabled={disableButton || actionInProgress}
                  >
                    {t('PREFERENCES.INTEGRATIONS.REVOKE_ACCESS')}
                  </Button>
                  <AWSAccountTableButton onClick={showAccountTableDialog} />
                </Stack>
              )}
            </CardActions>
          </>
        )}
      </Card>

      <IntegrationSetupDialog
        header={t('PREFERENCES.INTEGRATIONS.AWS.REVOKE_DIALOG.TITLE')}
        content={t('PREFERENCES.INTEGRATIONS.AWS.REVOKE_DIALOG.DESCRIPTION')}
        buttonLabel={t('PREFERENCES.INTEGRATIONS.AWS.REVOKE_DIALOG.REVOKE')}
        isLoading={actionInProgress}
        isOpened={openRevoke}
        onSubmit={handleRevoke}
        onClose={hideRevokeDialog}
      />

      <AWSAccountTableDialog open={openAccountTable} onClose={hideAccountTableDialog} />
    </div>
  );
};
