import { Task } from '../../../../definitionsTypes';
import { isVerticeServiceConfiguration } from '../../../../pocWorkflowSchema';
import { getLastPartDiversioned } from '../../../../utils';
import { TaskNodeState } from '../../../model/types';

export const JIRA_FIELD_PREFIX = 'jiraFields.';
// eslint-disable-next-line no-useless-escape
export const JIRA_FIELD_PREFIX_ESCAPED = JIRA_FIELD_PREFIX.replace('.', `\.`);
export const JIRA_FIELD_PREFIX_V2 = 'issue.fields.';
// eslint-disable-next-line no-useless-escape
export const JIRA_FIELD_PREFIX_V2_ESCAPED = JIRA_FIELD_PREFIX_V2.replace('.', `\.`);
export const REQUIRED_JIRA_CREATE_FIELDS = ['summary', 'description'];
export const REQUIRED_JIRA_SYNC_FIELDS = ['status', 'resolution'];
export const JIRA_CREATE_TICKET_SERVICE_URN_SUFFIX = 'service/jira/createTicket';
export const JIRA_SYNC_TICKET_SERVICE_URN_SUFFIX = 'service/jira/syncTicket';
export const ISSUE_KEY_INPUT_FIELD_VALUE = '__issueKey__';
export const SUMMARY_INPUT_FIELD_VALUE = '__summary__';
// Not added by user, but needed to show some specific information
export const SUPPORTING_INPUT_FIELD_VALUES = [SUMMARY_INPUT_FIELD_VALUE];

const JIRA_TASKS_CONFIG_URN_SUFFIXES = [JIRA_CREATE_TICKET_SERVICE_URN_SUFFIX, JIRA_SYNC_TICKET_SERVICE_URN_SUFFIX];

/**
 * Among its configurations, there is some which can be configured via editable drawer
 */
export const checkServiceTaskIsEditable = (task: Task) => {
  const serviceConfigUrn = task.configurations?.find(isVerticeServiceConfiguration)?.resourceUrn;

  return JIRA_TASKS_CONFIG_URN_SUFFIXES.includes(getLastPartDiversioned(serviceConfigUrn) ?? '');
};

export const checkServiceTaskHasDetail = (state?: TaskNodeState) => {
  const serviceConfigUrn = getLastPartDiversioned(state?.resourceUrn);

  return serviceConfigUrn ? JIRA_TASKS_CONFIG_URN_SUFFIXES.includes(serviceConfigUrn) : false;
};
