import { Route, Routes } from 'react-router-dom';

import { Company as CompanyPage } from '@vertice/components';
import { FEATURES } from '@vertice/core/src/modules/features/constants';
import { useFeatures } from '@vertice/core/src/modules/features/useFeatures';
import { useRoutes } from '@verticeone/router/src';
import { Customization as CustomizationPage } from './Customization/Customization';
import { Directory as DirectoryPage } from './Directory/Directory';
import { Integrations } from './Integrations';
import { AccountingSetupWizard } from './Integrations/Accounting/components';
import { AWSSetupWizard } from './Integrations/AWS/components';
import { BaseCustomIntegration } from './Integrations/Custom/page/BaseCustomIntegration';
import { CreateIntegration } from './Integrations/Custom/page/CreateIntegration';
import { EditIntegration } from './Integrations/Custom/page/EditIntegration';
import { EntraSetupWizard } from './Integrations/DiscoveryAndUsage/components/EntraSetup/EntraSetupWizard';
import { GoogleSetupWizard } from './Integrations/DiscoveryAndUsage/components/Google/GoogleSetupWizard';
import { JumpCloudSetupWizard } from './Integrations/DiscoveryAndUsage/components/JumpCloud/JumpCloudWizard';
import { OktaSetupWizard } from './Integrations/DiscoveryAndUsage/components/OktaSetup/OktaSetupWizard';
import { OneLoginSetupWizard } from './Integrations/DiscoveryAndUsage/components/OneLogin/OneLoginWizard';
import { SlackSetupWizard as SlackSetupWizardOld } from './Integrations/Notifications/components/Slack/V1/SlackSetupWizard';
import { SlackSetupWizardV2 } from './Integrations/Notifications/components/Slack/V2/SlackSetupWizardV2';
import { JiraSetup } from './Integrations/Workflows/Jira';
import { VisoTrustSetup } from './Integrations/Workflows/VisoTrust';
import { Preferences } from './Preferences';
import { SlackIntegrationWizard } from './User/SlackIntegration/Wizard/SlackIntegrationWizard';
import { User as UserPage } from './User/User';
import { Workflows as WorkflowsPage } from './Workflows/Workflows';

export const PreferencesRouter = () => {
  const { PREFERENCES } = useRoutes();
  const { isEnabled } = useFeatures();
  const SlackSetupWizard = isEnabled(FEATURES.INTEGRATION_SLACK_V2) ? SlackSetupWizardV2 : SlackSetupWizardOld;

  return (
    <Routes>
      <Route element={<Preferences />}>
        <Route path={PREFERENCES.COMPANY.PATH} element={<CompanyPage />} />
        <Route path={PREFERENCES.DIRECTORY.PATH} element={<DirectoryPage />} />
        <Route path={PREFERENCES.WORKFLOWS.PATH} element={<WorkflowsPage />} />
        <Route path={PREFERENCES.CUSTOMIZATION.PATH} element={<CustomizationPage />} />
        <Route path={PREFERENCES.USER.PATH} element={<UserPage />} />
        <Route path={PREFERENCES.USER.PATH}>
          <Route path={PREFERENCES.USER.SLACK_WIZARD.PATH} element={<SlackIntegrationWizard />} />
        </Route>
        <Route path={PREFERENCES.INTEGRATIONS.PATH} element={<Integrations />} />
      </Route>

      <Route path={PREFERENCES.INTEGRATIONS.PATH}>
        <Route path={PREFERENCES.INTEGRATIONS.ACCOUNTING.PATH} element={<AccountingSetupWizard />} />
        <Route path={PREFERENCES.INTEGRATIONS.AWS.PATH} element={<AWSSetupWizard />} />
        <Route path={PREFERENCES.INTEGRATIONS.NOTIFICATIONS.PATH}>
          <Route path={PREFERENCES.INTEGRATIONS.NOTIFICATIONS.SLACK.PATH} element={<SlackSetupWizard />} />
        </Route>
        <Route path={PREFERENCES.INTEGRATIONS.USAGE.PATH}>
          <Route path={PREFERENCES.INTEGRATIONS.USAGE.OKTA.PATH} element={<OktaSetupWizard />} />
          <Route path={PREFERENCES.INTEGRATIONS.USAGE.ENTRA.PATH} element={<EntraSetupWizard />} />
          <Route path={PREFERENCES.INTEGRATIONS.USAGE.ONELOGIN.PATH} element={<OneLoginSetupWizard />} />
          <Route path={PREFERENCES.INTEGRATIONS.USAGE.JUMPCLOUD.PATH} element={<JumpCloudSetupWizard />} />
          <Route path={PREFERENCES.INTEGRATIONS.USAGE.GOOGLE.PATH} element={<GoogleSetupWizard />} />
        </Route>
        <Route path={PREFERENCES.INTEGRATIONS.WORKFLOWS.PATH}>
          <Route path={PREFERENCES.INTEGRATIONS.WORKFLOWS.JIRA.PATH} element={<JiraSetup />} />
          <Route path={PREFERENCES.INTEGRATIONS.WORKFLOWS.VISO_TRUST.PATH} element={<VisoTrustSetup />} />
        </Route>
        <Route path={PREFERENCES.INTEGRATIONS.CUSTOM.PATH} element={<BaseCustomIntegration />}>
          <Route path={PREFERENCES.INTEGRATIONS.CUSTOM.EDIT.PATH} element={<EditIntegration />} />
          <Route path={PREFERENCES.INTEGRATIONS.CUSTOM.CREATE.PATH} element={<CreateIntegration />} />
        </Route>
      </Route>
    </Routes>
  );
};
