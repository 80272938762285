import { FC } from 'react';
import { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useTranslation } from 'react-i18next';
import { Button } from '@verticeone/design-system';
import { Stack } from '@mui/material';
import { Divider } from '@verticeone/design-system';
import { Text } from '@verticeone/design-system';
import { useRenewOutsideVertice } from './hooks/useRenewOutsideVertice';

type ContractRenewActionSaasIWProps = {
  contract: Contract;
  onSaasIWRequest: () => void;
};

/**
 * Actions to renew Saas contract.
 *
 * Based on the provided `contract` prop this component allow user to start new renewal workflow
 * in dialog, or generate Draft version of the current contract.
 */
export const ContractRenewActionSaasIW: FC<ContractRenewActionSaasIWProps> = (props) => {
  const { t } = useTranslation();
  const { contract, onSaasIWRequest } = props;

  const { accountId } = useAccountContext();
  const { handleRenewOutsideVertice, isLoading } = useRenewOutsideVertice({ contract, accountId });

  return (
    <>
      <Stack gap={4} width="100%">
        <Button fullWidth variant="solid" color="primary" isCaption size="S" onClick={onSaasIWRequest}>
          {t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.INITIATE_RENEWAL')}
        </Button>
        <Divider>
          <Text variant="label" color="text3" size="XXS">
            {t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.OR')}
          </Text>
        </Divider>
        <Stack direction="column" alignItems="center" gap={1}>
          <Text variant="body-regular" size="S">
            {t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.ALREADY_FULFILLED')}
          </Text>
          <Button
            fullWidth
            variant="plain"
            color="primary"
            onClick={handleRenewOutsideVertice}
            disabled={isLoading}
            isLoading={isLoading}
          >
            {t('SAAS.CONTRACT_NOT_YET_RENEWED_CARD.ADD_RENEWED_CONTRACT')}
          </Button>
        </Stack>
      </Stack>
    </>
  );
};
