import { useContext } from 'react';
import { NoData, NoDataButton } from '@verticeone/design-system';
import { useTranslation } from 'react-i18next';
import { AppTypeContext } from '../../../../../../../contexts/AppTypeContext';
import { useRouteNavigate, useRoutes } from '@verticeone/router/src';

export const NoActiveIntegration = () => {
  const { isIAT } = useContext(AppTypeContext);
  const { navigate } = useRouteNavigate();
  const routes = useRoutes();

  const navigateToSetup = () => {
    navigate(routes.PREFERENCES.INTEGRATIONS.WORKFLOWS.JIRA);
  };

  const { t } = useTranslation();
  return (
    <NoData
      header={t('INTELLIGENT_WORKFLOWS.JIRA.VARIABLE_MAPPER.NO_DATA.NO_INTEGRATION_HEADER')}
      content={t('INTELLIGENT_WORKFLOWS.JIRA.VARIABLE_MAPPER.NO_DATA.NO_INTEGRATION_CONTENT')}
      button={
        !isIAT && (
          <NoDataButton
            text={t('INTELLIGENT_WORKFLOWS.JIRA.VARIABLE_MAPPER.NO_DATA.NO_INTEGRATION_BUTTON')}
            onClick={navigateToSetup}
          />
        )
      }
    />
  );
};
