import React, { createContext, useContext, useState, PropsWithChildren } from 'react';
import { Dayjs } from 'dayjs';

export type Range = {
  startDate: Dayjs;
  endDate: Dayjs;
};

type TooltipData = {
  values: Record<string, number>;
  category: string | number;
  total: number;
};

type CompletedRequestsContextType = {
  range: Range;
  setRange: (startDate: Dayjs, endDate: Dayjs) => void;
  tooltipData: TooltipData | null;
  setTooltipData: (data: TooltipData | null) => void;
};

const CompletedRequestsContext = createContext<CompletedRequestsContextType | undefined>(undefined);

type CompletedRequestsProviderProps = PropsWithChildren & {
  defaultRange: {
    startDate: Dayjs;
    endDate: Dayjs;
  };
};

export const CompletedRequestsProvider = ({ children, defaultRange }: CompletedRequestsProviderProps) => {
  const [range, setRange] = useState<{
    startDate: Dayjs;
    endDate: Dayjs;
  }>(defaultRange);
  const [tooltipData, setTooltipData] = React.useState<TooltipData | null>(null);

  const updateRange = (startDate: Dayjs, endDate: Dayjs) => {
    setRange({ startDate, endDate });
  };

  return (
    <CompletedRequestsContext.Provider value={{ range, setRange: updateRange, tooltipData, setTooltipData }}>
      {children}
    </CompletedRequestsContext.Provider>
  );
};

export const useCompletedRequestsContext = (): CompletedRequestsContextType => {
  const context = useContext(CompletedRequestsContext);
  if (!context) {
    throw new Error('useRange must be used within a CompletedRequestsProvider');
  }
  return context;
};
