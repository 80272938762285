import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material';
import { DataGrid, NoData } from '@verticeone/design-system';

import { useFlatServiceWorkflowsWithAllVersions } from '../../hooks/useFlatServiceWorkflowsWithAllVersions';
import { getIsWorkflowLive, useWorkflowColumns } from './useWorkflowColumns';
import { parseWorkflowRef } from '../../../../../hooks/workflows/refUtils';
import { WorkflowPreviewDialog } from '../../../workflow/WorkflowDetailPage/components/WorkflowPreviewDialog';
import type { WorkflowVersionRow } from './types';
import type { Resource } from '../../types';

type WorkflowsTableProps = {
  service: Resource;
};

const ROW_HEIGHT = 60;
const LOADING_ROW_COUNT = 8;

const DataGridWithStyledRow = styled(DataGrid<WorkflowVersionRow>)(({ theme }) => ({
  '& .row-with-primary-background': {
    backgroundColor: theme.palette.primary.color4,
    '&:hover': {
      backgroundColor: theme.palette.primary.color3,
    },
  },
  '& .clickable': {
    cursor: 'pointer',
  },
}));

const NoWorkflowsData = () => {
  const { t } = useTranslation();
  return <NoData header={t(`INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.NO_WORKFLOWS`)} />;
};

export const WorkflowsTable: FC<WorkflowsTableProps> = ({ service }) => {
  const [workflowForPreview, setWorkflowForPreview] = useState<{ workflowId: string; versionId: string }>();

  const { workflowsWithVersions, isLoading } = useFlatServiceWorkflowsWithAllVersions(service);
  const columns = useWorkflowColumns();

  return (
    <>
      <DataGridWithStyledRow
        columns={columns}
        rows={workflowsWithVersions}
        headerSize="S"
        loadingStyle="skeleton"
        loading={isLoading}
        rowSelection={false}
        sortingMode="client"
        autoHeight={workflowsWithVersions.length < 1}
        onRowClick={({ row }) => {
          const workflowId = parseWorkflowRef(row.workflowRef)?.workflowId;
          if (workflowId) {
            setWorkflowForPreview({ workflowId, versionId: row.versionId });
          }
        }}
        loadingRowCount={LOADING_ROW_COUNT}
        getRowHeight={() => ROW_HEIGHT}
        noRowsOverlayHeight={ROW_HEIGHT * LOADING_ROW_COUNT}
        getRowClassName={({ row }) => {
          if (isLoading) return '';
          return getIsWorkflowLive(row) ? 'row-with-primary-background clickable' : 'clickable';
        }}
        getRowId={(row: WorkflowVersionRow) => `${row.id}-${row.versionId}`}
        slots={{
          noRowsOverlay: NoWorkflowsData,
        }}
        initialState={{
          sorting: {
            sortModel: [{ field: 'lastEdited', sort: 'desc' }],
          },
        }}
      />
      {workflowForPreview ? (
        <WorkflowPreviewDialog
          serviceRef={service.urn}
          workflowId={workflowForPreview.workflowId}
          versionId={workflowForPreview.versionId}
          onClose={() => setWorkflowForPreview(undefined)}
        />
      ) : null}
    </>
  );
};
