import { FC } from 'react';
import { Stack, styled, css } from '@mui/material';
import { Link } from 'react-router-dom';
import { Resource } from '../../types';
import { ServiceCardContent } from './ServiceCardContent';

export const cardCssProps = css(({ theme }) => ({
  display: 'flex',
  padding: 8,
  border: '1px solid',
  borderColor: theme.palette.core.color3,
  borderRadius: theme.spacing(3),
  textDecoration: 'none',
  color: 'unset',
  flexBasis: 362,
  flexGrow: 0,
  flexShrink: 0,
}));

const StyledLinkCard = styled(Link)(() => cardCssProps);
export const StyledStackCard = styled(Stack)(() => cardCssProps);

type ServiceCardProps = {
  service: Resource;
  inactive?: boolean;
  onClick?: (serviceRef: string) => void;
  targetPath?: string;
};

export const ServiceCard: FC<ServiceCardProps> = ({ service, inactive, onClick, targetPath }) => {
  if (inactive || !targetPath) {
    return (
      <StyledStackCard onClick={() => onClick?.(service.urn)} style={{ cursor: !!onClick ? 'pointer' : 'default' }}>
        <ServiceCardContent service={service} inactive={inactive} />
      </StyledStackCard>
    );
  }

  return (
    <StyledLinkCard to={targetPath}>
      <ServiceCardContent service={service} />
    </StyledLinkCard>
  );
};
