import { WorkflowVersion } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { ServiceCatalogResource } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { useMemo } from 'react';
import { isVerticeWorkflowHandler, parseServiceHandlerRef } from '../../../../hooks/workflows/refUtils';
import { WorkflowVersionRow } from './types';
import { useTranslation } from 'react-i18next';
import { GridComparatorFn } from '@mui/x-data-grid-pro';

export const useIsDefaultWorkflow = (workflowVersion?: WorkflowVersion, service?: ServiceCatalogResource) => {
  return useMemo(() => {
    if (workflowVersion !== undefined && service !== undefined) {
      const parsedServiceHandler = parseServiceHandlerRef(service.definition.ServiceProvider?.HandlerUrn);
      if (isVerticeWorkflowHandler(parsedServiceHandler)) {
        return parsedServiceHandler.workflowId === workflowVersion.id;
      }
    }
    return false;
  }, [workflowVersion, service]);
};

/**
 * Deployed workflow versions have versionName "v<number>"
 */

export const isDeployedVersion = ({ versionName }: { versionName?: string }) =>
  versionName && versionName.match(/^v\d+$/) !== null;

export const useLocalizeVersionName = () => {
  const { t } = useTranslation();

  return (versionName?: string) => versionName?.replace(/^v/, () => t('ENTITIES.WORKFLOW_VERSION.NAME') + ' ');
};

export const compareWorkflowIssues: GridComparatorFn<WorkflowVersionRow['workflowDefinitionStatus']> = (s1, s2) => {
  const errors = (s1?.error || 0) - (s2?.error || 0);
  const warnings = (s1?.warning || 0) - (s2?.warning || 0);

  if (errors !== 0) {
    return errors;
  } else if (warnings !== 0) {
    return warnings;
  }
  return (s1?.info || 0) - (s2?.info || 0);
};
