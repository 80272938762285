import React from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import dayjs from 'dayjs';
import { useCompletedRequestsContext } from './CompleteRequestsContext';
import DropDownMenu from '../../DropDownMenu';

const CompletedRequestFilter = () => {
  const { t } = useTranslation(undefined, { keyPrefix: 'INTELLIGENT_WORKFLOWS.DASHBOARD' });
  const { range, setRange } = useCompletedRequestsContext();

  return (
    <Stack direction="row" px={6} gap={2}>
      <DropDownMenu
        label={t('FILTER.DATE_RANGE.LABEL')}
        placeholder={t('LAST_X_MONTHS', {
          monthCount: range.endDate.diff(range.startDate, 'month') + 1,
        })}
        items={[
          { id: 3, label: t('LAST_X_MONTHS', { monthCount: 3 }) },
          { id: 6, label: t('LAST_X_MONTHS', { monthCount: 6 }) },
          { id: 12, label: t('LAST_X_MONTHS', { monthCount: 12 }) },
        ]}
        onChange={(months) => {
          const endDate = dayjs().subtract(1, 'months').endOf('month');
          const startDate = endDate.subtract(months - 1, 'months').startOf('month');

          setRange(startDate, endDate);
        }}
      />
      <DropDownMenu items={[]} label={t('FILTER.FILTER.LABEL')} placeholder={t('FILTER.FILTER.PLACEHOLDER')} />
    </Stack>
  );
};

export default CompletedRequestFilter;
