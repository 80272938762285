// @todo replace with real data
import type { Range } from './components/Card/CompletedRequestsCard/CompleteRequestsContext';

export const generateMonthsInRange = (range: Range): string[] => {
  const start = range.startDate;
  const end = range.endDate;
  const months = [];

  let current = start;

  while (current.isBefore(end) || current.isSame(end, 'month')) {
    months.push(current.startOf('month').format('YYYY-MM-DDTHH:mm:ssZ'));
    current = current.add(1, 'month');
  }

  return months;
};

const deterministicRandom = (seed: string) => {
  let h = 0;
  for (let i = 0; i < seed.length; i++) {
    h = (Math.imul(31, h) + seed.charCodeAt(i)) | 0;
  }
  return () => {
    h = (Math.imul(31, h) + 0x6d2b79f5) | 0;
    return (h >>> 0) / 0xffffffff;
  };
};

export const generateRandomData = (length: number, seed: string, maxValue: number): number[] => {
  const rng = deterministicRandom(seed);
  return Array.from({ length }, () => Math.floor(rng() * maxValue) + 1);
};
