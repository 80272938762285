import { Stack } from '@mui/material';
import { FC, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { TaskOverview } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { Text } from '@verticeone/design-system';
import { isProcessDefinition } from '../../../../../pocWorkflowSchema';
import { useWorkflowRendererContext } from '../../../../WorkflowRenderer/WorkflowRendererContext';
import { formatVariableLabel } from '../../../../WorkflowViewer/utils';
import { EditorDrawerGroup } from '../../../EditorDrawer';
import { useVariablesAvailableInNode } from '../../../hooks/useVariablesAvailableInNode';
import { findValueInTaskObj } from '../JiraCommon/utils';
import { JIRA_FIELD_PREFIX_V2_ESCAPED, SUPPORTING_INPUT_FIELD_VALUES } from '../utils';
import { INPUT_RESOLUTION_KEY, INPUT_STATUS_KEY } from './constants';

type FieldsSectionProps = {
  taskOverview?: TaskOverview;
};

export const FieldsSection: FC<FieldsSectionProps> = ({ taskOverview }) => {
  const { t } = useTranslation();

  const fieldKeys = Object.entries(taskOverview?.task.input ?? {})
    .filter(([key, value]) => {
      const fieldPrefix = new RegExp(`${JIRA_FIELD_PREFIX_V2_ESCAPED}(.*?)<(.*?)>`);
      const skippedFields = new RegExp(INPUT_STATUS_KEY.source + '|' + INPUT_RESOLUTION_KEY.source);
      const validKey = fieldPrefix.test(key) && !skippedFields.test(key);
      const validName = !SUPPORTING_INPUT_FIELD_VALUES.includes(value);

      return validKey && validName;
    })
    .map(([key]) => key);

  // Getting workflow variables
  const { workflowDefinitions } = useWorkflowRendererContext();
  const processDefinition = workflowDefinitions?.definitions.find(isProcessDefinition);
  const processTaskDefinition = processDefinition?.process?.tasks?.find((processTask) =>
    taskOverview?.task.taskId?.includes(processTask.task.id)
  );

  const { requestVariables, udfVariables } = useVariablesAvailableInNode({
    nodeId: processTaskDefinition?.task.id ?? '',
    processDefinition,
    workflowServiceRef: taskOverview?.request?.serviceRef,
  });

  const allVariables = useMemo(() => [...requestVariables, ...udfVariables], [requestVariables, udfVariables]);

  if (fieldKeys.length === 0) return null;

  return (
    <EditorDrawerGroup title={t('INTELLIGENT_WORKFLOWS.JIRA.FIELDS_GROUP_TITLE')}>
      <Stack gap={4}>
        {fieldKeys.map((key) => {
          const inputFieldKey = findValueInTaskObj(key, taskOverview?.task.input);
          const fieldValue = taskOverview?.task.result?.[inputFieldKey];

          return (
            <Stack gap={2} key={inputFieldKey}>
              <Text variant="caption" size="XS" color="text2">
                {allVariables.find((v) => v.id === inputFieldKey)?.label ?? formatVariableLabel(inputFieldKey)}
              </Text>
              <Text variant="body-regular" size="S" color="text1">
                {typeof fieldValue === 'object' ? fieldValue?.title : fieldValue}
              </Text>
            </Stack>
          );
        })}
      </Stack>
    </EditorDrawerGroup>
  );
};
