import { useMemo } from 'react';
import { createAttachedRoutes } from '@verticeone/router/src/routes/utils';
import { coreRoutes } from '@vertice/core/src/router/routes';
import { cloudRoutes } from '../modules/cloud/routes';
import { saasRoutes } from '../modules/saas/routes';
import { preferencesRoutes } from '../pages/Preferences/routes';
import { intelligentWorkflowsRoutes } from '@vertice/core/src/modules/intelligentWorkflows/routes';

export const useCfaRoutes = () => {
  return useMemo(
    () =>
      createAttachedRoutes({
        ...coreRoutes,
        ...preferencesRoutes,
        ...saasRoutes,
        ...cloudRoutes,
        ...intelligentWorkflowsRoutes,
      }),
    []
  );
};

export default useCfaRoutes;
