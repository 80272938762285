import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { EditorDrawerGroup } from '../../../EditorDrawer';
import { IssueTypePicker } from '../JiraCommon/IssueTypePicker';
import { VariableMapper } from '../../../VariableMapper/VariableMapper';
// import { Attachments } from './Attachments';
import { JiraCreateTaskFormData } from './formSchema';
import { JiraCreateFormProps } from './JiraCreateForm';
import { createJiraVariablesGroups } from '../JiraCommon/utils';
import { NoJiraVariables } from '../JiraCommon/NoJiraVariables';
import { IntegrationWrapper, IntegrationWrapperBaseProps } from '../JiraCommon/IntegrationWrapper';

type JiraCreateSetupProps = Pick<JiraCreateFormProps, 'issueTypePickerProps' | 'variableMapperProps'> & {
  defaultIssueTypeId: string;
  integrationWrapperProps: IntegrationWrapperBaseProps;
};

export const JiraCreateSetup: FC<JiraCreateSetupProps> = ({
  defaultIssueTypeId,
  issueTypePickerProps,
  variableMapperProps,
  integrationWrapperProps,
}) => {
  const { t } = useTranslation();

  return (
    <IntegrationWrapper {...integrationWrapperProps}>
      <EditorDrawerGroup title={t('INTELLIGENT_WORKFLOWS.JIRA.JIRA_ISSUE_SETUP')}>
        <IssueTypePicker defaultIssueTypeId={defaultIssueTypeId} {...issueTypePickerProps} />
        {/* Don't show anything when loading, so it doesn't blik with info about no variables */}
        {!issueTypePickerProps.isLoadingIssueTypes && (
          <VariableMapper<JiraCreateTaskFormData>
            formFieldName="variables"
            requiredFormFieldName="requiredVariables"
            otherVariablesTitle={t('INTELLIGENT_WORKFLOWS.JIRA.VARIABLE_MAPPER.COLUMN_HEADER')}
            fromWorkflow={true}
            variableMapperRowProps={{ createOtherVariableGroups: createJiraVariablesGroups }}
            components={{ noOtherVariables: <NoJiraVariables /> }}
            hasRichSelector={{ requiredRows: true }}
            {...variableMapperProps}
          />
        )}

        {/* TODO: Add attachments feature once it's fully supported on WF side */}
        {/* <Attachments /> */}
      </EditorDrawerGroup>
    </IntegrationWrapper>
  );
};
