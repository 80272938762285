import { Card, CardHeader, CardHeaderDescription, CardHeaderTitle, Text } from '@verticeone/design-system';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { SetupConnectionForm, SetupConnectionFormProps } from './SetupConnectionForm';

export const SetupConnection = () => {
  const { t } = useTranslation();

  const handleSubmit: SetupConnectionFormProps['onSubmit'] = (data) => {
    // console.log('DATA: ', data);
    // Temporary to satisfy type
    return new Promise((resolve) => {
      resolve();
    });
  };

  return (
    <Card>
      <CardHeader size="S">
        <CardHeaderTitle text={t('PREFERENCES.INTEGRATIONS.VISO_TRUST.INTEGRATION_SETUP.CARD_HEADER_TITLE')} />
        <CardHeaderDescription>
          <Text variant="body-regular" size="S" color="text2">
            {t('PREFERENCES.INTEGRATIONS.VISO_TRUST.INTEGRATION_SETUP.CARD_HEADER_DESCRIPTION')}
          </Text>
        </CardHeaderDescription>
      </CardHeader>
      <Stack gap={8} p={6}>
        <Stack gap={1}>
          <Text variant="caption" size="XS" color="text2">
            {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CARD_CONTENT_TITLE')}
          </Text>
          <Text variant="body-regular" size="M" color="text2">
            {t('PREFERENCES.INTEGRATIONS.SLACK.INTEGRATION_SETUP.CARD_CONTENT_TEXT')}
          </Text>
        </Stack>
        <SetupConnectionForm defaultValues={{ connectUrl: '', apiToken: '' }} onSubmit={handleSubmit} />
      </Stack>
    </Card>
  );
};
