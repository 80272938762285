import { Stack } from '@mui/material';
import React, { useEffect, useId } from 'react';
import { TextFieldCaption } from '@verticeone/design-system';
import FormControl from '@mui/material/FormControl';
import {
  useIsContractFieldEditable,
  useIsContractFieldRequired,
  useIsContractFieldVisible,
} from '../../hooks/fieldsRulesHooks';
import { useTranslation } from 'react-i18next';
import { ContractFormData, FormPlusComputedFieldPaths } from '../../types';
import { useWatch } from 'react-hook-form';
import FormDecimalNumberField from '../../../../forms/fields/FormDecimalNumberField';
import useContractFormDataSelector from '../../hooks/useContractFormDataSelector';
import { isContractExisting } from '../../computed';
import { AnnualCostNegotiatedViewOnlyField } from '../AnnualCostNegotiatedViewOnlyField';
import { useContractContext } from '../../ContractContext';
import { Text } from '@verticeone/design-system';

const AnnualCostTooltipContent = () => {
  const { t } = useTranslation();

  return (
    <Stack gap={2}>
      <Text size="S" variant="body-regular" color="text4">
        {t('ENTITIES.CONTRACT.TOOLTIPS.ADVANCED_ANNUAL_COST.LINE_1')}
      </Text>
      <Text size="S" variant="body-regular" color="text4">
        {t('ENTITIES.CONTRACT.TOOLTIPS.ADVANCED_ANNUAL_COST.LINE_2')}
      </Text>
    </Stack>
  );
};

const NegotiatedCostTooltipContent = () => {
  const { t } = useTranslation();

  return (
    <Stack gap={2}>
      <Text size="S" variant="body-regular" color="text4">
        {t('ENTITIES.CONTRACT.TOOLTIPS.ADVANCED_NEGOTIATED_COST.LINE_1')}
      </Text>
      <Text size="S" variant="body-regular" color="text4">
        {t('ENTITIES.CONTRACT.TOOLTIPS.ADVANCED_NEGOTIATED_COST.LINE_2')}
      </Text>
    </Stack>
  );
};

const NegotiatedCostFormEntry = () => {
  const { t } = useTranslation();
  const id = useId();
  const contractContext = useContractContext('DONT_REQUIRE_FETCHED');

  const overrideEnabled = useWatch<ContractFormData>({ name: 'costModel.negotiated.model.annualCostOverride.enabled' });

  const namePath: FormPlusComputedFieldPaths = 'costModel.negotiated.model.annualCostOverride.amount';
  const isAnnualCostVisible = useIsContractFieldVisible(namePath);
  const isAnnualCostRequired = useIsContractFieldRequired(namePath);
  const isAnnualCostEditable = useIsContractFieldEditable(namePath);

  const existingContract = useContractFormDataSelector(isContractExisting);

  const defaultAnnualCost = useWatch<ContractFormData>({ name: 'parts.overview.annualCostNegotiated' });

  useEffect(() => {
    if (!overrideEnabled) {
      contractContext.hookForm.setValue(namePath, defaultAnnualCost);
    }
  }, [defaultAnnualCost, contractContext.hookForm, overrideEnabled]);

  if (!isAnnualCostVisible) return null;

  return (
    <FormControl variant="outlined" fullWidth>
      <Stack gap={1}>
        <TextFieldCaption
          htmlFor={id}
          label={
            existingContract
              ? t('ENTITIES.CONTRACT.LABELS.ANNUAL_COST')
              : t('ENTITIES.CONTRACT.LABELS.ANNUAL_COST_NEGOTIATED')
          }
          size="XS"
          required={isAnnualCostEditable && isAnnualCostRequired}
          tooltip={
            existingContract ? { content: <AnnualCostTooltipContent /> } : { content: <NegotiatedCostTooltipContent /> }
          }
        />
        {isAnnualCostEditable ? (
          <FormDecimalNumberField id={id} name={namePath} disabled={!overrideEnabled} required={isAnnualCostRequired} />
        ) : (
          <AnnualCostNegotiatedViewOnlyField id={id} />
        )}
      </Stack>
    </FormControl>
  );
};

export default NegotiatedCostFormEntry;
