import React, { FC, useId } from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { TextFieldCaption } from '@verticeone/design-system';

import LargeValue from '@vertice/core/src/modules/saas/contract/components/LargeValue';
import useFormatContractCurrency from './useFormatContractCurrency';
import {
  getAnnualTotalSavings,
  getAnnualTotalSavingsPercentage,
  getTotalSavings,
} from '@vertice/core/src/modules/saas/savings/computed';
import useFormatSavingsPercentage from '@vertice/core/src/modules/saas/savings/useFormatSavingsPercentage';
import { PropsWithContract } from '../types';
import { isContractExisting } from '@vertice/core/src/modules/saas/contract/computed';

export const TotalSavings: FC<PropsWithContract> = ({ contract }) => {
  const { t } = useTranslation();
  const formatContractCurrency = useFormatContractCurrency(contract);
  const id = useId();
  const formatSavingsPercentage = useFormatSavingsPercentage();

  const totalSavings = getTotalSavings(contract);
  const annualTotalSavings = getAnnualTotalSavings(contract);
  const annualTotalSavingsPct = getAnnualTotalSavingsPercentage(contract);

  return (
    <Stack gap={1} flex={1}>
      {isContractExisting(contract) || totalSavings === 0 ? null : (
        <>
          <TextFieldCaption
            htmlFor={id}
            label={t('ENTITIES.CONTRACT.LABELS.TOTAL_SAVINGS')}
            size="XS"
            tooltip={{
              title: t('ENTITIES.CONTRACT.LABELS.TOTAL_SAVINGS'),
              content: t('ENTITIES.CONTRACT.TOOLTIPS.TOTAL_SAVINGS'),
            }}
          />
          <LargeValue
            id={id}
            primaryValue={formatContractCurrency(annualTotalSavings)}
            primaryTestId={'annualTotalSavingsValue'}
            primaryColor={annualTotalSavings > 0 ? 'success' : 'text1'}
            chipValue={annualTotalSavingsPct > 0 ? formatSavingsPercentage(annualTotalSavingsPct) : undefined}
            chipTestId={'annualTotalSavingsPercentage'}
            secondaryValue={t('ENTITIES.CONTRACT.LABELS.N_TCV', { value: formatContractCurrency(totalSavings) })}
            secondaryTestId={'totalSavingsValue'}
          />
        </>
      )}
    </Stack>
  );
};
