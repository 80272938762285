import { FC, memo, useState } from 'react';
import { CommentsBox, testProps } from '@verticeone/design-system';
import { useConversationUsers } from '../../../../../../hooks/useConversationUsers';
import { useConversations } from '../../useConversations';
import { Stack } from '@mui/material';
import { ConversationControls } from './ConversationControls';
import { useConversationSearch } from './useConversationSearch';
import { useDebounce } from 'use-debounce';
import { useTaskFilter } from './useTaskFilter';

type ConversationContextProps = ReturnType<typeof useConversations> & ReturnType<typeof useConversationUsers>;

export type CommentPanelProps = {
  accountId: string;
  canEditComents: boolean;
  userId: string;
  hasFilterEnabled?: boolean;
  testId?: string;
} & ConversationContextProps;

// We need to memoize the CommentsBox, because it was re-rendering
// when the search value changed, even though the props didn't change.
const MemoizedCommentsBox = memo(CommentsBox);

const CLIENT_INPUT_DEBOUNCE_TIME = 300;

export const CommentPanel: FC<CommentPanelProps> = ({
  canEditComents,
  userId,
  messages,
  cursor,
  sendMessage,
  removeMessage,
  updateCursor,
  editMessage,
  isLoading: isLoadingConversation,
  isMessagePending,
  usersById,
  isLoadingUsers,
  requestId,
  hasFilterEnabled = false,
  testId,
}) => {
  const [searchExpanded, setSearchExpanded] = useState(false);
  const [parentWidth, setParentWidth] = useState(0);
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const [debouncedSearchValue] = useDebounce(searchValue, CLIENT_INPUT_DEBOUNCE_TIME);

  const { filteredMessages, filterExpanded, setFilterExpanded, filterValue, setFilterValue, filterOptions } =
    useTaskFilter(messages, requestId);

  const { commentId, commentIndex, commentsLength, hasNext, hasPrevious, goToNext, goToPrevious } =
    useConversationSearch({
      search: debouncedSearchValue,
      messages,
      users: usersById,
      userId,
    });

  return (
    <Stack
      position="relative"
      overflow="hidden"
      height="100%"
      ref={(node) => node && setParentWidth(parseInt(getComputedStyle(node).width ?? '0'))}
      paddingTop={15}
      {...testProps(testId, 'comments-panel')}
    >
      <MemoizedCommentsBox
        users={usersById}
        currentUser={userId}
        canEditComents={canEditComents}
        comments={filteredMessages}
        cursor={cursor}
        onSendComment={sendMessage}
        onRemoveComment={removeMessage}
        onUpdateCursor={updateCursor}
        onEditComment={editMessage}
        highlightedText={debouncedSearchValue ?? undefined}
        activeSearchCommentId={commentId ?? undefined}
        isLoading={isLoadingUsers || isLoadingConversation}
        isSending={isMessagePending}
      />
      {messages && messages.length > 0 && (
        <Stack position="absolute" top={16} right={8}>
          <ConversationControls
            filterDisabled={!hasFilterEnabled}
            parentWidth={parentWidth}
            searchExpanded={searchExpanded}
            setSearchExpanded={setSearchExpanded}
            filterExpanded={filterExpanded}
            setFilterExpanded={setFilterExpanded}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
            filterValue={filterValue}
            setFilterValue={setFilterValue}
            filterOptions={filterOptions}
            hasNext={hasNext}
            hasPrevious={hasPrevious}
            goToNext={goToNext}
            goToPrevious={goToPrevious}
            currentIndex={commentIndex}
            commentsLength={commentsLength}
          />
        </Stack>
      )}
    </Stack>
  );
};
