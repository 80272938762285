import { useSaveWorkflow } from '../../../../hooks/useSaveWorkflow';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { enqueueSnackbar } from 'notistack';

import { WorkflowVersionRow } from '../../types';
import { useLazyGetWorkflowVersionQuery } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useAccountContext } from '../../../../../../contexts/AccountContext';
import { Definitions } from '../../../../definitionsTypes';
import { useInvalidateWorkflowVersions } from './useInvalidateWorkflowVersions';
import { isDeployedVersion } from '../../utils';

export const useCreateVersionCopyName = () => {
  const { t } = useTranslation();
  return (row: WorkflowVersionRow) => {
    return t(
      'INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.WORKFLOW_VERSION_COPY_NAME',

      {
        versionName: row.versionName ?? t('ENTITIES.WORKFLOW_VERSION.DRAFT'),
      }
    );
  };
};

export const useDuplicateWorkflowVersion = (row: WorkflowVersionRow, params?: { onComplete: () => void }) => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const [, setSearchParams] = useSearchParams();

  const { saveWorkflow, isSavingWorkflow } = useSaveWorkflow();
  const invalidateWorkflowVersions = useInvalidateWorkflowVersions();
  const createVersionCopyName = useCreateVersionCopyName();

  const [getWorkflowVersion, { isLoading: isLoadingWorkflowVersion }] = useLazyGetWorkflowVersionQuery();

  const duplicateWorkflow = useCallback(async () => {
    const getWorkflowVersionQuery = getWorkflowVersion({
      accountId,
      workflowId: row.id,
      workflowVersion: row.versionId,
    });
    const { data: workflowVersionData } = await getWorkflowVersionQuery;
    getWorkflowVersionQuery.unsubscribe(); // prevent re-fetching version which was duplicated and then deleted

    if (workflowVersionData !== undefined) {
      saveWorkflow(
        {
          workflowId: row.id,
          definitions: workflowVersionData.workflowDefinition as Definitions,
          customVersionName: createVersionCopyName(row),
        },
        () => {
          params?.onComplete?.();
          setSearchParams({ tab: 'drafts' });
          invalidateWorkflowVersions();
          enqueueSnackbar(
            t(
              isDeployedVersion(row)
                ? 'INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.MESSAGES.DEPLOYED_VERSION_DUPLICATED'
                : 'INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.MESSAGES.DRAFT_VERSION_DUPLICATED',

              { versionName: row.versionName }
            ),
            {
              variant: 'success',
            }
          );
        },
        () => {
          enqueueSnackbar(
            t(
              isDeployedVersion(row)
                ? 'INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.MESSAGES.DEPLOYED_VERSION_DUPLICATION_FAILED'
                : 'INTELLIGENT_WORKFLOWS.WORKFLOW_DETAIL.MESSAGES.DRAFT_VERSION_DUPLICATION_FAILED',
              { versionName: row.versionName }
            ),
            {
              variant: 'error',
            }
          );
          params?.onComplete?.();
        }
      );
    }
  }, [
    accountId,
    createVersionCopyName,
    getWorkflowVersion,
    invalidateWorkflowVersions,
    params,
    row,
    saveWorkflow,
    setSearchParams,
    t,
  ]);

  return {
    duplicateWorkflow,
    isDuplicatingWorkflow: isLoadingWorkflowVersion || isSavingWorkflow,
  };
};
