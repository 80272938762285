import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { generatePath, Link, Outlet } from 'react-router-dom';

import { BreadcrumbItem, PageHeader } from '@verticeone/design-system';
import { useRoutes } from '@verticeone/router/src';

export const BaseCustomIntegration = () => {
  const { t } = useTranslation();
  const routes = useRoutes();

  const items: BreadcrumbItem<typeof Link>[] = [
    {
      label: t('PREFERENCES.TITLE'),
      component: Link,
      to: generatePath(routes.PREFERENCES.ABSOLUTE_PATH),
    },
    {
      label: t('PREFERENCES.INTEGRATIONS.CUSTOM.TITLE'),
    },
  ];

  return (
    <Stack direction="column">
      <PageHeader breadcrumb={<PageHeader.Breadcrumbs items={items} />} color="primary" actions={undefined} />
      <Stack p={6} pt={0}>
        <Outlet />
      </Stack>
    </Stack>
  );
};
