import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckOutlined } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';
import { ChipButton, DesignSystemSize, EllipsisText, IconWrapper } from '@verticeone/design-system';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../constants';
import { ICON_MAP } from './ValidationDrawer';

type ValidationStats = {
  errors?: number;
  warnings?: number;
  infos?: number;
  isValid: boolean;
  isLoading: boolean;
  minified?: boolean;
};

type ValidationChipProps = ValidationStats & {
  onClick?: () => void;
  isActive?: boolean;
  size?: DesignSystemSize;
};

const getValidationChipColor = ({ warnings, errors, infos, isLoading }: Omit<ValidationStats, 'isValid'>) => {
  return isLoading
    ? 'info'
    : errors && errors > 0
    ? 'error'
    : warnings && warnings > 0
    ? 'warning'
    : infos && infos > 0
    ? 'info'
    : 'success';
};

const useValidationMessage = ({ errors, warnings, infos, isValid, isLoading, minified }: ValidationStats) => {
  const { t } = useTranslation();

  return useMemo(() => {
    if (isLoading) {
      return t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VALIDATION.VALIDATING');
    }

    if (isValid) {
      return t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VALIDATION.VALID');
    }

    const warningsMessage =
      warnings && warnings > 0
        ? t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VALIDATION.WARNING', { count: warnings })
        : null;
    const errorsMessage =
      errors && errors > 0 ? t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VALIDATION.ERROR', { count: errors }) : null;
    const infosMessage =
      infos && infos > 0 ? t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VALIDATION.INFO', { count: infos }) : null;

    if (minified) {
      const minifiedMessage = (errorsMessage ?? warningsMessage ?? infosMessage)?.replace(/\d+/, '').trim();
      return minifiedMessage;
    }

    const completeMessage = [errorsMessage, warningsMessage, errorsMessage || warningsMessage ? null : infosMessage]
      .filter(Boolean)
      .join(` ${t('INTELLIGENT_WORKFLOWS.WORKFLOW_EDITOR.VALIDATION.AND')} `);

    return completeMessage;
  }, [t, errors, warnings, infos, isValid, isLoading, minified]);
};

export const ValidationChip = ({ onClick, isActive, size = 'M', minified = false, ...stats }: ValidationChipProps) => {
  const validationMessage = useValidationMessage({ ...stats, minified });

  const chipColor = getValidationChipColor(stats);

  const ChipLabelIcon =
    stats.isLoading || chipColor === 'info' ? (
      <CircularProgress color={INTELLIGENT_WORKFLOWS_BRAND_COLOR} size={16} />
    ) : stats.isValid || chipColor === 'success' ? (
      <CheckOutlined />
    ) : (
      <IconWrapper size="S" icon={ICON_MAP[chipColor]} />
    );

  return (
    <ChipButton
      sx={{ transition: 'width 0.3s ease-in-out', alignItems: 'center' }}
      variant="ghost"
      color={chipColor}
      size={size}
      onClick={onClick}
      isActive={isActive}
    >
      {!minified && ChipLabelIcon}
      <EllipsisText variant="body-bold" size={size} px={1}>
        {validationMessage}
      </EllipsisText>
    </ChipButton>
  );
};
