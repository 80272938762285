import React, { FC, MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { EditOutlined, SvgIconComponent } from '@mui/icons-material';

import { DesignSystemColor, DesignSystemSize, IconButton } from '@verticeone/design-system';
import { Tooltip } from '@verticeone/design-system';

import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '../../../constants';
import { WorkflowVersionRow } from '../types';
import { useGoToWorkflowEditor } from './hooks/useGoToWorkflowEditor';

type EditButtonProps = {
  row: WorkflowVersionRow;
  color?: DesignSystemColor;
  icon?: SvgIconComponent;
  size?: DesignSystemSize;
};

export const EditButton: FC<EditButtonProps> = ({ row, color = INTELLIGENT_WORKFLOWS_BRAND_COLOR, icon, size }) => {
  const { t } = useTranslation();
  const { goToEditor } = useGoToWorkflowEditor(row);

  const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    goToEditor();
  };

  return (
    <Tooltip title={t('ENTITIES.WORKFLOW_VERSION.ACTIONS.EDIT')} minified size="S">
      <IconButton icon={icon ?? EditOutlined} variant="outline" color={color} onClick={handleButtonClick} size={size} />
    </Tooltip>
  );
};
