import { useMemo } from 'react';

import { useListMultipleWorkflowVersionsQuery } from '@vertice/slices';

import { useAccountContext } from '../../../../contexts/AccountContext';
import { useServiceWorkflows } from '../ServiceDetailPage/useServiceWorkflows';
import { parseWorkflowRef } from '../../../../hooks/workflows/refUtils';
import type { Resource } from '../types';

const getIsDefaultWorkflow = (workflowRef: string, defaultWorkflowId?: string) => {
  const workflowId = parseWorkflowRef(workflowRef)?.workflowId;

  if (!defaultWorkflowId || !workflowId) return false;

  return workflowId === defaultWorkflowId;
};

export const useFlatServiceWorkflowsWithAllVersions = (service: Resource) => {
  const { accountId } = useAccountContext();

  const defaultWorkflowRef = service.definition.ServiceProvider?.HandlerUrn;
  const serviceRef = service.urn;

  const { workflows, isLoadingWorkflows } = useServiceWorkflows({ serviceRef, defaultWorkflowRef });
  const { data: workflowsWithVersionsData, isLoading } = useListMultipleWorkflowVersionsQuery(
    { accountId, workflowIds: workflows.map((w) => w.id) },
    { skip: !workflows.length }
  );

  const defaultWorkflowId = parseWorkflowRef(defaultWorkflowRef)?.workflowId;

  const workflowsWithVersions = useMemo(() => {
    if (!workflowsWithVersionsData) return [];
    return workflowsWithVersionsData
      .map((workflowWithVersions) =>
        workflowWithVersions.workflows.map((workflowVersion) => ({
          ...workflowVersion,
          isWorkflowDefault: getIsDefaultWorkflow(workflowVersion.workflowRef, defaultWorkflowId),
        }))
      )
      .flat();
  }, [workflowsWithVersionsData, defaultWorkflowId]);

  return {
    isLoading: isLoading || isLoadingWorkflows,
    workflowsWithVersions,
  };
};
