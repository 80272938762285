import type { Contract } from '@vertice/slices/src/openapi/codegen/bffeSaasAPI';
import type { Request } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import type { Request as BffeRequest } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { useGetRequestQuery } from '@vertice/slices/src/openapi/codegen/servicesAPI';
import { useAccountContext } from '../../../../../../contexts/AccountContext';
import { isUserRef } from '../../../../../user/refUtils';
import { parseRequestIdFromChildRequestRef } from '../../../../../../hooks/workflows/refUtils';

// Small helper to narrow the type of the request to BFFE request used in the LinkedPagesCard
const convertToBffeRequest = (request: Request): BffeRequest => ({
  ...request,
  parentRequestRef: request.parentRequestRef ?? undefined,
  owner: request.owner ?? undefined,
});

export const useRequestThatCreatedContract = (contract: Contract | undefined): BffeRequest | null => {
  const { accountId } = useAccountContext();

  const createdByRef = contract?.record?.createdBy;
  const isUserCreatedByRef = isUserRef(createdByRef ?? '');
  const createdByRequestId = parseRequestIdFromChildRequestRef(createdByRef ?? '');

  const { data: createdByRequest } = useGetRequestQuery(
    { requestId: createdByRequestId!, accountId },
    { skip: !createdByRequestId || isUserCreatedByRef }
  );

  if (!createdByRequest || !createdByRequest.request || isUserCreatedByRef) return null;

  const request: BffeRequest = convertToBffeRequest(createdByRequest.request);

  return request;
};
