import { useMemo } from 'react';

import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { getTaskRows } from '@vertice/core/src/modules/intelligentWorkflows/task/dataSource';
import { RequestTask, useListRequestTasksQuery } from '@vertice/slices/src/openapi/codegen/bffeWorkflowsAPI';
import { NotifMessage, useNotificationWebSocketSubscription } from '../../../../contexts/NotificationWebSocketContext';
import { useRelevantUsersById } from '../../../../hooks/useRelevantUsersById';
import { useResolveUsersInfo } from '../../../../hooks/useResolveUsersInfo';
import { isTaskRef, parseRequestRef, parseUserRef } from '../../../../hooks/workflows/refUtils';
import { resolveTaskNodeThumbnailConfiguration } from '../../workflowSchema/model/resolveTaskNodeThumbnailConfiguration';
import { resolveTaskType } from '../../workflowSchema/model/resolveTaskType';

const isActiveServiceTaskWithChildRequest = (task: RequestTask): task is RequestTask & { childRequestRef: string } =>
  !!(task.type === 'SERVICE' && task.status === 'ACTIVE' && task.childRequestRef);

const isCurrentTask = (requestId: string) => (message: NotifMessage) => {
  if (isTaskRef(message)) {
    return message.message_attributes.workflowInstanceRef?.includes(requestId) ?? false;
  }
  return false;
};

export const useRequestTasks = (requestId?: string, includeServiceTasks = false, accountId?: string) => {
  const { accountId: contextAccountId } = useAccountContext();

  if (!accountId) {
    accountId = contextAccountId;
  }

  const skip = !requestId;
  const {
    data: tasksData,
    isLoading: isLoadingTasks,
    isFetching: isFetchingTasks,
    refetch: refetchTasks,
  } = useListRequestTasksQuery({ accountId, requestId: requestId! }, { skip });

  useNotificationWebSocketSubscription({
    filter: isCurrentTask(requestId!),
    callback: () => {
      void refetchTasks();
    },
  });

  const [relevantTasks, tasksAssignees] = useMemo(() => {
    const tasks = (tasksData?.items || []).filter((task) => includeServiceTasks || task.type !== 'SERVICE');
    const assignees = tasks.flatMap((task) => task.assignment.map((ref) => parseUserRef(ref).userId));
    return [tasks, assignees];
  }, [tasksData?.items, includeServiceTasks]);

  const { usersById: accountUsersById, isLoadingUsers: isLoadingAccountUsers } = useRelevantUsersById({
    skip,
    includeLoggedVerticeUser: true,
  });
  const { usersById, isLoading: isLoadingUsers } = useResolveUsersInfo(tasksAssignees, accountUsersById);

  const allTasks = useMemo(() => getTaskRows(relevantTasks, usersById), [relevantTasks, usersById]);
  const activeTasks = allTasks.filter((task) => task.status === 'ACTIVE');
  const activeChildRequests = useMemo(() => {
    if (!tasksData?.items.length) {
      return [];
    }

    return tasksData.items.filter(isActiveServiceTaskWithChildRequest).map((task) => ({
      id: parseRequestRef(task.childRequestRef).requestId,
      accountId: parseRequestRef(task.childRequestRef).accountId,
      name: task.name,
      thumbnail: resolveTaskNodeThumbnailConfiguration(task.configurations || []),
      type: resolveTaskType(task.configurations || []),
    }));
  }, [tasksData?.items]);

  return {
    allTasks,
    activeTasks,
    refetchTasks,
    isLoadingTasks: isLoadingAccountUsers || isLoadingTasks || isLoadingUsers,
    isFetchingTasks,
    activeChildRequests,
  };
};
