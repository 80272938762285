import { FC } from 'react';
import { Stack } from '@mui/material';

import { Text } from '@verticeone/design-system';

import { Thumbnail } from './Thumbnail';
import { Resource } from '../../types';
import { testProps } from '@verticeone/design-system';
import { createServiceIdentifier } from '../../../../../hooks/workflows/refUtils';

type ServiceCardContentProps = {
  service: Resource;
  inactive?: boolean;
};

const getServiceTestId = (service: Resource) => createServiceIdentifier(service.urn).replace(/^(.*)-v\d$/, 'card-$1');

export const ServiceCardContent: FC<ServiceCardContentProps> = ({ service, inactive }) => {
  return (
    <Stack width="100%" gap={2} {...testProps(getServiceTestId(service))}>
      <Thumbnail thumbnail={service.thumbnail} inactive={inactive} />
      <Stack padding={4} gap={2}>
        <Stack>
          <Text variant="caption" size="S" color="text3">
            {/*reserve the space even if empty*/}
            {service.category || '\u200b'}
          </Text>
          <Text variant="heading" size="XS" color="text1">
            {service.name}
          </Text>
        </Stack>
        <Text variant="body-regular" size="S" color="text2">
          {service.definition?.Description}
        </Text>
      </Stack>
    </Stack>
  );
};
