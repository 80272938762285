import { Info as InfoIcon, OpenInNew as OpenInNewIcon } from '@mui/icons-material';
import { Box, IconButton, MenuItem, OutlinedInput, Select, Stack, Tooltip, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Label } from '@vertice/components/src/Label';
import { Loader } from '@vertice/components/src/Loader';
import { DEFAULT_CURRENCY } from '@vertice/core/src/constants/currency';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { useExchangeCurrencyHint } from '@vertice/core/src/modules/currency/useGetExchangeCurrencyHint';
import { DepartmentList } from '@vertice/core/src/modules/departments/DepartmentList';
import {
  useGetAccountSettingsQuery,
  useGetPublicAccountInformationQuery,
  useUpdateAccountSettingsMutation,
  useUpdatePublicAccountInformationMutation,
} from '@vertice/slices';
import { Button, Card, CardHeader, CardHeaderActions, CardHeaderTitle } from '@verticeone/design-system';
import { urlRegexp } from '../../utils/oldValidators';
import styles from './Company.module.scss';
import { currencies } from './constants';

interface CompanyInformationForm {
  registeredAddress?: string;
  website?: string;
}

interface AccountSettingsForm {
  preferredCurrency?: string;
}

interface FormType {
  companyInformation: CompanyInformationForm;
  accountSettings: AccountSettingsForm;
}

export const Company = () => {
  const { t } = useTranslation();
  const { accountId } = useAccountContext();
  const { enqueueSnackbar } = useSnackbar();
  const { getExchangeCurrencyHint } = useExchangeCurrencyHint();

  const [updateCompanyInformation, { isLoading: isCompanyInformationUpdating }] =
    useUpdatePublicAccountInformationMutation();

  const [updateAccountSettings, { isLoading: isAccountSettingsUpdating }] = useUpdateAccountSettingsMutation();

  const {
    data: companyInformation,
    isLoading: isCompanyInformationLoading,
    isFetching: isCompanyInformationFetching,
  } = useGetPublicAccountInformationQuery({ accountId: accountId! }, { skip: !accountId });

  const {
    data: accountSettings,
    isLoading: isAccountSettingsLoading,
    isFetching: isAccountSettingsFetching,
  } = useGetAccountSettingsQuery({ accountId: accountId! }, { skip: !accountId });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<FormType>({
    defaultValues: {
      accountSettings: {
        preferredCurrency: DEFAULT_CURRENCY,
      },
    },
  });

  const websiteValue = watch('companyInformation.website');

  const handleGoToWebsite = () => {
    const url = websiteValue?.toString();
    if (url && !errors?.companyInformation?.website && urlRegexp.test(url)) {
      window.open(url, '_blank')?.focus();
    }
  };

  const onSubmit: SubmitHandler<FormType> = async (data) => {
    const { website, registeredAddress } = data.companyInformation;
    const { preferredCurrency } = data.accountSettings;

    const asyncActions = [];

    if (website !== companyInformation?.website || registeredAddress !== companyInformation?.registeredAddress) {
      const updatedCompanyInformation = { ...companyInformation, website, registeredAddress };
      asyncActions.push(updateCompanyInformation({ accountId, companyInformation: updatedCompanyInformation }));
    }

    if (preferredCurrency) {
      asyncActions.push(updateAccountSettings({ accountId, updateAccountSettings: data.accountSettings }));
    }

    // TODO: I really feel like this should be awaited...
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    Promise.all(asyncActions).then(() => {
      enqueueSnackbar(t('PREFERENCES.NOTIFICATIONS.SUCCESS'), {
        variant: 'success',
      });
    });
    // some error handler should be implemented according to design and requirements
  };

  useEffect(() => {
    setValue('companyInformation', {
      website: companyInformation?.website || '',
      registeredAddress: companyInformation?.registeredAddress || '',
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companyInformation]);

  useEffect(() => {
    if (accountSettings?.preferredCurrency) {
      setValue('accountSettings', {
        preferredCurrency: accountSettings?.preferredCurrency,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountSettings]);

  if (
    isCompanyInformationLoading ||
    isCompanyInformationFetching ||
    !companyInformation ||
    !accountId ||
    isAccountSettingsLoading ||
    isAccountSettingsFetching
  ) {
    return <Loader />;
  }

  const isBasicInfoSaving =
    isCompanyInformationUpdating ||
    isAccountSettingsUpdating ||
    isCompanyInformationLoading ||
    isAccountSettingsLoading;

  return (
    <Stack spacing={4}>
      <Card>
        <CardHeader size="S">
          <CardHeaderTitle
            // The concatenation is a temporary solution before the two titles are merged in IAT preferences as well.
            text={`${t('PREFERENCES.COMPANY.BASIC_INFO.TITLE')} & ${t('PREFERENCES.COMPANY.COMPANY_SETTINGS.TITLE')}`}
          />
          <CardHeaderActions>
            <Button
              onClick={handleSubmit(onSubmit)}
              isLoading={isBasicInfoSaving}
              disabled={isBasicInfoSaving}
              variant="solid"
              color="primary"
            >
              {t('PREFERENCES.COMPANY.UPDATE')}
            </Button>
          </CardHeaderActions>
        </CardHeader>

        <div className={styles.form}>
          <div className={styles['form-item']}>
            <Label text={t('PREFERENCES.COMPANY.BASIC_INFO.COMPANY_NAME')} />
            <Typography variant="body-regular-m" data-target="companyName">
              {companyInformation.companyName}
            </Typography>
          </div>
          <div className={styles['form-item']}>
            <Label text={t('PREFERENCES.COMPANY.BASIC_INFO.COUNTRY')} />
            <Typography variant="body-regular-m" data-target="country">
              {companyInformation?.country}
            </Typography>
          </div>

          <div className={styles['form-item']}>
            <Label
              text={t('PREFERENCES.COMPANY.BASIC_INFO.REGISTRATION_ADDRESS')}
              htmlFor="companyInformation.registeredAddress"
            />
            <OutlinedInput fullWidth {...register('companyInformation.registeredAddress')} />
          </div>
          <div className={styles['form-item']}>
            <Label text={t('PREFERENCES.COMPANY.BASIC_INFO.WEBSITE')} htmlFor="companyInformation.website" />
            <OutlinedInput
              fullWidth
              {...register('companyInformation.website', { pattern: urlRegexp })}
              endAdornment={
                <IconButton onClick={handleGoToWebsite} disabled={!!errors?.companyInformation?.website}>
                  <OpenInNewIcon className={styles['open-in-new-icon']} />
                </IconButton>
              }
            />
          </div>
          <div className={`${styles['form-item']} ${styles.currency}`}>
            <Label htmlFor="currency" text={t('PREFERENCES.COMPANY.COMPANY_SETTINGS.CURRENCY')} />
            <Controller
              render={({ field: { ref, ...field } }) => (
                <Box display="flex" gap="12px" alignItems="center">
                  <Select
                    fullWidth
                    {...field}
                    value={field.value || ''}
                    inputRef={ref}
                    inputProps={{ id: 'currency' }}
                    onChange={(e) => field.onChange(e.target.value)}
                    data-target="preferredCurrency"
                  >
                    {currencies.map((currency: string) => (
                      <MenuItem key={currency} value={currency}>
                        {currency}
                      </MenuItem>
                    ))}
                  </Select>
                  <Tooltip title={<Typography variant="body-regular-s">{getExchangeCurrencyHint()}</Typography>}>
                    <InfoIcon className={styles['company-settings--info-icon']} />
                  </Tooltip>
                </Box>
              )}
              name="accountSettings.preferredCurrency"
              control={control}
            />
          </div>
        </div>
      </Card>

      <Card>
        <CardHeader size="S">
          <CardHeaderTitle text={t('PREFERENCES.COMPANY.DEPARTMENTS.TITLE')} />
        </CardHeader>
        <DepartmentList noBorder />
      </Card>
    </Stack>
  );
};
