import { Box, Card, CardActions, CardContent, CardHeader, Stack } from '@mui/material';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import {
  IntegrationConnectionStatus,
  useDeactivateAccountIntegrationMutation,
  useGetAccountIntegrationQuery,
  useRevokeAccountIntegrationMutation,
} from '@vertice/slices';
import {
  useActivateProviderMutation,
  useDeactivateProviderMutation,
  useGetProviderQuery,
} from '@vertice/slices/src/graphql/insight/generated/insightGraphQL';
import { Button, Loader, Text } from '@verticeone/design-system';
import { IntegrationsCardContent } from 'pages/Preferences/Integrations/components/IntegrationsCardContent';
import { IntegrationSetupDialog } from 'pages/Preferences/Integrations/components/IntegrationSetupDialog';
import { StatusBadge, StatusBadgeVariant } from 'pages/Preferences/Integrations/components/StatusBadge';
import SwitchComponent from 'pages/Preferences/Integrations/components/SwitchComponent';
import styles from '../../../common.module.scss';

type UsageStatsSetupCardProps = {
  providerId: string;
  providerName: string;
  providerIcon: ReactNode;
  description: string;
  wizardRoute: string;
};

export const DiscoveryAndUsageSetupCard = ({
  providerId,
  providerName,
  providerIcon,
  description,
  wizardRoute,
}: UsageStatsSetupCardProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { accountId } = useAccountContext();
  const [insightActive, setInsightActive] = useState(false);
  const [revokeConfirmationDisplayed, setRevokeConfirmationDisplayed] = useState(false);

  const { data: integration, isLoading: isIntegrationLoading } = useGetAccountIntegrationQuery({
    accountId: accountId,
    integrationId: providerId,
  });

  const [deactivateIntegration, { isLoading: isIntegrationDeactivationInProgress }] =
    useDeactivateAccountIntegrationMutation();
  const [revokeIntegration, { isLoading: isIntegrationRevokeInProgress }] = useRevokeAccountIntegrationMutation();

  const { data: providerQueryData, isLoading: isInsightLoading } = useGetProviderQuery(
    { accountId: accountId, providerId },
    { skip: !integration || integration?.status !== 'ACTIVE' }
  );

  const [activateInsight, { isLoading: isInsightActivationInProgress }] = useActivateProviderMutation();
  const [deactivateInsight, { isLoading: isInsightDeactivationInProgress }] = useDeactivateProviderMutation();

  useEffect(() => {
    setInsightActive(providerQueryData?.provider?.status === 'ACTIVE');
  }, [providerQueryData]);

  const handleRevoke = async (action: 'DELETE' | 'CANCEL' | 'CONFIRM') => {
    setRevokeConfirmationDisplayed(action === 'CONFIRM');

    if (action === 'DELETE') {
      await deactivateInsight({ providerId, accountId: accountId });
      const response = await deactivateIntegration({
        accountId: accountId,
        integrationId: providerId,
      });
      if ((response as { data: IntegrationConnectionStatus }).data) {
        void revokeIntegration({ accountId: accountId, integrationId: providerId });
      }
    }
  };

  const handleInsightActiveChange = async () => {
    const isInsightActive = providerQueryData?.provider?.status === 'ACTIVE';
    setInsightActive(!isInsightActive); // Let's be optimistic here to avoid UI flickering

    let response;
    if (isInsightActive) {
      response = await deactivateInsight({ accountId: accountId, providerId });
    } else {
      response = await activateInsight({ accountId: accountId, providerId });
    }

    // Move back to previous state if BE request failed
    if ((response as { error: FetchBaseQueryError | SerializedError }).error) {
      setInsightActive(isInsightActive);
    }
  };

  const navigateToWizard = () => {
    navigate(wizardRoute);
  };

  const integrationStatus = integration?.status;
  const isIntegrationActive = integrationStatus === 'ACTIVE';
  const isIntegrationMissing = integrationStatus === 'MISSING';

  const badgeStatus = isIntegrationActive ? StatusBadgeVariant.ACTIVE : StatusBadgeVariant.NEW;

  const mainButtonLabel = !isIntegrationMissing
    ? t('PREFERENCES.INTEGRATIONS.RE_RUN_SETUP')
    : t('PREFERENCES.INTEGRATIONS.CONNECT');

  const insightInProgress = isInsightLoading || isInsightActivationInProgress || isInsightDeactivationInProgress;
  const revokeInProgress = isIntegrationDeactivationInProgress || isIntegrationRevokeInProgress;

  return (
    <div className={styles.card}>
      <Card variant="outlined">
        {isIntegrationLoading ? (
          <Box sx={{ height: '268px' }}>
            <Loader />
          </Box>
        ) : (
          <>
            <CardHeader avatar={providerIcon} />
            <CardContent classes={{ root: styles['card-content'] }}>
              <IntegrationsCardContent
                title={
                  <Box title={`${providerName} ${t('PREFERENCES.INTEGRATIONS.FOR_USAGE_STATS')}`}>
                    <Text variant="heading" size="S" color="text1">
                      {providerName}
                    </Text>{' '}
                    <Text variant="heading" size="S" color="text1" sx={{ opacity: 0.4 }}>
                      {t('PREFERENCES.INTEGRATIONS.FOR_USAGE_STATS')}
                    </Text>
                  </Box>
                }
                statusBadge={badgeStatus && <StatusBadge variant={badgeStatus} />}
                description={description}
              />
            </CardContent>
            <CardActions className={styles['card-actions']} sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Stack direction="row" gap="8px">
                <Button
                  onClick={navigateToWizard}
                  variant="outline"
                  color="neutral"
                  size="S"
                  disabled={revokeInProgress || insightInProgress}
                >
                  {mainButtonLabel}
                </Button>
                {!isIntegrationMissing && (
                  <Button
                    onClick={() => handleRevoke('CONFIRM')}
                    variant="ghost"
                    color="neutral"
                    size="S"
                    disabled={revokeInProgress || insightInProgress}
                  >
                    {t('PREFERENCES.INTEGRATIONS.REVOKE_ACCESS')}
                  </Button>
                )}
              </Stack>
              {isIntegrationActive && (
                <SwitchComponent
                  checked={insightActive}
                  onChange={handleInsightActiveChange}
                  loading={revokeInProgress || insightInProgress}
                />
              )}
            </CardActions>
          </>
        )}
      </Card>
      <IntegrationSetupDialog
        header={t('PREFERENCES.INTEGRATIONS.REVOKE_DIALOG.TITLE', { providerName })}
        content={t('PREFERENCES.INTEGRATIONS.REVOKE_DIALOG.DESCRIPTION', { providerName })}
        buttonLabel={t('PREFERENCES.INTEGRATIONS.REVOKE_DIALOG.REVOKE')}
        isOpened={revokeConfirmationDisplayed}
        onSubmit={() => handleRevoke('DELETE')}
        onClose={() => handleRevoke('CANCEL')}
      />
    </div>
  );
};
