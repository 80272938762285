import React, { FC } from 'react';
import { TextFieldCaption, Alert, Text } from '@verticeone/design-system';
import { FormControl, Stack } from '@mui/material';
import FormChecklistItemField from '@vertice/core/src/modules/forms/fields/FormChecklistItemField';
import { useTranslation } from 'react-i18next';
import { INTELLIGENT_WORKFLOWS_BRAND_COLOR } from '@vertice/core/src/modules/intelligentWorkflows/constants';
import { useTaskFormContext } from '../TaskFormContext';
import { useWatch } from 'react-hook-form';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

type PurchasingManagerPermissionsProps = {
  contactVendorDisabled?: boolean;
  contactVendorHidden?: boolean;
};

const SIZE = 'S';

export const PurchasingManagerPermissions: FC<PurchasingManagerPermissionsProps> = ({
  contactVendorDisabled = false,
  contactVendorHidden = false,
}) => {
  const { t } = useTranslation();
  const { readOnly } = useTaskFormContext();
  const contactVendorDirectly = useWatch({ name: 'contactVendorDirectly' });
  const mainVendorContact = useWatch({ name: 'mainVendorContact' });

  return (
    <Stack direction="column" gap={3}>
      <TextFieldCaption label={t('INTAKE_FORM.PURCHASING_CONTACT_PERMISSIONS.LABEL')} size="XS" />
      {contactVendorHidden ? (
        <Text variant="body-regular" size="S" color="text2">
          {t('INTELLIGENT_WORKFLOWS.TASK_FORMS.PURCHASE_REQUIREMENTS.PROCUREMENT_MANAGER_CONTACT')}
        </Text>
      ) : (
        <>
          <FormControl variant="outlined">
            <FormChecklistItemField
              name="contactVendorDirectly"
              label={t('INTAKE_FORM.PURCHASING_CONTACT_PERMISSIONS.CONTACT_VENDOR_DIRECTLY_TITLE')}
              description={t('INTAKE_FORM.PURCHASING_CONTACT_PERMISSIONS.CONTACT_VENDOR_DIRECTLY_SUBTITLE')}
              color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
              size={SIZE}
              disabled={contactVendorDisabled || readOnly}
            />
          </FormControl>
          {!contactVendorDirectly && mainVendorContact && (
            <Alert
              icon={InfoOutlinedIcon}
              color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
              title={t('INTAKE_FORM.PURCHASING_CONTACT_PERMISSIONS.CONTACT_VENDOR_DIRECTLY_UNCHECKED_INFO')}
              variant="ghost"
              size="XS"
            />
          )}
        </>
      )}
      <FormControl variant="outlined">
        <FormChecklistItemField
          name="discussMultiYearDeals"
          label={t('INTAKE_FORM.PURCHASING_CONTACT_PERMISSIONS.DISCUS_MULTI_YEAR_TITLE')}
          description={t('INTAKE_FORM.PURCHASING_CONTACT_PERMISSIONS.DISCUS_MULTI_YEAR_SUBTITLE')}
          color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          size={SIZE}
          disabled={readOnly}
        />
      </FormControl>
      <FormControl variant="outlined">
        <FormChecklistItemField
          name="discussPlannedGrowth"
          label={t('INTAKE_FORM.PURCHASING_CONTACT_PERMISSIONS.DISCUS_PANNED_GROWTH_TITLE')}
          description={t('INTAKE_FORM.PURCHASING_CONTACT_PERMISSIONS.DISCUS_PANNED_GROWTH_SUBTITLE')}
          color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          size={SIZE}
          disabled={readOnly}
        />
      </FormControl>
      <FormControl variant="outlined">
        <FormChecklistItemField
          name="discussCompetitors"
          label={t('INTAKE_FORM.PURCHASING_CONTACT_PERMISSIONS.DISCUS_COMPETITORS_TITLE')}
          description={t('INTAKE_FORM.PURCHASING_CONTACT_PERMISSIONS.DISCUS_COMPETITORS_SUBTITLE')}
          color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          size={SIZE}
          disabled={readOnly}
        />
      </FormControl>
      <FormControl variant="outlined">
        <FormChecklistItemField
          name="discussCaseStudies"
          label={t('INTAKE_FORM.PURCHASING_CONTACT_PERMISSIONS.DISCUS_CASE_STUDIES_TITLE')}
          description={t('INTAKE_FORM.PURCHASING_CONTACT_PERMISSIONS.DISCUS_CASE_STUDIES_SUBTITLE')}
          color={INTELLIGENT_WORKFLOWS_BRAND_COLOR}
          size={SIZE}
          disabled={readOnly}
        />
      </FormControl>
    </Stack>
  );
};
