import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isCurrentConversation, parseUserRef } from '@vertice/core/src/hooks/workflows/refUtils';
import { getFullName } from '@verticeone/utils/formatting';
import {
  useCreateMessageMutation,
  useGetCursorQuery,
  useListContributorsQuery,
  useListMessagesQuery,
  usePostCursorMutation,
  useDeleteMessageMutation,
  useUpdateMessageMutation,
} from '@vertice/slices/src/openapi/codegen/conversationsAPI';
import { useLoggedUser } from '@verticeone/auth/src';
import { useAccountContext } from '@vertice/core/src/contexts/AccountContext';
import { CommentMetadata, MENTION_PATTERN, RemoveCommentCallback } from '@verticeone/design-system';
import { SendCommentCallback } from '@verticeone/design-system';
import { NotifMessage, useNotificationWebSocketSubscription } from '../../../../contexts/NotificationWebSocketContext';
import { getConversationRef, getRequestRef } from './utils';
import { CommentSourceRefButton } from './CommentSourceRefButton';
import { TaskRow } from '../../task/dataSource';

export type ConversationInfo = {
  requestId?: string;
  accountId?: string;
  requestOwner: string | null | undefined;
  sourceRef?: string;
  onCommentSourceClick?: (item: TaskRow) => void;
};

export const useConversations = ({
  requestId,
  accountId,
  requestOwner,
  sourceRef,
  onCommentSourceClick,
}: ConversationInfo) => {
  const { t } = useTranslation();
  const { userId } = useLoggedUser();
  const { accountId: contextAccountId } = useAccountContext();

  const { data: contributors, refetch: refetchContributors } = useListContributorsQuery(
    {
      accountId: accountId!,
      conversationUrn: getConversationRef(accountId!, requestId!),
    },
    { skip: !accountId || !requestId }
  );
  const {
    data: cursor,
    isLoading: isCursorLoading,
    isError: isCursorError,
    refetch: refetchCursor,
  } = useGetCursorQuery(
    { accountId: accountId!, conversationUrn: getConversationRef(accountId!, requestId!) },
    { skip: !accountId || !requestId }
  );
  const [updateMessage] = useUpdateMessageMutation();

  const {
    data: messagesRaw,
    isLoading: isMessagesLoading,
    isError: isMessagesError,
    refetch: refetchMessages,
  } = useListMessagesQuery(
    {
      accountId: accountId!,
      conversationUrn: getConversationRef(accountId!, requestId!),
      sourceRef,
    },
    { skip: !accountId || !requestId }
  );

  const [messageSendingInProgress, setMessageSendingInProgress] = useState(false);
  const [createMessage] = useCreateMessageMutation();
  const [postCursor] = usePostCursorMutation();
  const [deleteMessage] = useDeleteMessageMutation();

  const updateCursor = useCallback(async () => {
    if (!accountId || !requestId) return;

    await postCursor({
      accountId,
      conversationUrn: getConversationRef(accountId, requestId),
      body: { timestamp: new Date().toISOString() },
    }).then(async () => {
      await refetchCursor();
    });
  }, [postCursor, refetchCursor, accountId, requestId]);

  const sendMessage: SendCommentCallback = useCallback(
    async (message, onSuccess) => {
      if (!accountId || !requestId) return;
      if (messageSendingInProgress) return;
      if (message.trim() === '') return;

      setMessageSendingInProgress(true);

      const newMessage = await createMessage({
        accountId,
        conversationUrn: getConversationRef(accountId, requestId),
        sourceRef,
        createMessage: {
          // Trim message to avoid whitespace around characters
          content: message.trim(),
          contentType: 'text/plain',
        },
      });

      if ('data' in newMessage) {
        onSuccess?.();

        await refetchContributors();
        await refetchMessages();
        setMessageSendingInProgress(false);
        await updateCursor();
      }

      setMessageSendingInProgress(false);
    },
    [
      refetchMessages,
      refetchContributors,
      messageSendingInProgress,
      updateCursor,
      createMessage,
      accountId,
      requestId,
      sourceRef,
    ]
  );

  const removeMessage: RemoveCommentCallback = useCallback(
    async (messageId: string) => {
      if (!accountId || !requestId) return;

      await deleteMessage({
        accountId,
        conversationUrn: getConversationRef(accountId, requestId),

        messageId,
      });
    },
    [accountId, requestId, deleteMessage]
  );

  const deletedUserSkeleton = useMemo(
    () => ({
      firstName: t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.COMMENTS.DELETED'),
      lastName: t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.COMMENTS.USER'),
    }),
    [t]
  );

  const editMessage = useCallback(
    async (messageId: string, content: string) => {
      if (!accountId || !requestId) return;
      await updateMessage({
        accountId,
        messageId,
        conversationUrn: getConversationRef(accountId, requestId),
        updateMessage: {
          content: content.trim(),
          contentType: 'text/plain',
        },
      });
    },
    [updateMessage, accountId, requestId]
  );

  const wsConversationFilter = useMemo(() => {
    if (!accountId || !requestId) {
      return (_: NotifMessage) => false;
    }

    return isCurrentConversation(getRequestRef(accountId, requestId));
  }, [accountId, requestId]);

  useNotificationWebSocketSubscription({
    filter: wsConversationFilter,
    callback: () => {
      void refetchContributors();
      void refetchMessages();
    },
  });

  const messages: (CommentMetadata & { sourceRef?: string })[] = useMemo(
    () =>
      messagesRaw && contributors
        ? (
            messagesRaw.items
              .map((message, index) => {
                const contributor = contributors.contributors.find(
                  (user) => user.userId === parseUserRef(message.createdBy).userId
                );
                const user = contributor ?? { ...deletedUserSkeleton, userId: parseUserRef(message.createdBy).userId };

                const requestOwnerId = requestOwner?.includes('/') ? parseUserRef(requestOwner).userId : requestOwner;

                const isRequestOwner = requestOwner ? requestOwnerId === user.userId : false;

                return {
                  messageId: message.messageId,
                  commentId: [message.content, index].join(),
                  timestamp: message.createdAt,
                  personId: message.createdBy,
                  personName: getFullName(user),
                  personRole: isRequestOwner
                    ? t('INTELLIGENT_WORKFLOWS.REQUEST_DETAIL.FORMS.CHANGE_OWNER.LABELS.OWNER')
                    : user.jobTitle ?? '',
                  content: message.content,
                  isCommentAuthor: user.userId === userId,
                  isVerticeUser: user?.isAccountManager,
                  isEdited: message.edited,
                  isDeleted: message.deleted,
                  bottomContent: sourceRef ? undefined : (
                    <CommentSourceRefButton
                      accountId={accountId}
                      requestId={requestId}
                      sourceRef={message.sourceRef}
                      onClick={accountId === contextAccountId ? onCommentSourceClick : undefined}
                    />
                  ),
                  sourceRef: message.sourceRef,
                  // Wait for API to include this information, for now nobody is vertice user
                };
              })
              .filter((predicate) => !!predicate) as CommentMetadata[]
          ).sort((a, b) => (new Date(b.timestamp) < new Date(a.timestamp) ? 1 : -1))
        : [],
    [
      messagesRaw,
      contributors,
      userId,
      requestOwner,
      t,
      deletedUserSkeleton,
      sourceRef,
      requestId,
      accountId,
      contextAccountId,
      onCommentSourceClick,
    ]
  );

  const mentionedUserIds = useMemo(() => {
    return (
      messagesRaw?.items
        .map((message) => {
          return (
            message.content.match(MENTION_PATTERN)?.map((mention) => mention.substring(2, mention.length - 1)) ?? []
          );
        })
        .flat() ?? []
    );
  }, [messagesRaw]);

  return {
    cursor: cursor?.timestamp ?? null,
    messages,
    mentionedUserIds,
    sendMessage,
    removeMessage,
    editMessage,
    updateCursor,
    requestId,
    isMessagePending: messageSendingInProgress,
    isLoading: isCursorLoading || isMessagesLoading,
    isError: isCursorError || isMessagesError,
  };
};
