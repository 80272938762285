import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';
import { Text, Drawer, Divider } from '@verticeone/design-system';

import { DrawerBody, DrawerContent, DrawerHeader } from '../Drawer';
import { WorkflowsTable } from '../WorkflowsTable/WorkflowsTable';
import type { Resource } from '../../types';

const isNonEmptyString = (value: any): value is string => typeof value === 'string' && value.length > 0;

type ServiceDrawerBodyProps = {
  onClose: () => void;
  service: Resource;
};

const ServiceDrawerBody: FC<ServiceDrawerBodyProps> = ({ onClose, service }) => {
  const serviceDescription = service.definition.Description;

  const { t } = useTranslation();

  return (
    <DrawerBody>
      <DrawerHeader title={service.name} onClose={onClose} />
      <Divider />
      <DrawerContent>
        {isNonEmptyString(serviceDescription) ? (
          <Stack direction="column" gap={2}>
            <Text variant="caption" size="XS" color="text2">
              {t('INTELLIGENT_WORKFLOWS.SERVICES_LIST.SERVICE_DRAWER.LABELS.DESCRIPTION')}
            </Text>
            <Text variant="body-regular" color="text1" size="S">
              {serviceDescription}
            </Text>
          </Stack>
        ) : null}
        <Stack minHeight={0}>
          <WorkflowsTable service={service} />
        </Stack>
      </DrawerContent>
    </DrawerBody>
  );
};

type ServiceDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  service?: Resource;
};

export const ServiceDrawer: FC<ServiceDrawerProps> = ({ isOpen, onClose, service }) => {
  return (
    <Drawer disableRestoreFocus onClose={onClose} width={`max(calc((100vw / 12) * 7), 780px)`} open={isOpen}>
      {service && <ServiceDrawerBody onClose={onClose} service={service} />}
    </Drawer>
  );
};
